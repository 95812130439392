<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Billing History") }}
        </h1>

        <Button
          id="btn-search_billing"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="slideOver.show = true, searchDetails(), search.errors = null"
        >
          {{ t("Search") }}
        </Button>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getBillingHistory()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="flex flex-wrap items-center justify-center mx-1">
            <ButtonSecondary
              v-if="false"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="resetFilters(), getBillingHistory(), isRestFilterEnable = false"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="refresh"
                stroke-width="2.5"
              />
              <span>{{ t("Reset Filters") }}</span>
            </ButtonSecondary>
          </div>

          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              :search-text="quickSearchText"
              @dataFetchfunction="getBillingHistory(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Invoice Date") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('inv_date')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'inv_date'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Invoice Number") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('inv_number')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'inv_number'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Merchant") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('merchant')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'merchant'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-end">
                    <span>{{ t("Amount") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('inv_total')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'inv_total'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(billing_history, index) in billingHistoryDetails"
                :id="'billing_history_admin_portal_' + index"
                :key="index"
              >
                <td class="whitespace-nowrap">
                  {{ formatDate(billing_history.inv_date, 'DD-MMM-YYYY') }}
                </td>
                <td class="max-w-xs">
                  {{ billing_history.inv_no }}
                </td>
                <td class="max-w-lg">
                  {{ billing_history.mch_acc_name }}
                </td>
                <td class="text-right">
                  {{ billing_history.inv_total }}
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'billing_history_admin_portal_view_' + index"
                      type="info"
                      icon="eye"
                      @click="viewInvoiceDetails(billing_history.inv_batch_no, billing_history.inv_no)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!billingHistoryDetails.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getBillingHistory($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="t(slideOver.title)"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <button
        id="focus-ref"
        class="absolute top-0 w-0 h-0 focus:outline-none"
        type="button"
      >
        <span class="invisible">.</span>
      </button>

      <div class="space-y-4">
        <div>
          <form-label for="from_date">
            {{ t("Invoice Date From") }}
          </form-label>
          <datepicker
            id="from_date"
            v-model="search.inv_date_from"
            name="from_date"
            class="vdp-input mt-1"
            input-format="dd-MM-yyyy"
          />
        </div>
        <div>
          <form-label for="to_date">
            {{ t("Invoice Date To") }}
          </form-label>
          <datepicker
            id="to_date"
            v-model="search.inv_date_to"
            name="to_date"
            class="vdp-input mt-1"
            input-format="dd-MM-yyyy"
          />
        </div>
        <div>
          <form-label for="invoice_no">
            {{ t("Invoice Number") }}
          </form-label>
          <form-input
            id="invoice_no"
            v-model="search.inv_number"
            class="mt-1"
            name="invoice_no"
            type="text"
          />
          <form-error
            v-if="search.errors"
            :message="
              search.errors.invo_no
                ? search.errors.invo_no
                : ''
            "
          />
        </div>
        <div>
          <form-label for="merchant">
            {{ t("Merchant") }}
          </form-label>
          <form-select
            id="merchant"
            v-model="search.merchant"
            class="mt-1"
            name="merchant"
            :items="merchantsOptions"
          />
          <form-error
            v-if="search.errors"
            :message="
              search.errors.noRecords
                ? search.errors.noRecords
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        v-if="(isSearchRestEnable && isSearch()) ||
          (search.inv_date_from != null
            || search.inv_date_to != null
            || search.inv_number != null
            || search.merchant != null)"
        class="btn-wide"
        type="button"
        @click="reset(), getBillingHistory()"
      >
        {{ t("Reset") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
        @click="getBillingHistory(1,sortColumn,null,true,true)"
      >
        {{ t("Search") }}
      </Button>
    </template>
  </slide-over>

  <modal-form
    :close-button="modalInvoicePreview.close_button"
    :confirm-button="modalInvoicePreview.confirm_button"
    :modal-size="modalInvoicePreview.size"
    :show="modalInvoicePreview.show"
    @closeModal="rmPdfPath !=null && showPreviousBillingPdf? removeMchAccPdfFile(rmPdfPath) : '', modalInvoicePreview.show = false"
  >
    <template #modal-body>
      <div v-if="showPreviousBillingPdf">
        <iframe
          :src="pdfPath+'#toolbar=0&view=FitH&navpanes=0'"
          width="100%"
          style="height: calc(100vh - 208px); background-color: #ffffff !important;"
        />
      </div>

      <div v-if="!showPreviousBillingPdf">
        <div class="mb-1 font-bold text-xl text-right text-black">
          {{ t("Transaction Invoice") }}
        </div>

        <!--      <div class=" flex items-start justify-end space-x-2">-->
        <!--        <button type="button">-->
        <!--          <svg-icon-->
        <!--            class="w-8 h-8 text-black"-->
        <!--            icon="print"-->
        <!--            @click="print('printSection')"-->
        <!--          />-->
        <!--        </button>-->

        <!--        <button type="button">-->
        <!--          <svg-icon-->
        <!--            class="w-8 h-8 text-black"-->
        <!--            icon="download"-->
        <!--            @click="downloadFile(previewInvoiceDetails.mch_acc_id, previewInvoiceDetails.mch_id, previewInvoiceDetails.inv_no)"-->
        <!--          />-->
        <!--        </button>-->
        <!--      </div>-->

        <div id="printSection">
          <div class="md:flex space-y-2 md:space-x-6 md:space-y-0">
            <div class="md:w-2/3 space-y-2">
              <div class="break-all flex">
                <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Organisation Name") }}: </span>
                <span class="w-full inline-block font-semibold text-black">{{ previewInvoiceDetails.account.mch_acc_name }}</span>
              </div>
              <div class="break-all flex">
                <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Plan") }}: </span>
                <span class="w-full inline-block font-semibold text-black">{{ previewInvoiceDetails.plan.plan_name }}</span>
              </div>
              <div class="break-all flex">
                <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Date") }}: </span>
                <span class="w-full inline-block font-semibold text-black">{{ formatDate(previewInvoiceDetails.inv_date, 'DD-MMMM-YYYY') }}</span>
              </div>
              <div class="break-all flex">
                <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Period") }}: </span>
                <span class="w-full inline-block font-semibold text-black">{{ formatDate(previewInvoiceDetails.inv_period, 'DD MMMM YYYY') }} to {{ formatDate(previewInvoiceDetails.inv_date, 'DD MMMM YYYY') }}</span>
              </div>
              <div class="break-all flex">
                <span class="w-64 inline-block font-semibold text-gray-500">{{ t("Invoice Number") }}: </span>
                <span class="w-full inline-block font-semibold text-black">{{ previewInvoiceDetails.inv_no }}</span>
              </div>
            </div>

            <div class="md:w-2/3">
              <div class="flex items-start">
                <div class="w-64">
                  <span class="font-semibold text-gray-500">{{ t("Address") }}:</span>
                </div>
                <div class="w-full">
                  <div class="mb-1 break-all">
                    <span class="mr-0.5 inline-block font-semibold text-black">{{ previewInvoiceDetails.account.address1 }}</span>
                    <span
                      v-if="previewInvoiceDetails.account.address2 != null"
                      class="inline-block font-semibold text-black"
                    >, {{ previewInvoiceDetails.account.address2 }}</span>
                  </div>
                  <div class="mb-1 break-all">
                    <span class="inline-block font-semibold text-black">{{ previewInvoiceDetails.account.suburb_name }}, {{ previewInvoiceDetails.account.state_name }}</span>
                  </div>
                  <div class="mb-1 break-all">
                    <span class="inline-block font-semibold text-black">{{ previewInvoiceDetails.account.country_name }} {{ previewInvoiceDetails.account.postcode }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-5 mb-3 text-base text-center font-semibold text-black">
            {{ t("Invoice Summary") }}
          </div>

          <TableDefault>
            <template #table>
              <table
                class="table table-clickable"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Month") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Tier Level") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span># {{ t("Transactions") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span>{{ t("Rate") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span>{{ t("Frequency") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span>{{ t("Total") }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <no-table-data v-if="!tierSummary.length" />
                  <tr
                    v-for="(tier, index4) in tierSummary"
                    :key="index4"
                  >
                    <td> {{ tier.inv_month }}</td>
                    <td>{{ tier.tier_no }}</td>
                    <td class="text-right">
                      {{ tier.tnx_count }}
                    </td>
                    <td class="text-right">
                      {{ tier.rate.toFixed(2) }}
                    </td>
                    <td
                      v-if="tier.inv_option == 'month'"
                      class="text-right"
                    >
                      {{ t("Monthly") }}
                    </td>
                    <td
                      v-if="tier.inv_option == 'free'"
                      class="text-right"
                    >
                      {{ t("Monthly Free Transaction") }}
                    </td>
                    <td
                      v-if="tier.inv_option == 'tnx'"
                      class="text-right"
                    >
                      {{ t("Transaction") }}
                    </td>
                    <td class="text-right">
                      {{ tier.amount.toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-semibold">
                      {{ t("Sub Total") }} ({{ previewInvoiceDetails.base_curr_code }})
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td class="font-semibold text-right">
                      {{ previewInvoiceDetails.inv_sub_total.toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-semibold">
                      Tax ({{ previewInvoiceDetails.inv_tax_rate }}%)
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td class="font-semibold text-right">
                      {{ (previewInvoiceDetails.inv_total - previewInvoiceDetails.inv_sub_total).toFixed(2) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-semibold">
                      <span class="text-black">{{ t("Total") }} ({{ previewInvoiceDetails.base_curr_code }})</span>
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td class="font-semibold text-right">
                      <span class="text-black">{{ previewInvoiceDetails.inv_total.toFixed(2) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TableDefault>

          <div class="mt-5 mb-3 text-base text-center font-semibold text-black">
            {{ previewInvoiceDetails.account.mch_acc_name }} {{ t("Transaction Details") }}
          </div>

          <TableDefault>
            <template #table>
              <table
                class="table table-clickable"
                aria-describedby=""
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction Date") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Document Ref") }} #</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Transaction Type") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Customer Name") }}</span>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-end">
                        <span>{{ t("Transaction Value") }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <no-table-data v-if="!invoiceTransactions.length" />
                  <tr
                    v-for="(transaction, index3) in invoiceTransactions"
                    :key="index3"
                  >
                    <td>{{ index3 + 1 }}</td>
                    <td>{{ formatDate(transaction.gw_trn_date, 'DD-MMM-YYYY') }}</td>
                    <td>{{ transaction.mch_ref }}</td>
                    <td>{{ transaction.trn_type }}</td>
                    <td>{{ transaction.customer_name }}</td>
                    <td class="text-right">
                      <span v-if="transaction.trn_type == 'REFUND'" />
                      {{ parseFloat(transaction.amount).toFixed(2) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </TableDefault>

          <div
            v-for="(account, index7) in subAccTransactions"
            :key="index7"
          >
            <div
              class="mt-5 mb-3 text-base text-center font-semibold text-black"
            >
              {{ account.mch_acc_name }} {{ t("Transaction Details") }}
            </div>

            <TableDefault>
              <template
                #table
              >
                <table
                  class="table table-clickable"
                  aria-describedby=""
                >
                  <thead>
                    <tr>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Transaction") }}</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Transaction Date") }}</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Document Ref") }} #</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Transaction Type") }}</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center">
                          <span>{{ t("Customer Name") }}</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div class="flex items-center justify-end">
                          <span>{{ t("Transaction Value") }}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <no-table-data v-if="!account.transactions.length" />
                    <tr
                      v-for="(accountTrn, index8) in account.transactions"
                      :key="index8"
                    >
                      <td>{{ index8 + 1 }}</td>
                      <td>{{ formatDate(accountTrn.gw_trn_date, 'DD-MMM-YYYY') }}</td>
                      <td>{{ accountTrn.mch_ref }}</td>
                      <td>{{ accountTrn.trn_type }}</td>
                      <td>{{ accountTrn.customer_name }}</td>
                      <td class="text-right">
                        <span v-if="accountTrn.trn_type == 'REFUND'" />
                        {{ parseFloat(accountTrn.amount).toFixed(2) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </TableDefault>
          </div>
        </div>
      </div>
    </template>
  </modal-form>
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker from 'vue3-datepicker'
import Loading from 'vue3-loading-overlay'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ModalForm from '@/components/ModalForm.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from "axios";
import moment from "moment";
import FormError from '@/components/FormError.vue'
import router from "../../routes";

export default {
  name: 'BillingHistory',

  components: {
    Button,
    Search,
    Loading,
    SvgIcon,
    SlideOver,
    FormInput,
    FormLabel,
    FormSelect,
    FormError,
    Datepicker,
    PageHeader,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    ModalForm,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Billing History',
    })

    const sortColumn = ref('')
    const searchParam = ref('')
    const quickSearchText = ref('')
    const totalRecord = ref(0)
    const lastPage = ref(0)
    const currentPage = ref(0)
    const perPage = ref('10')
    const isRestFilterEnable = ref(false)
    const isSearchRestEnable = ref(false)
    const billingHistoryDetails = ref([])
    const merchantsOptions = ref([{ value: null, text: 'Select a Tax Group' }])
    const invoiceTransactions = ref(0)
    const tierSummary = ref(0)
    const previewInvoiceDetails = ref(0)
    const subAccTransactions = ref([])
    const pdfPath = ref(0)
    const rmPdfPath = ref(0)
    const showPreviousBillingPdf = ref(JSON.parse(process.env.MIX_BILLING_HISTORY_SHOW_PREVIOUS_PDF || false))

    const search = reactive({
      inv_date_from: null,
      inv_date_to: null,
      inv_number: null,
      merchant: null,
      errors: null,
    })

    const searchValues = reactive({
      inv_date_from: null,
      inv_date_to: null,
      inv_number: null,
      merchant: null,
    })

    const modalInvoicePreview = reactive({
      show: false,
      size: 'max-w-7xl',
      close_button: false,
      confirm_button: false,
    })

    getAllMerchantsList()

    function formatDate(date, format){
      return moment(date).format(format)
    }

    function getBillingHistory(
      page = 1,
      column = sortColumn.value,
      quickSearch = null,
      isSlideOverChange = true,
      fromAdvanceSearch = false
    ) {
      if(search.merchant === ""){
        search.merchant = null
      }
      if(search.inv_number === ""){
        search.inv_number = null
      }
      billingHistoryDetails.value.length = 0

      const max = /^.{0,100}$/
      let index = 0
      if (search.inv_date_from != null ||
        search.inv_date_to != null ||
        search.inv_number != null ||
        search.merchant != null ||
        billingHistoryDetails.value.length != 0
      ) {
        isLoading.value = true
        let searchText = ''
        if (isSearch()) {
          if (!max.test(search.inv_number)) {
            search.errors = {
              invo_no: 'Maximum length for invoice number is 100.'
            }
            index++
          }
          isSearchRestEnable.value = true
          isLoading.value = false
        }
        if (index == 0) {
          if (isSearch()) {
            isSearchRestEnable.value = true
            searchParam.value = '&search='
            searchText = JSON.stringify(search)
          }
          if (quickSearch != null) {
            quickSearchText.value = quickSearch
          }else{
            quickSearchText.value = ''
          }
          search.errors = null;
          axios
            .get('v1/billing/get-billing-history', {
              params: {
                quickSearch: quickSearchText.value,
                search: searchText,
                count_per_page: perPage.value,
                page: page,
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
              if (response.data.data != null) {
                isLoading.value = false
                billingHistoryDetails.value = response.data.data.data
                lastPage.value = response.data.data.last_page
                currentPage.value = response.data.data.current_page
                totalRecord.value = response.data.data.total

                if(fromAdvanceSearch){
                  if (response.data.data.data.length > 0) {
                    isSearchRestEnable.value = true
                    isRestFilterEnable.value = true
                    if(isSlideOverChange) {
                      slideOver.show = false
                    }
                  } else  {
                    search.errors = {
                      noRecords: "No invoices found that match the criteria entered. Please change the criteria and search again. "
                    }
                    isRestFilterEnable.value = false
                    isSearchRestEnable.value = true
                  }
                }

              } else {
                slideOver.show = false
                console.error('Error!')
              }
            })
          searchValues.inv_date_from = search.inv_date_from
          searchValues.inv_date_to = search.inv_date_to
          searchValues.inv_number = search.inv_number
          searchValues.merchant = search.merchant
          searchDetails()
        }
     }
      else{
        search.errors = {
          noRecords: "No search filters are found. Please select and try again."
        }
        isRestFilterEnable.value = false

        searchValues.inv_date_from = null
        searchValues.inv_date_to = null
        searchValues.inv_number = null
        searchValues.merchant = null

        search.inv_date_from = null
        search.inv_date_to = null
        search.inv_number = null
        search.merchant = null

        lastPage.value = 0
        currentPage.value = 0
        totalRecord.value = 0
      }
    }

    function isSearch() {
      if (
        search.inv_date_from != null ||
        search.inv_date_to != null ||
        search.inv_number != null ||
        search.merchant != null
      ) {
        return true
      } else {
        return false
      }
    }

    function focusElement(el) {
      nextTick(() => {
        const focusRef = document.getElementById(el)
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function searchDetails() {
      search.inv_date_from = searchValues.inv_date_from
      search.inv_date_to = searchValues.inv_date_to
      search.inv_number = searchValues.inv_number
      search.merchant = searchValues.merchant
    }

    function getAllMerchantsList() {
      merchantsOptions.value = [{ value: '', text: 'Select a Merchant' }]

      axios
        .get('v1/get-all-merchant-accounts-is-invoice-directly')
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((merchant) => {
              let obj = {}
              obj.text = merchant.mch_acc_name
              obj.value = merchant.mch_acc_id
              merchantsOptions.value.push(obj)
            })
          }else if (response.data.error != null){
            router.push({ name: 'unauthorized-access'})
          }
        })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function resetFilters(){
      billingHistoryDetails.value.length = 0
      perPage.value = 10
      quickSearchText.value = ''
      search.inv_date_from = null
      search.inv_date_to = null
      search.inv_number = null
      search.merchant = null
      search.errors = null
      searchValues.inv_date_from = null
      searchValues.inv_date_to = null
      searchValues.inv_number = null
      searchValues.merchant = null
      totalRecord.value = 0
    }

    function reset(){
      search.inv_date_from = null
      search.inv_date_to = null
      search.inv_number = null
      search.merchant = null
      search.errors = null
      searchValues.inv_date_from = null
      searchValues.inv_date_to = null
      searchValues.inv_number = null
      searchValues.merchant = null
      slideOver.show = false
    }

    function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      getBillingHistory(1, sortColumn.value, quickSearchText.value)
    }

    function viewInvoiceDetails(invBtcNo, invNo){
      isLoading.value = true
      if (showPreviousBillingPdf.value){
        axios.get('/v1/billing/preview-invoice', {
          responseType: 'arraybuffer',
          params: {
            inv_batch_no: invBtcNo,
            inv_no: invNo,
          },
        })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              let resultHeader = response.headers['x-result'];
              if (resultHeader === undefined){
                rmPdfPath.value = null
              }
              rmPdfPath.value = resultHeader
              let blob = new Blob([response.data], { type: 'application/pdf' });
              let url = URL.createObjectURL(blob);
              this.pdfPath = url;
              modalInvoicePreview.show = true
            } else {
              isLoading.value = false
              console.error('Error!')
            }
          })
      }else {
        axios.get('/v1/billing/preview-invoice', {
          params: {
            inv_batch_no: invBtcNo,
            inv_no: invNo,
          },
        })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              previewInvoiceDetails.value = response.data.data.invoice
              invoiceTransactions.value = response.data.data.transactions
              tierSummary.value = response.data.data.tiers
              subAccTransactions.value = response.data.data.sub_acc_transactions
              modalInvoicePreview.show = true
            } else {
              isLoading.value = false
              console.error('Error!')
            }
          })
        // modalInvoicePreview.show = true
    }
  }

    function downloadFile(mchAccId, mchId, invNo) {
      isLoading.value = true
      let fileNameForDownload = 'TDB.pdf'
      let contentType = '';

      axios.get("v1/billing/download_pdf",
        {
          responseType: 'arraybuffer',
          params: {
            content_type: contentType,
            mch_acc_id: mchAccId,
            mch_id: mchId,
            inv_no: invNo
          }
        }).then(response => {
        isLoading.value = false
        let blob = new Blob([response.data], {type: contentType})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileNameForDownload;
        document.body.appendChild(link);
        link.click();
      });
    }

    function removeMchAccPdfFile(rmPdfPath){
      axios.get('/v1/billing/remove-billing-history-invoice-pdf', {
        params: {
          rm_pdf_path:rmPdfPath
        },
      }).then((response) =>{})
    }

    return {
      t,
      fullPage,
      isLoading,
      slideOver,
      sortColumnDirection,
      getBillingHistory,
      sortColumn,
      searchParam,
      quickSearchText,
      totalRecord,
      lastPage,
      currentPage,
      perPage,
      billingHistoryDetails,
      search,
      isSearch,
      searchValues,
      searchDetails,
      getAllMerchantsList,
      merchantsOptions,
      resetFilters,
      sort,
      isRestFilterEnable,
      isSearchRestEnable,
      modalInvoicePreview,
      viewInvoiceDetails,
      previewInvoiceDetails,
      invoiceTransactions,
      tierSummary,
      subAccTransactions,
      formatDate,
      reset,
      downloadFile,
      removeMchAccPdfFile,
      pdfPath,
      rmPdfPath,
      showPreviousBillingPdf
    }
  },
}
</script>
