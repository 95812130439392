<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <div class="flex items-center ">
          <back-button
            v-if="userType == 'TNT'"
            :route-to="{ name: 'qrprogram-index' }"
          />
          <back-button
            v-if="userType == 'MCH'"
            :route-to="{ name: 'merchant-qrprogram-index' }"
          />
          <h1
            id="page-title"
            class="page-title"
          >
            {{ qrProgramName }} {{ "QR Transactions" }}
          </h1>
        </div>

        <Button
          id="button_search-transactions"
          class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
          type="button"
          @click="(searchSlideOver.show = true) , searchMerchantTransaction()"
        >
          {{ t("Filter") }}
        </Button>
      </div>
      <div class="mt-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-4 break-words">
        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Merchant Account") }}
          </div>

          <div class="flex flex-wrap items-center gap-x-2">
            <div

              class="mr-1 font-medium text-white text-base break-all w-full"
            >
              {{ qrMchAccName }}
            </div>
          </div>
        </div>



        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("QR Program") }}
          </div>

          <div class="font-medium text-white text-base">
            {{ qrProgramName }}
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Transaction Type") }}
          </div>

          <div class="font-medium text-white text-base">
            QR Payment
          </div>
        </div>

        <div>
          <div class="text-white text-opacity-50 text-sm">
            {{ t("Total Value") }}
          </div>

          <div
            v-if="qrProgramTotal != 'null'"
            class="font-medium text-white text-base"
          >
            {{ qrProgramTotal }}
          </div>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getAllTransactionsByQrProgramId()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="flex flex-wrap items-center justify-center mx-1">
            <ButtonSecondary
              v-if="merchantTransactions.length"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="exportType = 'xlsx',exportTransactions()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="export"
                stroke-width="2.5"
              />
              <span>{{ t("Export Excel") }}</span>
            </ButtonSecondary>
            <ButtonSecondary
              v-if="merchantTransactions.length"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="exportType = 'csv',exportTransactions()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="export"
                stroke-width="2.5"
              />
              <span>{{ t("Export CSV") }}</span>
            </ButtonSecondary>

            <ButtonSecondary
              v-if="false"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="resetSearch(), getAllTransactionsByQrProgramId(), isRestFilterEnable=false"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="refresh"
                stroke-width="2.5"
              />
              <span>{{ t("Reset Filters") }}</span>
            </ButtonSecondary>
          </div>

          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="quickSearchText"
              @dataFetchfunction="
                getAllTransactionsByQrProgramId(1, sortColumn, $event)
              "
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Transaction Date") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('transaction_date')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'transaction_date'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="w-72"
                >
                  <div class="flex items-center">
                    <span>{{ t("Document Number (Reference)") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('mch_ref')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'mch_ref'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="w-44"
                >
                  <div class="flex items-center justify-end">
                    <span>{{ t("Amount") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('amount')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'amount'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Payor Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('payer_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'payer_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Payment Method") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('pay_method')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'pay_method'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(merchantTransaction, index) in merchantTransactions"
                :id="'transactions_admin_portal_' + index"
                :key="index"
              >
                <td>{{ formatDateTime(merchantTransaction.created_at) }}</td>
                <td>{{ merchantTransaction.mch_ref }}</td>
                <td class="text-right">
                  {{ merchantTransaction.amount ? (parseFloat(merchantTransaction.amount.split(' ')[0]).toFixed(2) + ' ' + merchantTransaction.amount.split(' ')[1]) : '-' }}
                </td>

                <td style="max-width: 130px">
                  {{ (merchantTransaction.payer_name ? merchantTransaction.payer_name : '-' )+ " " + (merchantTransaction.payer_last_name ? merchantTransaction.payer_last_name : '') }}
                </td>
                <td>{{ merchantTransaction.pay_method }}</td>
                <td class="text-center">
                  <badge
                    v-if="merchantTransaction.pay_status =='GW_APPROVED' ||
                      merchantTransaction.pay_status =='GW_CAPTURED'||
                      merchantTransaction.pay_status =='GW_SETTLED' ||
                      merchantTransaction.pay_status =='GW_RETURNED'"
                    class="w-28 block"
                    type="success"
                    text="SUCCESS"
                  />
                  <badge
                    v-if="merchantTransaction.pay_status =='GW_CANCELLED'"
                    class="w-28 block"
                    type="warning"
                    text="CANCEL"
                  />
                  <badge
                    v-if="merchantTransaction.pay_status =='GW_ERROR' ||
                      merchantTransaction.pay_status =='GW_FAILED'"
                    class="w-28 block"
                    type="danger"
                    text="FAIL"
                  />
                  <badge
                    v-if="merchantTransaction.pay_status =='GW_DECLINED'"
                    class="w-28 block"
                    type="decline"
                    text="DECLINE"
                  />
                  <badge
                    v-if="merchantTransaction.pay_status =='GW_INIT' ||
                      merchantTransaction.pay_status =='GW_PROCESSING'||
                      merchantTransaction.pay_status =='GW_VERIFY_SIG' ||
                      merchantTransaction.pay_status =='INIT' ||
                      merchantTransaction.pay_status =='GW_PENDING'"
                    class="w-28 block"
                    type="info"
                    text="PENDING"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center space-x-5">
                    <button-circle
                      :id="'transactions_admin_portal_view_' + index"
                      type="info"
                      icon="eye"
                      @click.stop="previewSlideOver.show = true, getMerchantTransaction(merchantTransaction.pay_trn_id)"
                    />
                  </div>
                </td>
              </tr>

              <no-table-data v-if="totalRecord == 0" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getAllTransactionsByQrProgramId($event)"
      />
    </div>
  </div>

  <slide-over
    :show="previewSlideOver.show"
    :slideover-size="previewSlideOver.size"
    :slideover-title="previewSlideOver.title"
    :close-button="previewSlideOver.close_button"
    :close-button-text="previewSlideOver.close_button_text"
    @closeSlideOver="previewSlideOver.show = false"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Transaction Details") }}
      </div>
      <div>
        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant Account") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.merchant }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Merchant ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.mch_id }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Document Number (Reference)") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.document_number }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Date") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ formatDateTime(form.transaction_date) }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Transaction Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              QR Payment
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70" />
            <div class="mt-1 text-black font-medium" />
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Amount") }}
            </div>
            <div
              v-if="form.amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? '-' + Math.abs(form.amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.amount ? form.amount + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Fee") }}
            </div>
            <div
              v-if="form.gw_fee < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? '-' + Math.abs(form.gw_fee).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.gw_fee ? form.gw_fee + ' ' + form.currency_code : '' }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Net") }}
            </div>
            <div
              v-if="form.net_amount < 0"
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? '-' + Math.abs(form.net_amount).toFixed(decimalPlaces) + ' ' + form.currency_code : '' }}
            </div>
            <div
              v-else
              class="mt-1 text-black font-medium"
            >
              {{ form.net_amount ? form.net_amount + ' ' + form.currency_code : '' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Customer Details") }}
        </div>
        <!--first row-->
        <div class="mb-4 grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("First Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.first_name ? form.first_name : '-' }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Last Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.last_name ? form.last_name : '-' }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Email Address") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.email_address ? form.email_address : '-' }}
            </div>
          </div>
        </div>

        <!--second row-->
        <div class="mb-4 grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Billing Address Line 1") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.billing_address ? form.billing_address : '-' }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Country") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.country ? form.country : '-' }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("State") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.state ? form.state : '-' }}
            </div>
          </div>
        </div>

        <!--third row-->
        <div class="mb-4 grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("City") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.city ? form.city : '-' }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Postal") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.postal_code ? form.postal_code : '-' }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payor Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ (form.payer_name ? form.payer_name : '-' )+ " " + (form.payer_last_name ? form.payer_last_name : '') }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payor Email") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.payer_email }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Origin of Transaction") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.origin_country_name }}
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("Payment Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Type") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_type }}
            </div>
          </div>
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Payment Method") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_method }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Card Details") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.card_details }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Name") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.paymentDevice }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device Location") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pd_location }}
            </div>
          </div>

          <div>
            <div
              v-if="form.pay_type == 'CARD_PRESENT'"
              class="text-xs font-normal text-black opacity-70"
            >
              {{ t("Device ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.pd_serial_number && form.encrypt_pd_serial_number == constantValue"
                  class="absolute inset-y-0 left-0 m-px border-l rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.pd_serial_number, 'pd_serial_no')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium">
                  {{ form.encrypt_pd_serial_number }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <divider />

        <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
          {{ t("opuspay Details") }}
        </div>

        <div class="grid md:grid-cols-3 gap-4 break-words">
          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("opuspay Tracking ID") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_trn_ref }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.gateway }}
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Gateway Token") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <div class="relative">
                <button
                  v-if="form.gw_token && form.encrypt_gw_token == constantValue"
                  class="absolute inset-y-0 left-o m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                  type="button"
                  @click="decryptData(form.gw_token, 'gw_token')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                    />
                  </svg>
                </button>
                <div class="mt-1 text-black font-medium  pr-10">
                  {{ form.encrypt_gw_token }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Status") }}
            </div>
            <div class="mt-1 text-black font-medium">
              <badge
                v-if="form.status =='GW_APPROVED' ||
                  form.status =='GW_CAPTURED'||
                  form.status =='GW_SETTLED' ||
                  form.status =='GW_RETURNED'"
                type="success"
                text="SUCCESS"
              />
              <badge
                v-if="form.status =='GW_CANCELLED'"
                type="warning"
                text="CANCEL"
              />
              <badge
                v-if="form.status =='GW_ERROR' ||
                  form.status =='GW_FAILED'"
                type="danger"
                text="FAIL"
              />
              <badge
                v-if="form.status =='GW_DECLINED'"
                type="decline"
                text="DECLINE"
              />
              <badge
                v-if="form.status =='GW_INIT' ||
                  form.status =='GW_PROCESSING'||
                  form.status =='GW_VERIFY_SIG' ||
                  form.status =='INIT' ||
                  form.status =='GW_PENDING'"
                type="info"
                text="PENDING"
              />
            </div>
          </div>

          <!--          <div>-->
          <!--            <div class="text-xs font-normal text-black opacity-70">-->
          <!--              {{ t("opuspay Token") }}-->
          <!--            </div>-->
          <!--            <div class="mt-1 text-black font-medium">-->
          <!--              {{ form.pay_trn_ref }}-->
          <!--            </div>-->
          <!--          </div>-->

          <div v-if="form.status == 'GW_DECLINED' || form.status == 'GW_ERROR' || form.status == 'GW_FAILED'">
            <div class="text-xs font-normal text-black opacity-70">
              {{ t("Due to") }}
            </div>
            <div class="mt-1 text-black font-medium">
              {{ form.pay_msg }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </slide-over>

  <slide-over
    :show="searchSlideOver.show"
    :slideover-size="searchSlideOver.size"
    :slideover-title="t(searchSlideOver.title)"
    :close-button="searchSlideOver.close_button"
    @closeSlideOver="searchSlideOver.show = false"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="from_date">
            {{ t("Transaction Date Range") }}
          </form-label>
          <div class="flex space-x-2 mt-1">
            <div class="w-1/2">
              <form-label for="from_date">
                {{ t("From Date") }}
              </form-label>
              <datepicker
                id="from_date"
                v-model="search.from_date"
                name="from_date"
                class="vdp-input mt-1"
                input-format="dd-MM-yyyy"
                :upper-limit="todayDate"
              />
            </div>
            <div class="w-1/2">
              <form-label for="to_date">
                {{ t("To Date") }}
              </form-label>
              <datepicker
                id="to_date"
                v-model="search.to_date"
                name="to_date"
                class="vdp-input mt-1"
                input-format="dd-MM-yyyy"
                :upper-limit="todayDate"
              />
            </div>
          </div>
          <form-error
            v-if="searchErrors.errors"
            :message="searchErrors.errors.invalidRecords ? searchErrors.errors.invalidRecords : ''"
          />
        </div>
        <div>
          <form-label for="invoice_no">
            {{ t("Document Number (Reference)") }}
          </form-label>
          <form-input
            id="invoice_no"
            v-model="search.invoice_number"
            class="mt-1"
            name="invoice_no"
            type="text"
          />
          <form-error
            v-if="searchErrors.invoice_number"
            :message="searchErrors.invoice_number ? searchErrors.invoice_number : ''"
          />
        </div>
        <div>
          <form-label for="amount">
            {{ t("Amount") }}
          </form-label>
          <div class="flex space-x-2">
            <div class="w-2/3 inputType-number">
              <form-input
                id="amount"
                v-model="search.amount"
                class="mt-1"
                name="amount"
                type="number"
                min="0"
                @keypress="onlyTwoDecimalNumbers"
              />
            </div>
            <div class="w-1/3">
              <form-select
                id="amount_limit"
                v-model="search.amount_range"
                class="mt-1"
                name="amount_limit"
                :items="range"
              />
            </div>
          </div>
        </div>
        <div>
          <form-label for="payer_name">
            {{ t("Payor Name") }}
          </form-label>
          <form-input
            id="payer_name"
            v-model="search.payer_name"
            class="mt-1"
            name="payer_name"
            type="text"
          />
        </div>

        <div>
          <form-label for="payer_name">
            {{ t("Payment Method") }}
          </form-label>
          <form-select
            id="payment_method"
            v-model="search.payment_method"
            class="mt-1"
            name="payment_type"
            :items="paymentMethodOptions"
          />
        </div>

        <div>
          <form-label for="status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="status"
            v-model="search.status"
            class="mt-1"
            name="payment_type"
            :items="statusOptions"
          />
        </div>
        <div>
          <form-error
            v-if="searchErrors.errors"
            :message="
              searchErrors.errors.noRecords
                ? searchErrors.errors.noRecords
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        v-if="true"
        class="btn-wide"
        type="button"
        @click="resetSearch(), getAllTransactionsByQrProgramId()"
      >
        {{ t("Reset") }}
      </ButtonSecondary>
      <Button
        class="btn-wide"
        type="button"
        @click="isRestFilterEnable=true,getAllTransactionsByQrProgramId(1,sortColumn,null,true)"
      >
        {{ t("Search") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import Datepicker from 'vue3-datepicker'
import Loading from 'vue3-loading-overlay'
import Badge from '@/components/Badge.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import Divider from '@/components/Divider.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import axios from "axios";
import moment from "moment";
import FormError from '@/components/FormError.vue'
import constant from "../constant";
import Modal from '@/components/Modal.vue'
import BackButton from "@/components/BackButton.vue";
import CryptoJs from 'crypto-js';


export default {
  name: 'Transactions',

  components: {
    BackButton,
    Badge,
    Search,
    Button,
    Loading,
    Divider,
    SvgIcon,
    SlideOver,
    FormInput,
    FormLabel,
    FormSelect,
    Datepicker,
    PageHeader,
    NoTableData,
    TableDefault,
    ButtonCircle,
    VuePagination,
    ButtonSecondary,
    FormError,
    Modal,

  },

  setup() {
    const qrProgramId = sessionStorage.getItem('qrProgramId');
    const qrProgramName = sessionStorage.getItem('qrProgramName');
    const qrMchAccName = sessionStorage.getItem('qrMchAccName');
    const qrProgramTotal = sessionStorage.getItem('qrProgramTotal');
    const todayDate = ref();
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const sortColumnDirection = ref('desc')
    const sortColumn = ref('')
    const searchParam = ref('')
    const lastPage = ref(0)
    const perPage = ref('10')
    const totalRecord = ref(0)
    const currentPage = ref(0)
    const merchantTransactions = ref(0)
    const merchantTransaction = ref(0)
    const isRestFilterEnable = ref(false)
    const quickSearchText = ref('')
    const exportType = ref('xlsx')
    const decimalPlaces = ref(constant.decimalPlaces)
    const constantValue = constant.encryptionKey
    const CryptoJsAesJson = constant.CryptoJsAesJson
    let user = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8))
    const userType = user.authentication_data.user.user.user_type
    const paymentMethodOptions = ref([
      { value: '', text: 'Please select a payment method' },
    ])
    const merchantAccountOptions = ref([
      { value: '', text: 'Please select a merchant account' },
    ])
    const range= [
      { value: '=', text: 'Equal' },
      { value: '>', text: 'Greater than' },
      { value: '<', text: 'Less than' },
      { value: '>=', text: 'Equal or greater than' },
      { value: '<=', text: 'Equal or less than' },
    ]
    const statusOptions = ref([
      { value: '', text: 'Please select a status' },
      { value: 'fail', text: 'Fail' },
      { value: 'decline', text: 'Decline' },
      { value: 'cancel', text: 'Cancel' },
      { value: 'pending', text: 'Pending' },
      { value: 'success', text: 'Success' },
    ])
    const modal = reactive({
      type: 'success',
      show: false,
      title: 'Success',
      message: '',
      close_button: true,
    })

    const previewSlideOver = reactive({
      show: false,
      size: 'max-w-4xl',
      close_button: true,
      close_button_text: 'Close',
      title: 'Transaction Summary',
    })

    const searchSlideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Search Transactions',
    })

    const search = reactive({
      merchant: null,
      transaction_date: null,
      invoice_number: null,
      amount: null,
      payer_name: null,
      payer_last_name: null,
      payment_type: null,
      payment_method: null,
      amount_range: null,
      date_range: null,
      status: null,
      transaction_type: null,
      from_date:null,
      to_date:null,
    })

    const searchValues = reactive({
      merchant: null,
      transaction_date: null,
      invoice_number: null,
      transaction_type: null,
      amount: null,
      payer_name: null,
      payer_last_name: null,
      payment_type: null,
      payment_method: null,
      date_range: null,
      amount_range: null,
      status: null,
      from_date:null,
      to_date:null,
    })

    const form = reactive({
      transaction_id: null,
      merchant: null,
      transaction_date: null,
      invoice_number: null,
      amount: null,
      payer_name: null,
      payer_last_name: null,
      payment_type: null,
      status: null,
      gateway: null,
      customer_name:null,
      gw_fee: null,
      net_amount: null,
      payer_email: null,
      origin_country_name: null,
      pay_method: null,
      pay_type: null,
      card_details: null,
      pay_device_name: null,
      pd_location: null,
      pd_serial_number: null,
      pay_trn_ref: null,
      gw_token: null,
      document_number: null,
      mch_id: null,
      currency_code: null,
      pay_msg: null,
      encrypt_gw_token : constant.encryptionKey,
      encrypt_pd_serial_number : constant.encryptionKey,
      first_name: null,
      last_name: null,
      email_address: null,
      billing_address: null,
      country: null,
      state: null,
      city: null,
      postal_code :null
    })

    function resetTransactionSummary(){
      form.transaction_id = null
      form.amount = null
      form.payer_name = null
      form.payer_last_name = null
      form.status = null
      form.gateway = null
      form.payment_type = null

      form.gw_fee = null
      form.net_amount = null
      form.payer_email = null
      form.origin_country_name = null
      form.pay_method = null
      form.pay_type = null
      form.card_details = null
      form.paymentDevice = null
      form.pd_location = null
      form.pd_serial_number = null
      form.pay_trn_ref = null
      form.gw_token = null
      form.document_number = null
      form.mch_id = null
      form.merchant = null
      form.transaction_date = null
      form.pay_msg = null
      form.first_name = null
      form.last_name = null
      form.email_address = null
      form.billing_address = null
      form.country = null
      form.state = null
      form.city = null
      form.postal_code = null

    }

    const searchErrors = reactive({
      invoice_number: null,
      transaction_type: null,
      errors: null,
    })

    const decryptDataForm = reactive({
      data: '',
    })

    function focusElement() {
      nextTick(() => {
        const focusRef = document.getElementById('merchant_account')
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function formatDateTime(dateTime, format = 'DD-MMM-YYYY') {
      if (!moment(dateTime, format).isValid()) {
        return moment(dateTime).format(format)
      } else {
        return moment(dateTime, format).format(format)
      }
    }

    async function searchMerchantTransaction() {
      searchErrors.errors = ''
      if(search.payment_method == null){
        paymentMethodOptions.value = ([
          { value: '', text: 'Please select a payment method' },
        ])
      }
      searchErrors.invoice_number = ''
      searchErrors.transaction_type = ''
      search.merchant = searchValues.merchant
      search.invoice_number = searchValues.invoice_number
      search.transaction_type = searchValues.transaction_type
      search.amount = searchValues.amount
      search.payer_name = searchValues.payer_name
      search.payment_type = searchValues.payment_type
      search.payment_method = searchValues.payment_method
      if(paymentMethodOptions.value.length <= 1 && searchSlideOver.show == true){
        await getAllPaymentMethodOptions()
      }
      if(merchantAccountOptions.value.length <= 1 && searchSlideOver.show == true){
        await getAllMerchantAccountOptions()
      }
      if(searchValues.amount_range != null){
        search.amount_range = searchValues.amount_range
      }
      else{
        search.amount_range = "="
      }
    }

    function isSearch() {
      if (
          search.merchant != null ||
          search.transaction_date != null ||
          search.invoice_number != null ||
          search.transaction_type != null ||
          search.amount != null ||
          search.payer_name != null ||
          search.payer_last_name != null ||
          search.payment_type != null ||
          search.payment_method != null ||
          search.status ||
          search.from_date != null ||
          search.to_date != null
      ) {
        return true
      } else {
        return false
      }
    }

    async function resetSearch(){
      search.merchant = null
      search.transaction_date = null
      search.invoice_number = null
      search.transaction_type = null
      search.amount = null
      search.payer_name =null
      search.payer_last_name =null
      search.payment_type = null
      search.payment_method = null
      search.status = null
      search.date_range = "<="
      search.amount_range = "="
      search.from_date = null
      search.to_date = null
      searchValues.merchant = null
      searchValues.transaction_date = null
      searchValues.invoice_number = null
      searchValues.amount = null
      searchValues.status = null
      searchValues.payer_name = null
      searchValues.payer_last_name = null
      searchValues.payment_type = null
      searchValues.status = null
      searchValues.from_date = null
      searchValues.to_date = null
      searchValues.date_range = "<="
      searchValues.amount_range = "<="
      searchErrors.invoice_number = ''
      searchErrors.transaction_type = ''
      searchErrors.errors = ''
    }

    async function getMerchantTransaction(transaction_id){
      merchantTransaction.value = null
      form.encrypt_gw_token = ''
      form.encrypt_pd_serial_number = ''
      resetTransactionSummary()
      await axios.get('v1/qr-payment/tran-view/' + transaction_id).then((response) => {
        if (response.data.data != null) {
          let merchant = response.data.data
          form.transaction_id = merchant.pay_trn_id
          if(merchant.amount != null){
            form.amount = Number((merchant.amount)).toFixed(constant.decimalPlaces)
          }else{
            form.amount = merchant.amount
          }
          form.payer_name = merchant.payer_name
          form.payer_last_name = merchant.payer_last_name
          form.status = merchant.status
          form.gateway = merchant.gateway.name
          form.payment_type = merchant.payment_type
          if(merchant.gw_fee != null){
            form.gw_fee = Number(merchant.gw_fee).toFixed(constant.decimalPlaces)
          }else{
            form.gw_fee = merchant.gw_fee
          }
          if(merchant.net_amount != null){
            form.net_amount = Number(merchant.net_amount).toFixed(constant.decimalPlaces)
          }else{
            form.net_amount = merchant.net_amount
          }
          form.payer_email = "N/A"
          form.origin_country_name = "N/A"
          form.pay_method = merchant.pay_method
          form.pay_type = merchant.pay_type
          form.card_details = merchant.card_details
          form.pd_location = merchant.pd_location
          form.pd_serial_number = merchant.pd_serial_number
          if(form.pd_serial_number){
            form.encrypt_pd_serial_number = constant.encryptionKey
          }
          form.pay_trn_ref = merchant.pay_trn_ref
          form.gw_token = merchant.gw_token
          if(form.gw_token){
            form.encrypt_gw_token = constant.encryptionKey
          }
          form.document_number = merchant.mch_ref
          form.mch_id = merchant.mch_id
          form.merchant = merchant.merchant.mch_acc_name
          form.transaction_date = merchant.created_at
          form.currency_code = merchant.currency.currency_code
          form.customer_name = merchant.customer_name
          form.first_name = merchant.first_name
          form.last_name = merchant.last_name
          form.email_address = merchant.email
          form.billing_address = merchant.address_line1
          form.country = merchant.country
          form.state = merchant.state
          form.city = merchant.city
          form.postal_code = merchant.postal_code
          form.pay_msg = merchant.pay_msg
          if(merchant.pay_device != null){
            form.paymentDevice = merchant.pay_device.pd_name
          }
        }
      })
    }

    async function getAllPaymentMethodOptions() {
      isLoading.value = true
      await axios
          .get('v1/gateway/payment-methods/get-tnt-payment-methods')
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((paymentMethod) => {
                let obj = {}
                obj.value = paymentMethod.description
                obj.text = paymentMethod.description
                paymentMethodOptions.value.push(obj)
              })
            }
          })
      isLoading.value = false
    }

    async function getAllMerchantAccountOptions() {
      isLoading.value = true
      await axios
          .get('v1/get-all-merchant-account')
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((merchantAccount) => {
                let obj = {}
                obj.value = merchantAccount.mch_acc_id
                obj.text = merchantAccount.mch_acc_name
                merchantAccountOptions.value.push(obj)
              })
            }
          })
      isLoading.value = false
    }
    function setEnvironment() {
      search.merchant = null
      search.transaction_date = null
      search.invoice_number = null
      search.transaction_type = null
      search.amount = null
      search.payer_name =null
      search.payer_last_name =null
      search.payment_type = null
      search.payment_method = null
    }

    function resetErrors() {
      search.invoice_number = null
    }

    function onlyTwoDecimalNumbers($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which

      // only allow number and one dot
      if (
          (keyCode < 48 || keyCode > 57) &&
          (keyCode !== 46 || (search.amount != null && search.amount.indexOf('.') != -1))
      ) {
        // 46 is dot
        $event.preventDefault()
      }

      // restrict to 2 decimal places
      if (
          search.amount != null &&
          search.amount.indexOf('.') > -1 &&
          search.amount.split('.')[1].length > 1
      ) {
        $event.preventDefault()
      }

      if (
          search.amount != null &&
          search.amount.indexOf('.') > -1 &&
          search.amount.toString().indexOf('.') == 0
      ) {
        search.amount = '0' + search.amount
        $event.preventDefault()
      }
    }

    function decryptData(data, value){
      decryptDataForm.data = data
      axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
        if (response.data != null) {
          if(value == 'gw_token'){
            form.encrypt_gw_token = response.data
          }
          else if(value == 'pd_serial_no'){
            form.encrypt_pd_serial_number = response.data
          }

        } else {
          console.error('Error!')
        }
      })
    }

    function closeAlert() {
      modal.show = false
    }

    //get all transactions related to qr program id
    async function getAllTransactionsByQrProgramId(page = 1, column = sortColumn.value, quickSearch = null, fromAdvanceSearch = false) {
      const max = /^.{0,255}$/
      let index = 0
      const currentDate = moment().format('YYYY-MM-DD');
      todayDate.value = new Date(currentDate);
      todayDate.value = todayDate.value.setDate(todayDate.value.getDate())

      if (!max.test(search.invoice_number)) {
        searchErrors.invoice_number = 'Maximum length for document number (reference) is 255.'
        index++
      }
      if (!max.test(search.transaction_type)) {
        searchErrors.transaction_type = 'Maximum length for transaction type is 255.'
        index++
      }

      if(fromAdvanceSearch == true && search.from_date > search.to_date){
        searchErrors.errors = {
          invalidRecords: "Please select a valid date range."
        }
        index++
      }

      if (index == 0){
        isLoading.value = true
        let searchText = ''
        if (isSearch()) {
          searchParam.value = '&search='
          searchText = JSON.stringify(search)
        }
        if (quickSearch != null) {
          quickSearchText.value = quickSearch
        }

        await axios
            .get('/v1/qr-payment/get-all-qr-transactions/' + qrProgramId, {
              params: {
                quickSearch: quickSearchText.value,
                search: searchText,
                count_per_page: perPage.value,
                page: page,
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
              if (response.data != null) {
                isLoading.value = false
                merchantTransactions.value = response.data.data.data
                lastPage.value = response.data.data.last_page
                totalRecord.value = response.data.data.total
                currentPage.value = response.data.data.current_page

                if(fromAdvanceSearch == true &&
                    search.merchant == null &&
                    search.transaction_date == null &&
                    search.invoice_number == null &&
                    search.amount == null &&
                    search.payer_name == null &&
                    search.payment_method == null &&
                    search.transaction_type == null &&
                    search.status == null &&
                    search.to_date == null &&
                    search.from_date == null
                ){
                  searchErrors.errors = {
                    noRecords: "No search filters are found. Please select and try again."
                  }
                  isRestFilterEnable.value = false
                }else{
                  setSearchValues()
                  searchMerchantTransaction()
                  searchSlideOver.show = false
                }
              } else {
                searchSlideOver.show = false
              }
            })
      }
    }

    //sorting
    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

      await getAllTransactionsByQrProgramId(1, sortColumn.value, quickSearchText.value)
    }

    //set search values
    function setSearchValues(){
      searchValues.merchant = search.merchant
      searchValues.transaction_date = search.transaction_date
      searchValues.invoice_number = search.invoice_number
      searchValues.transaction_type = search.transaction_type
      searchValues.amount = search.amount
      searchValues.payer_name = search.payer_name
      searchValues.payment_type = search.payment_type
      searchValues.payment_method = search.payment_method
      searchValues.date_range = search.date_range
      searchValues.amount_range = search.amount_range
      searchValues.status = search.status
      searchValues.to_date = search.to_date
      searchValues.from_date = search.from_date
    }

    //export transactions
    async function exportTransactions(page = 1, column = sortColumn.value, quickSearch = null) {
      isLoading.value = true
      let searchText = ''
      if (isSearch()) {
        searchParam.value = '&search='
        searchText = JSON.stringify(search)
      }
      if (quickSearch != null) {
        quickSearchText.value = quickSearch
      }

      let dataTime = moment().format("YYYY-MM-DD h:mm:ss");

      await axios
      ({
        method: 'get',
        responseType: 'blob',
        url: 'v1/qr-payment/transactions/export',
        params: {
          quickSearch: quickSearchText.value,
          search: searchText,
          count_per_page: totalRecord.value,
          page: 1,
          sortColumn: column,
          sortDirection: sortColumnDirection.value,
          exportType: exportType.value,
          qrProgramId: qrProgramId,
          dataTime: dataTime,
        },
      })
      if(exportType.value == 'xlsx'){
        modal.message = "QR Transaction Excel will be sent to your business email address."
      }else{
        modal.message = "QR Transaction CSV will be  sent to your business email address."

      }
      modal.show=true
      isLoading.value = false
    }

    return {
      t,
      fullPage,
      isLoading,
      searchSlideOver,
      previewSlideOver,
      sortColumnDirection,
      sortColumn,
      searchParam,
      lastPage,
      perPage,
      merchantTransactions,
      formatDateTime,
      totalRecord,
      sort,
      searchMerchantTransaction,
      search,
      searchValues,
      isSearch,
      quickSearchText,
      isRestFilterEnable,
      resetSearch,
      getMerchantTransaction,
      form,
      paymentMethodOptions,
      range,
      merchantAccountOptions,
      setEnvironment,
      exportTransactions,
      exportType,
      searchErrors,
      onlyTwoDecimalNumbers,
      decimalPlaces,
      statusOptions,
      decryptData,
      constantValue,
      closeAlert,
      modal,
      getAllTransactionsByQrProgramId,
      qrMchAccName,
      qrProgramName,
      qrProgramTotal,
      userType,
      todayDate
    }
  },
}
</script>
