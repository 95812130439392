<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <!-- start  mulitple GWs ui  ui   ---->

  <div class="mb-5">
    <div class="pr-4">
      <h4 class="mb-3 font-medium text-black text-opacity-75 text-lg">
        {{ t("Please select one of the following options") }}:
      </h4>

      <div>
        <div class="mt-1 flex items-center">
          <form-radio
            id="multiiple-link-parent-gateway"
            v-model="form.is_parent_cred_used"
            name="multiiple-gateway_link"
            :checked="form.is_parent_cred_used == 1"
            :disable="isDisabled"
            @click="setToParentGatewayAccount()"
          />
          <label
            class="ml-2 text-sm"
            for="multiiple-link-parent-gateway"
          >{{ t("Link to the Parent Entity's gateway account(s)") }}</label>
        </div>
        <div class="mt-1 flex items-center">
          <form-radio
            id="multiiple-link-existing-gateway"
            v-model="form.is_parent_cred_used"
            name="multiiple-gateway_link"
            :checked="form.is_parent_cred_used == 0"
            :disabled="(form.act_as_agent == 1 || !isDisabled)"
            @click="setToExistingGatewayAccount()"
          />
          <label
            class="ml-2 text-sm"
            for="multiiple-link-existing-gateway"
          >{{ t("Link to an existing gateway accounts") }}</label>
        </div>
      </div>
    </div>
  </div>

  <TableDefault>
    <template #table>
      <table
        id="accounts_edit_payment_methods_tbl"
        aria-describedby=""
        class="table"
      >
        <thead>
          <tr>
            <th scope="col">
              <div class="flex items-center">
                <span>{{ t("Payment Gateways") }}</span>
                <button
                  class="inline-flex focus:outline-none opacity-40"
                  type="button"
                  @click="sortGateway('name')"
                >
                  <svg-icon
                    v-if="
                      sortColumnDirection == 'asc' &&
                        sortColumn == 'name'
                    "
                    class="text-black w-7 h-7"
                    icon="caret-up"
                  />
                  <svg-icon
                    v-else
                    class="text-black w-7 h-7"
                    icon="caret-down"
                  />
                </button>
              </div>
            </th>
            <th scope="col" />
            <th
              scope="col"
              class="th-action"
            >
              Actions
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(gateway_details, index) in gatewayDetails"
            :key="index"
          >
            <td>
              {{ gateway_details.name }}
            </td>
            <td>
              <div
                class="flex items-center justify-center space-x-5"
              >
                <form-checkbox
                  id="index_gateway"
                  name="gateway"
                  :checked="checkGatewayDetailsCheckbox(gateway_details.gw_id)"
                  :disabled="disableCheckbox(gateway_details.gw_id)"
                  @click="clickGateway($event, gateway_details.gw_id, gateway_details.name )"
                />
              </div>
            </td>
            <td>
              <div class="flex items-center justify-center space-x-5">
                <button-circle
                  type="info"
                  icon="eye"
                  :disabled="disableViewButton(gateway_details.gw_id)"
                  @click="(AddGwsSlideOver.show = true,
                           slideOver.show = false),
                          editPaymentGateway(gateway_details.gw_id, gateway_details.name),
                          getGatewayPaymentMethods(),
                          isView = true"
                />
                <button-circle
                  v-if="gateway_details.blueBtn"
                  type="info"
                  icon="edit"
                  :disabled="disableEditButton(gateway_details.gw_id)"
                  @click="(AddGwsSlideOver.show = true),
                          editPaymentGateway(gateway_details.gw_id, gateway_details.name),
                          getGatewayPaymentMethods(),
                          isView = false"
                />
                <tippy
                  v-if="gateway_details.redBtn"
                  placement="left"
                >
                  <button-circle
                    v-if="gateway_details.redBtn"
                    type="warning"
                    icon="warning"
                    @click="(AddGwsSlideOver.show = true),
                            editPaymentGateway(gateway_details.gw_id, gateway_details.name),
                            getGatewayPaymentMethods(),
                            isView = false"
                  />
                  <template #content>
                    <span>{{ t("Please complete configuration for this gateway.") }}</span>
                  </template>
                </tippy>
              </div>
            </td>
          </tr>

          <no-table-data v-if="gatewayDetails.length == 0" />
        </tbody>
      </table>
    </template>
  </TableDefault>

  <hr class="mt-5 mb-5">

  <div>
    <form-error
      v-if="errorMsgForGateway != ''"
      :message="errorMsgForGateway"
    />
  </div>

  <slide-over
    :show="slideOver.show"
    :mode="slideOver.editMode"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="(slideOver.show = false), (AddGwsSlideOver.show = true) "
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="gateway-name">
            {{ t("Gateway") }} <required />
          </form-label>
          <form-select
            id="gateway-name"
            v-model="form.gw_id"
            :items="gatewayOptions"
            class="mt-1"
            name="gateway"
            disabled
          />
        </div>

        <div>
          <form-label for="device-type">
            {{ t("Device Type") }} <required />
          </form-label>
          <form-select
            id="device-type"
            v-model="formDevice.gw_pd_id"
            :items="paymentDeviceOptions"
            class="mt-1"
            name="device_type"
          />

          <form-error
            v-if="formDevice.errors"
            :message="
              formDevice.errors.validations.gw_pd_id
                ? formDevice.errors.validations.gw_pd_id[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="device-name">
            {{ t("Name") }} <required />
          </form-label>
          <form-input
            id="device-name"
            v-model="formDevice.pd_name"
            class="mt-1"
            name="device_name"
            type="text"
            required
          />

          <form-error
            v-if="formDevice.errors"
            :message="
              formDevice.errors.validations.pd_name
                ? formDevice.errors.validations.pd_name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="device-id">
            {{ t("Device ID") }} <required />
          </form-label>
          <form-input
            id="device-id"
            v-model="formDevice.pd_serial_number"
            class="mt-1"
            name="device_name"
            type="text"
            required
          />

          <form-error
            v-if="formDevice.errors"
            :message="
              formDevice.errors.validations.pd_serial_number
                ? formDevice.errors.validations.pd_serial_number[0]
                : ''
            "
          />
        </div>
        <div>
          <form-label for="key-1">
            {{ t("Device Key 1") }}
          </form-label>
          <form-input
            id="pd_key1"
            v-model="formDevice.pd_key1"
            class="mt-1"
            name="pd_key1"
            type="text"
            required
          />
          <form-error
            v-if="false"
          />
        </div>

        <div>
          <form-label for="key-2">
            {{ t("Device Key 2") }}
          </form-label>
          <form-input
            id="pd_key2"
            v-model="formDevice.pd_key2"
            class="mt-1"
            name="pd_key2"
            type="text"
            required
          />
          <form-error
            v-if="false"
          />
        </div>

        <div>
          <form-label for="key-3">
            {{ t("Device Key 3") }}
          </form-label>
          <form-input
            id="pd_key3"
            v-model="formDevice.pd_key3"
            class="mt-1"
            name="pd_key3"
            type="text"
            required
          />
          <form-error
            v-if="false"
          />
        </div>

        <div>
          <form-label for="key-4">
            {{ t("Device Key 4") }}
          </form-label>
          <form-input
            id="pd_key4"
            v-model="formDevice.pd_key4"
            class="mt-1"
            name="pd_key4"
            type="text"
            required
          />
          <form-error
            v-if="false"
          />
        </div>

        <!-- Start Device Key 5-->
        <div>
          <form-label for="key-5">
            {{ t("Device Key 5") }}
          </form-label>
          <form-input
            id="pd_key5"
            v-model="formDevice.pd_key5"
            class="mt-1"
            name="pd_key5"
            type="text"
            required
          />
          <form-error
            v-if="false"
          />
        </div>
        <!-- End Device Key 5-->

        <div>
          <form-label for="device-status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="device-status"
            v-model="formDevice.status"
            class="mt-1"
            name="device_status"
            :items="statusOptions"
            required
          />
        </div>

        <div>
          <form-label for="device-location">
            {{ t("Location") }}
          </form-label>
          <form-textarea
            id="device-location"
            v-model="formDevice.location"
            class="mt-1"
            name="device_location"
          />
          <form-error
            v-if="formDevice.errors"
            :message="
              formDevice.errors.validations.location
                ? formDevice.errors.validations.location[0]
                : ''
            "
          />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        v-if="slideOver.editMode == false"
        class="btn-wide"
        type="button"
        @click="applyMerchantAccountGatewayPaymentDeviceDetails()"
      >
        {{ t("Apply") }}
      </Button>
    </template>
  </slide-over>

  <div class="mt-4 flex justify-end space-x-2">
    <Button
      class="btn-wide"
      type="button"
      :disabled="warningValue == true"
      @click="saveMerchantAccountGatewayDetails"
    >
      {{ t("Save") }}
    </Button>
  </div>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    @closeModal="closeAlert"
  />

  <modal
    :type="modalWarning.type"
    :show="modalWarning.show"
    :title="modalWarning.title"
    :message="modalWarning.message"
    :close-button="modalWarning.close_button"
    :confirm-button="modalWarning.confirm_button"
    :button-text="modalWarning.button_text"
    @closeModal="closeWarning"
  />

  <modal
    :type="modalDevice.type"
    :show="modalDevice.show"
    :title="modalDevice.title"
    :message="modalDevice.message"
    :close-button="modalDevice.close_button"
    :confirm-button="modalDevice.confirm_button"
    :button-text="modalDevice.button_text"
    @closeModal="closeAlertDevice"
    @confirmModal="confirmAlert"
  />

  <slide-over
    :show="AddGwsSlideOver.show"
    :slideover-size="AddGwsSlideOver.size"
    :slideover-title="AddGwsSlideOver.title"
    :close-button="AddGwsSlideOver.close_button"
    @closeSlideOver="AddGwsSlideOver.show = false, closeGatewayDetailsSlideOver()"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
          <div>
            <form-label
              for="merchant-id"
            >
              {{ t("Merchant ID") }}
              <required v-if="requiredApiKeysValue == false" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_mch_id == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_mch_id, 'gw_mch_id')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="merchant-id"
                v-model="form.gw_mch_id"
                class="mt-1"
                name="merchant_id"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="merchant-id1"
                v-model="form.encrypt_gw_mch_id"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="merchant_id"
                required
                type="text"
              />
              <form-error
                v-if="errorMchId"
                :message="errorMchId"
              />
            </div>
          </div>

          <div>
            <form-label
              for="api-private-key"
            >
              {{ t("API Key1") }}
              <required v-if="requiredApiKeysValue == false" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key1 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key1, 'gw_key1')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="api-private-key"
                v-model="form.gw_key1"
                class="mt-1"
                name="api_private_key"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="api-private-key1"
                v-model="form.encrypt_gw_key1"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="api_private_key"
                required
                type="text"
              />
              <form-error
                v-if="errorPrivateKey"
                :message="errorPrivateKey"
              />
            </div>
          </div>

          <div>
            <form-label
              for="api-public-key"
            >
              {{ t("API Key2") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key2 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key2, 'gw_key2')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="api-public-key"
                v-model="form.gw_key2"
                class="mt-1"
                name="api_public_key"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="api-public-key1"
                v-model="form.encrypt_gw_key2"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="api_public_key"
                required
                type="text"
              />
              <form-error
                v-if="errorPublicKey"
                :message="errorPublicKey"
              />
            </div>
          </div>

          <div>
            <form-label for="key-1">
              {{ t("API Key 3") }}
              <required v-if="form.is_js_support" />
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key3 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key3, 'gw_key3')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="gw_key3"
                v-model="form.gw_key3"
                class="mt-1"
                name="gw_key3"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="gw_key3_1"
                v-model="form.encrypt_gw_key3"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="gw_key3"
                required
                type="text"
              />
              <form-error
                v-if="errorApiKey1"
                :message="errorApiKey1"
              />
            </div>
          </div>
        </div>

        <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
          <div>
            <form-label for="key-2">
              {{ t("API Key 4") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key4 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key4, 'gw_key4')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="gw_key4"
                v-model="form.gw_key4"
                class="mt-1"
                name="gw_key4"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="gw_key4_1"
                v-model="form.encrypt_gw_key4"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="gw_key4"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>

          <div>
            <form-label for="key-3">
              {{ t("API Key 5") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key5 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key5, 'gw_key5')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="gw_key5"
                v-model="form.gw_key5"
                class="mt-1"
                name="gw_key5"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="gw_key5_1"
                v-model="form.encrypt_gw_key5"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="gw_key5"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>

          <div>
            <form-label for="key-4">
              {{ t("API Key 6") }}
            </form-label>
            <div class="relative">
              <button
                v-if="form.is_parent_cred_used == 1 && form.encrypt_gw_key6 == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_key6, 'gw_key6')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              <form-input
                v-if="fieldsDisableValue == false"
                id="gw_key6"
                v-model="form.gw_key6"
                class="mt-1"
                name="gw_key6"
                required
                type="text"
                :disabled="isView == true"
              />
              <form-input
                v-if="fieldsDisableValue == true"
                id="gw_key6_1"
                v-model="form.encrypt_gw_key6"
                :disabled="fieldsDisableValue == true"
                class="mt-1"
                name="gw_key6"
                required
                type="text"
              />
              <form-error
                v-if="false"
              />
            </div>
          </div>
        </div>

        <div class="tab-group">
          <div class="tabs-wrapper">
            <nav
              class="tabs"
              aria-label="Tabs"
            >
              <a
                v-for="(tabItem, index) in gatewayDetailsTabItems"
                :key="index"
                href="#"
                class="tab-item"
                :class="{ active: activeGatewayDetailsTabId == tabItem.id }"
                @click="showTabContent('activeGatewayDetailsTabId', tabItem.id)"
              >{{ tabItem.name }}</a>
            </nav>
          </div>

          <div class="tab-container">
            <div v-if="activeGatewayDetailsTabId == 'tab1'">
              <div class="mb-4 grid grid-cols-2 gap-y-3" />

              <TableDefault>
                <template #table>
                  <table
                    id="accounts_payment_methods_tbl"
                    class="table"
                    aria-describedby=""
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Payment Methods") }} <required /></span>
                            <button
                              class="btn_th-sort"
                              type="button"
                              @click="sort('paymentMethod')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'paymentMethod'
                                "
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                            </button>
                          </div>
                        </th>
                        <th class="text-center">
                          <div class="flex items-center justify-center">
                            <form-checkbox
                              v-model="form.isSelectAllGatewayPaymentMethod"
                              :check-value="true"
                              :disabled="isView == true"
                              :model-value="
                                form.isSelectAllGatewayPaymentMethod == 1 ? 0 : 1
                              "
                              :checked="
                                form.isSelectAllGatewayPaymentMethod == 1
                                  ? true
                                  : false
                              "
                              name="all-payment_method"
                              @click="selectAllPaymentMethod()"
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(
                          payment_method_data, index
                        ) in merchantAccountGatewayPaymentMethods"
                        :id="'accounts_payment_methods_' + index"
                        :key="index"
                      >
                        <td
                          v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                        >
                          {{ payment_method_data.description }}
                        </td>
                        <td v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'">
                          <div class="flex items-center justify-center space-x-5">
                            <form-checkbox
                              v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                              :id="index"
                              :disabled="isView == true"
                              name="payment_method"
                              :model-value="payment_method_data.tnt_pm_id ? 1 : 0"
                              :checked="
                                setPaymentMethodCheck(payment_method_data.tnt_pm_id)
                              "
                              @click="
                                setPaymentMethods(payment_method_data.tnt_pm_id)
                              "
                            />
                          </div>
                        </td>
                      </tr>

                      <no-table-data
                        v-if="!merchantAccountGatewayPaymentMethods.length"
                      />
                    </tbody>
                  </table>
                </template>
              </TableDefault>

              <div>
                <form-error
                  v-if="errorPaymentMethod"
                  :message="errorPaymentMethod"
                />
              </div>
            </div>

            <div v-if="activeGatewayDetailsTabId == 'tab2'">
              <div class="mb-4 grid grid-cols-2 gap-y-3">
                <div class="flex items-center">
                  <span class="text-sm">{{ t("Show") }}</span>
                  <div class="mx-2">
                    <form-select
                      id="results_per_page1"
                      v-model="perPage"
                      :items="[10, 25, 50, 100]"
                      class="py-2"
                      name="results_per_page"
                      @onChangeFunction="getGatewayPaymentDevices()"
                    />
                  </div>
                  <span class="text-sm">{{ t("entries") }}</span>
                </div>

                <div class="w-full flex items-center justify-end space-x-2">
                  <Button
                    v-if="isView == false"
                    type="button"
                    @click="(addDeviceOpen()), (AddGwsSlideOver.show = false)"
                  >
                    {{ t("Add Device") }}
                  </Button>
                </div>
              </div>

              <TableDefault>
                <template #table>
                  <table
                    id="accounts_payment_devices_tbl"
                    class="table"
                    aria-describedby=""
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Name") }}</span>
                            <button
                              class="btn_th-sort"
                              type="button"
                              @click="sortPaymentDevice('pd_name')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'pd_name'
                                "
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Location") }}</span>
                            <button
                              class="btn_th-sort"
                              type="button"
                              @click="sortPaymentDevice('location')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'location'
                                "
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col">
                          <div class="flex items-center justify-center">
                            <span>{{ t("Status") }}</span>
                            <button
                              class="btn_th-sort"
                              type="button"
                              @click="sortPaymentDevice('status')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'status'
                                "
                                class="icon_th-sort"
                                icon="th-caret-down"
                              />
                              <svg-icon
                                v-else
                                class="icon_th-sort"
                                icon="th-caret-up"
                              />
                            </button>
                          </div>
                        </th>
                        <th
                          scope="col"
                          class="th-action"
                        >
                          {{ t("Actions") }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(
                          payment_device_data, index
                        ) in merchantAccountGatewayPaymentDevices"
                        :id="'accounts_payment_devices_' + index"
                        :key="index"
                      >
                        <td>
                          <router-link
                            class="text-primary underline"
                            :to="{ path: '/accounts/add' }"
                            @click="
                              getGatewayPaymentDeviceByMchAccPaymentDeviceId(
                                payment_device_data, index
                              ),
                              (slideOver.show = true),(AddGwsSlideOver.show = false)
                            "
                          >
                            {{ payment_device_data.pd_name }}
                          </router-link>
                        </td>
                        <td>{{ payment_device_data.location }}</td>
                        <td class="text-center">
                          <badge
                            v-if="payment_device_data.status == 'ACT'"
                            type="success"
                            text="Active"
                          />
                          <badge
                            v-if="payment_device_data.status == 'INA'"
                            type="danger"
                            text="Inactive"
                          />
                        </td>
                        <td>
                          <div class="flex items-center justify-center space-x-5">
                            <button-circle
                              type="danger"
                              icon="trash"
                              @click="
                                deletePaymentDeviceType(
                                  payment_device_data.mch_acc_pd_id
                                )
                              "
                            />
                          </div>
                        </td>
                      </tr>

                      <no-table-data v-if="!merchantAccountGatewayPaymentDevices" />
                    </tbody>
                  </table>
                </template>
              </TableDefault>
              <vue-pagination
                :page-size="perPage"
                :total-records="totatlRecord"
                :sort-column="sortColumn"
                :sort-direction="sortColumnDirection"
                :last-page="lastPage"
                @page-changed="getGatewayPaymentDevices($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <Button
        v-if="isView == false"
        class="btn-wide"
        type="button"
        @click="applyGatewayPaymentChanges()"
      >
        {{ t("Apply") }}
      </Button>
    </template>
  </slide-over>
</template>

<script>
import { ref, reactive, watch, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import Button from '@/components/Button.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormRadio from '@/components/FormRadio.vue'
import FormTextarea from '@/components/FormTextarea.vue'
import axios from 'axios'
import FormCheckbox from '@/components/FormCheckbox.vue'
import Modal from '@/components/Modal.vue'
import { useRouter } from 'vue-router'
import Badge from '@/components/Badge.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import FormSelect from '@/components/FormSelect.vue'
import NoTableData from '@/components/NoTableData.vue'
import TableDefault from '@/components/TableDefault.vue'
import ButtonCircle from '@/components/ButtonCircle.vue'
import Loading from 'vue3-loading-overlay'
import VuePagination from '@/components/VuePagination'
import { Tippy } from 'vue-tippy'
import constant from "@/constant";

export default {
  name: 'FormGatewayDetails',

  components: {
    Modal,
    Button,
    Required,
    SlideOver,
    FormError,
    FormLabel,
    FormInput,
    FormRadio,
    FormSelect,
    FormTextarea,
    FormCheckbox,
    Badge,
    SvgIcon,
    NoTableData,
    TableDefault,
    ButtonCircle,
    Loading,
    VuePagination,
    Tippy,
  },

  props: {
    mch: {
      type: Number,
      default: 0,
    },
    countryId: {
      type: String,
      default: '',
    },
    tab: {
      type: String,
      default: '',
    },
    stateReact: {
      type: Number,
      default: 0,
    }
  },

  setup(props) {
    const { t } = useI18n()
    const sortColumnDirection = ref('desc')
    const responseData = ref(false)
    // const checkboxDisableValue = ref(false)
    const fieldsDisableValue = ref(false)
    const mchAccountId = ref(0)
    const totatlRecord = ref(0)
    const currentPage = ref(0)
    const lastPage = ref(0)
    const merchantAccountGatewayPaymentMethods = ref(0)
    const merchantAccountGatewayPaymentDevices = ref(0)
    const gatewayPaymentDevices = ref([])
    const paymentDevicesByPaymentDeviceId = ref(0)
    const parentMchId = ref(0)
    const merchantAccountDetails = ref(0)
    const merchantAccountPaymentGatewayCredentialsDetails = ref(0)
    const parentGatewaysWithAcceptedCountry = ref(0)
    const paymentMethodsForAccount = ref(0)
    const PaymentDeviceTypeIdToDelete = ref(false)
    const requiredApiKeysValue = ref(false)
    const isView = ref(false)
    const isDisabled = ref(true)
    const notComplete = ref(false)
    const changeVal = ref(false)


    const errorMchId = ref('');
    const errorPrivateKey = ref('');
    const errorApiKey1 = ref('');
    const errorPublicKey = ref('');
    const errorPaymentMethod = ref('');

    const gatewayOptions = ref([])

    const paymentDeviceOptions = ref([
      { value: '', text: 'Please select a device type' },
    ])

    const statusOptions = ref([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])

    const stateOptions = ref([])

    let onchange = ref(true)

    const gatewayDetailsTabItems = reactive([
      { id: 'tab1', name: 'Payment Methods' },
      { id: 'tab2', name: 'Devices' },
    ])

    const activeGatewayDetailsTabId = ref(gatewayDetailsTabItems[0].id)

    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Add Device',
    })
   const AddGwsSlideOver = reactive({
      show: false,
      size: 'max-w-5xl',
      close_button: false,
      title: 'Payment Gateways',
    })

    const modalWarning = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const router = useRouter()

    const form = reactive({
      mch_acc_id: '',
      gw_mch_id: '',
      gw_key2: '',
      gw_key1: '',
      gw_id: '',
      tmp_gw_id: '',
      tnt_pm_id: '',
      state: '',
      states: [],
      isSelectAllGatewayPaymentMethod: 0,
      is_parent_cred_used: 1,
      errors: '',
      gw_key3: '',
      gw_key4: '',
      gw_key5: '',
      gw_key6: '',
      payment_devices:[],
      paymentGatewayDetails:[],
      checked_gw: 0,
      gateways: [],
      encrypt_gw_mch_id: constant.encryptionKey,
      encrypt_gw_key2: constant.encryptionKey,
      encrypt_gw_key1: constant.encryptionKey,
      encrypt_gw_key3: constant.encryptionKey,
      encrypt_gw_key4: constant.encryptionKey,
      encrypt_gw_key5: constant.encryptionKey,
      encrypt_gw_key6: constant.encryptionKey,
      act_as_agent: 0,
      parentGateways: [],
      parentGatewaysWithAcceptedCountry: [],
      is_js_support : false,
      country_code: null
    })

    const decryptDataForm = reactive({
      data: '',
    })

    const formDevice = reactive({
      mch_acc_pd_id: '',
      mch_acc_id: '',
      gw_id: '',
      gw_pd_id: '',
      pd_name: '',
      pd_serial_number: '',
      location: '',
      status: '',
      pd_key1: '',
      pd_key2: '',
      pd_key3: '',
      pd_key4: '',
      pd_key5: '',
      errors: '',
    })

    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const modalDevice = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })

    const sortColumn = ref('')
    const searchText = ref('')
    const errorForParentAccountLink = ref('')
    const url = ref('')
    const msg = ref('')
    const perPage = ref(10)
    const fullPage = ref(true)
    const isLoading = ref(false)
    const disableDeviceValue = ref(false)
    const paymentMethodList = ref([])
    const PaymentDeviceIndexToDelete = ref(false)

    const gatewayDetails = ref(0)
    const paymentGatewayDetails = ref([])
    const gatewayIdInEditMode = ref(0)
    const gatewayNameInEditMode = ref('')
    const errorMsgForGateway = ref('')
    const constantValue = constant.encryptionKey
    const warningValue = ref(false)
    const sorting = ref(false)
    const count = ref(0)

    watch(
      () => [props.mch, props.tab],
      async () => {
        form.mch_acc_id = props.mch;
        await getMerchantAccountDetailsById(form.mch_acc_id);
        slideOver.editMode = false;
        if (props.tab === 'tab2') {
          if (form.is_parent_cred_used === 1) {
            await setToParentGatewayAccount();
          }
        }
      },
      { immediate: true } 
    );

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    async function setBlueBtnOrRedBtn(gatewayResponse){
      let object = [];
      for (const value of gatewayResponse) {
        let tempObj = {
          gw_id: null,
          name: null,
          blueBtn: false,
          redBtn: false,
          checked: false,
          is_js_support: false
        };
        tempObj.gw_id = value.gw_id;
        tempObj.name = value.name;
        tempObj.is_js_support = value.is_js_support
        if(form.gateways.includes(parseInt(value.gw_id))){
          let bool = await checkPaymentMethodsEmptyOrNot(value.gw_id)
          tempObj.redBtn = !bool
          tempObj.blueBtn = bool
          tempObj.checked = true
        }else{
          tempObj.redBtn = false
          tempObj.blueBtn = true
          tempObj.checked = false
        }
        object.push(tempObj);
      }
      return object;
    }

    async function getGateways(column = sortColumn.value) {
      try {
        if(props.countryId){
          gatewayDetails.value = []
          let response = await axios.get('v1/gateway/accepted-countries/get-all-gateways-per-country/' + props.countryId, {
                params: {
                  sortColumn: column,
                  sortDirection: sortColumnDirection.value,
                },
              })
          if (response.data.data != null) {
            let gatewayResponse = response.data.data;
            gatewayDetails.value = await setBlueBtnOrRedBtn(gatewayResponse)
          }

        }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    async function getAllGatewaysWithoutCountrySelection(column = sortColumn.value) {
      // await axios
      //     .get('/v1/gateway/list/', {
      //       params: {
      //         sortColumn: column,
      //         sortDirection: sortColumnDirection.value,
      //         search: searchText.value,
      //         paginate: false,
      //       },
      //     })
      //     .then((response) => {
      //       if (response.data.data != null) {
      //         gatewayDetails.value = response.data.data
      //       }
      //     })

    }

    async function sortGateway(column) {
      sortColumn.value = column
      sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

     await getGateways(sortColumn.value)
    }

    async function saveMerchantAccountGatewayDetails() {
      isLoading.value = true
      form.payment_devices = merchantAccountGatewayPaymentDevices.value
      form.paymentGatewayDetails = paymentGatewayDetails.value

      if(form.gateways.length == 0){
        errorMsgForGateway.value = 'Please select at least one gateway.'
        isLoading.value = false
      }else{
        let isCheckPaymentMethod = true;
        for (const gwId of form.gateways) {
          isCheckPaymentMethod = await checkPaymentMethodsEmptyOrNot(gwId)
          if(!isCheckPaymentMethod){
            isCheckPaymentMethod = false;
            break;
          }
        }
        if(!isCheckPaymentMethod)
        {
          modalWarning.type = 'warning'
          modalWarning.title = 'Warning'
          modalWarning.message =
            'Please complete configuration of selected gateways.'
          modalWarning.show = true
          isLoading.value = false
        }else{
          axios
            .post(
              '/v1/new-merchant-account-gateway-details/' + form.mch_acc_id,
              form
            )
            .then((response) => {
              isLoading.value = false
              if (response.data != null && !response.data.data.errors) {
                modal.type = 'success'
                modal.title = 'Success'
                modal.message = 'Account created successfully.'
                modal.show = true
              } else {
                form.errors = response.data.data.errors
              }
            })
        }
      }
    }

    function sort(column) {
      sorting.value = false
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      sorting.value = true

      getGatewayPaymentMethods(1, sortColumn.value, searchText.value)
    }

    function setPaymentMethods(stateId) {
      if (form.states.includes(parseInt(stateId))) {
        form.states.splice(form.states.indexOf(parseInt(stateId)), 1)
      } else {
        form.states.push(stateId)
      }
      checkAllPaymentMethodSelect()
    }

    function checkAllPaymentMethodSelect() {
      let _arr1 = form.states.sort()
      let _arr2 = paymentMethodList.value.sort()

      if(form.is_parent_cred_used == 0){
        if (
            Array.isArray(_arr1) &&
            Array.isArray(_arr2) &&
            _arr1.length === _arr2.length
        ) {
          if (paymentMethodList.value.length != 0) {
            form.isSelectAllGatewayPaymentMethod = 1
          } else {
            form.isSelectAllGatewayPaymentMethod = 0
          }
        }

        if (
            !Array.isArray(_arr1) ||
            !Array.isArray(_arr2) ||
            _arr1.length !== _arr2.length
        ) {
          form.isSelectAllGatewayPaymentMethod = 0
        }
      }else{
        paymentMethodCheckboxFunctionality()
      }
    }

    function paymentMethodCheckboxFunctionality(){
      let _arr1 = form.states.sort()
      let _arr2 = paymentMethodList.value.sort()
      let _arr1Length = ''
      _arr1Length = _arr1.length

      if (
          Array.isArray(_arr1) &&
          Array.isArray(_arr2) &&
          _arr1Length === _arr2.length
      ) {
        form.isSelectAllGatewayPaymentMethod = 1
      }

      if (
          !Array.isArray(_arr1) ||
          !Array.isArray(_arr2) ||
          _arr1Length !== _arr2.length
      ) {
        form.isSelectAllGatewayPaymentMethod = 0
      }
    }

    function setPaymentMethodCheck(paymentMethodId) {
      if (form.states.includes(parseInt(paymentMethodId))) {
        return true
      }
    }

    function selectAllPaymentMethod() {
      if (form.isSelectAllGatewayPaymentMethod == 0) {
        form.states = []
        paymentMethodList.value.forEach((value) => {
          form.states.push(value)
        })
      } else if (form.isSelectAllGatewayPaymentMethod == 1) {
        isLoading.value = true
        form.states = []
        isLoading.value = false
      }
    }

    function saveMerchantAccountGatewayPaymentMethodDetails() {
      isLoading.value = true
      axios
        .post(
          '/v1/new-merchant-account-payment-method-details/' + form.mch_acc_id,
          form
        )
        .then((response) => {
          isLoading.value = false
          if (response.data != null && !response.data.data.errors) {
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'Account created successfully.'
            modal.show = true
          } else {
            form.errors = response.data.data.errors
          }
        })
    }

    async function getAllPaymentDevicePerGateway(gatewayIdInEditMode) {
      isLoading.value = true
      paymentDeviceOptions.value = ([
        { value: '', text: 'Please select a device type' },
      ])
      await axios
        .get('v1/gateway/payment-device/get-active-devices/' + gatewayIdInEditMode)
        .then((response) => {
          isLoading.value = false
          if (response.data.data != null) {
            response.data.data.find((paymentDevice) => {
              let obj = {}
              obj.text = paymentDevice.gw_pd_type
              obj.value = paymentDevice.gw_pd_id
              paymentDeviceOptions.value.push(obj)
            })
          }
        })
    }

    async function getGatewayPaymentDevices(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      isLoading.value = true
      if (search != null) {
        searchText.value = search
      }

      await axios
        .get(
          '/v1/get-merchant-account-payment-device-details/' + form.mch_acc_id,
          {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          }
        )
        .then((response) => {
          isLoading.value = false
          if (response.data.data != null) {
            merchantAccountGatewayPaymentDevices.value = response.data.data.data
            totatlRecord.value = response.data.data.total
            currentPage.value = response.data.data.current_page
            lastPage.value = response.data.data.last_page
          }
        })
    }

    function sortPaymentDevice(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      getGatewayPaymentDevices(1, sortColumn.value, searchText.value)
    }

    async function getGatewayPaymentDeviceByMchAccPaymentDeviceId(
      data, index
    ) {
      formDevice.errors = ''
      disableDeviceValue.value = true
      slideOver.title = 'Edit Device'

      formDevice.arrayIndex = index
      formDevice.mch_acc_pd_id = data.mch_acc_pd_id
      formDevice.gw_pd_id = data.gw_pd_id != undefined ? data.gw_pd_id : data.gw_pd_id
      formDevice.pd_name = data.pd_name
      formDevice.pd_serial_number = data.pd_serial_number
      formDevice.location = data.location
      formDevice.status = data.status
      formDevice.pd_key1 = data.pd_key1
      formDevice.pd_key2 = data.pd_key2
      formDevice.pd_key3 = data.pd_key3
      formDevice.pd_key4 = data.pd_key4
      formDevice.pd_key5 = data.pd_key5
    }

    function editMerchantAccountGatewayPaymentDeviceDetails() {
      formDevice.gw_id = form.gw_id
      formDevice.mch_acc_id = form.mch_acc_id
      isLoading.value = true
      axios
        .post(
          '/v1/edit-merchant-account-payment-device-details/' +
            formDevice.mch_acc_pd_id,
          formDevice
        )
        .then((response) => {
          isLoading.value = false
          if (response.data != null && !response.data.data.errors) {
            modalDevice.type = 'success'
            modalDevice.title = 'Success'
            modalDevice.message = 'Payment device details updated successfully.'
            modalDevice.show = true
            formDevice.gw_id = ''
            formDevice.gw_pd_id = ''
            formDevice.pd_name = ''
            formDevice.pd_serial_number = ''
            formDevice.location = ''
            formDevice.status = 'ACT'
          } else {
            formDevice.errors = response.data.data.errors
          }
        })
    }

    async function getParentAccountDetails() {
      try {
        const response = await axios.get('v1/get-merchant-accounts-by-merchant-id-is-parent');
        return response;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    async function setToParentGatewayAccount() {
      try {
        isDisabled.value = true
        if (sorting.value == false) {
          resetSlideOverDetails()
        }
        //await getParentGatewaysWithAcceptedCountry()
        await getPaymentMethodsToAccount()
        form.is_parent_cred_used = 1
        fieldsDisableValue.value = true
        errorMsgForGateway.value = ''
        paymentGatewayDetails.value = []
        isLoading.value = true
        let response = await getParentAccountDetails();
        isLoading.value = false
        if (response.data.data != null) {
          parentMchId.value = response.data.data.mch_acc_id
          await getMerchantAccountPaymentGaetwayCredentialsDetailsByMchAccId(parentMchId.value)
          await getGateways()
          form.errors = ''
          removePaymentDevices()
        }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    async function getMerchantAccountPaymentGaetwayCredentialsDetailsByMchAccId(parentMchId) {
      try {
        isLoading.value = true
        form.gateways = []
        merchantAccountPaymentGatewayCredentialsDetails.value = []
        form.parentGateways = []
        warningValue.value = false
        let response = await axios.get('/v1/get-merchant-account-payment-gateway-credentials/' + parentMchId)
          isLoading.value = false
          if (response.data != null) {
            merchantAccountPaymentGatewayCredentialsDetails.value = response.data.data


            merchantAccountPaymentGatewayCredentialsDetails.value.forEach((gatewayCredentials) => {

              let parentGatewayCountryCodes = gatewayCredentials.accepted_countries;
              parentGatewayCountryCodes.forEach((GatewayCountryCode) => {
                if(GatewayCountryCode.country_code === form.country_code) {
                  form.gateways.push(gatewayCredentials.gw_id)
                }
              })
            })

            merchantAccountPaymentGatewayCredentialsDetails.value.forEach((gatewayCredentials) => {
                let obj = {}
                obj.gw_id = gatewayCredentials.credential_gateway.gw_id
                obj.name = gatewayCredentials.credential_gateway.name
                form.parentGateways.push(obj)
            })
            if(gatewayDetails.value.length == 0){
              modalWarning.type = 'warning'
              modalWarning.title = 'Warning'
              modalWarning.message =
                  'opuspay does not have a gateway setup for your country. Please contact opuspay team.'
              modalWarning.show = true
              warningValue.value = true
            }else{
              let value = false
              gatewayDetails.value.forEach((gatewayDetails) => {
                if (form.gateways.includes(parseInt(gatewayDetails.gw_id))) {
                  value = true
                }
              })
              if(value == false && props.tab == 'tab2'){
                modalWarning.type = 'warning'
                modalWarning.title = 'Warning'
                modalWarning.message =
                    'Your parent account does not have a gateway setup for your country. Please contact opuspay team.'
                modalWarning.show = true
                warningValue.value = true
              }
            }
            await getParentGatewaysWithAcceptedCountry()
          } else {
            console.error('Error!')
          }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
    async function getParentGatewaysWithAcceptedCountry(){
      gatewayDetails.value = []
      form.parentGatewaysWithAcceptedCountry = []
      await axios
          .get('/v1/get-merchant-account-payment-gateway-credentials/' + form.mch_acc_id)
          .then((response) => {
            isLoading.value = false
            if (response.data != null) {
              parentGatewaysWithAcceptedCountry.value = response.data.data
              parentGatewaysWithAcceptedCountry.value.forEach((gateways) => {
                let obj = {}
                obj.gw_id = gateways.credential_gateway.gw_id
                obj.name = gateways.credential_gateway.name
                form.parentGatewaysWithAcceptedCountry.push(obj)
              })
              gatewayDetails.value = form.parentGatewaysWithAcceptedCountry
            } else {
              console.error('Error!')
            }
      })
    }

    async function setToExistingGatewayAccount() {
      isDisabled.value = false
      // checkboxDisableValue.value = false
      fieldsDisableValue.value = false
      form.is_parent_cred_used = 0
      paymentGatewayDetails.value = []
      form.gateways = []
      warningValue.value = false
      errorMsgForGateway.value = ''
      if(gatewayDetails.value.length == 0){
        modalWarning.type = 'warning'
        modalWarning.title = 'Warning'
        modalWarning.message =
            'opuspay does not have a gateway setup for your country. Please contact opuspay team.'
        modalWarning.show = true
        warningValue.value = true
      }
      gatewayDetails.value = await setBlueBtnOrRedBtn(gatewayDetails.value)
    }

    function closeAlert() {
      modal.show = false
      router.push('/accounts/')
    }

    function closeAlertDevice() {
      modalDevice.show = false
      slideOver.show = false
    }

    function openSlideOver() {
      formDevice.gw_pd_id = ''
      formDevice.pd_name = ''
      formDevice.pd_serial_number = ''
      formDevice.status = 'ACT'
      formDevice.location = ''
      formDevice.pd_key1 = ''
      formDevice.pd_key2 = ''
      formDevice.pd_key3 = ''
      formDevice.pd_key4 = ''
      formDevice.pd_key5 = ''
      slideOver.show = true
    }

    function addDeviceOpen() {
      slideOver.title = 'Add Device'
      disableDeviceValue.value = false
      formDevice.gw_pd_id = ''
      formDevice.pd_name = ''
      formDevice.pd_serial_number = ''
      formDevice.status = 'ACT'
      formDevice.location = ''
      formDevice.pd_key1 = ''
      formDevice.pd_key2 = ''
      formDevice.pd_key3 = ''
      formDevice.pd_key4 = ''
      formDevice.pd_key5 = ''
      formDevice.errors = ''
      openSlideOver()
    }

    function deletePaymentDeviceType(id, index) {
      PaymentDeviceTypeIdToDelete.value = id
      PaymentDeviceIndexToDelete.value = index;
      modalDevice.type = 'warning'
      modalDevice.title = 'Warning'
      modalDevice.confirm_button = true
      modalDevice.button_text = 'No'
      modalDevice.message =
        'You are about to delete a record. This cannot be undone. Are you sure you want to continue?'
      modalDevice.show = true
    }

    function confirmAlert() {
      deletePaymentDeviceTypeOption()
      modalDevice.show = false
      return true
    }

    async function deletePaymentDeviceTypeOption() {
      if (PaymentDeviceTypeIdToDelete.value !== null && PaymentDeviceTypeIdToDelete.value !== ""){
        merchantAccountGatewayPaymentDevices.value.forEach((paymentDevice, index) => {
          if(paymentDevice.mch_acc_pd_id === PaymentDeviceTypeIdToDelete.value){
            let obj = {}
            obj.mch_acc_pd_id = paymentDevice.mch_acc_pd_id;
            obj.row_version = paymentDevice.row_version;
            form.deleted_mch_acc_gw_devices.push(obj)
            merchantAccountGatewayPaymentDevices.value.splice(index, 1)
          }
        })
      }else if (PaymentDeviceIndexToDelete.value !== null && PaymentDeviceIndexToDelete.value !== ""){
        merchantAccountGatewayPaymentDevices.value.splice(PaymentDeviceIndexToDelete.value, 1)
      }
    }

    async function removePaymentDevices(){
      try {
        const response = await axios.post(
            '/v1/delete-all-payment-devices-per-mch-account/' +
            form.mch_acc_id
          );
        if (response.data != null) {
          await getGatewayPaymentDevices()
        }
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    function removeDetails(){
      form.gw_mch_id = ''
      form.gw_key1 = ''
      form.gw_key2 = ''
      form.gw_key3 = ''
      form.gw_key4 = ''
      form.gw_key5 = ''
      form.gw_key6 = ''
    }

    function applyMerchantAccountGatewayPaymentDeviceDetails(){
      isLoading.value = true;
      formDevice.gw_id = form.gw_id;
      formDevice.mch_acc_id = form.mch_acc_id;
      formDevice.errors = '';

      let obj = {}
      obj.mch_acc_pd_id = formDevice.mch_acc_pd_id
      obj.mch_acc_id = formDevice.mch_acc_id;
      obj.gw_id = formDevice.gw_id;
      obj.gw_pd_id = formDevice.gw_pd_id
      obj.pd_name = formDevice.pd_name
      obj.pd_serial_number = formDevice.pd_serial_number
      obj.status = formDevice.status
      obj.location = formDevice.location
      obj.pd_key1 = formDevice.pd_key1
      obj.pd_key2 = formDevice.pd_key2
      obj.pd_key3 = formDevice.pd_key3
      obj.pd_key4 = formDevice.pd_key4
      obj.pd_key5 = formDevice.pd_key5

      let isValidate = false
      formDevice.errors = null
      if (merchantAccountGatewayPaymentDevices.value.length > 0) {
        merchantAccountGatewayPaymentDevices.value.forEach((paymentDevice, index) => {
          if (index != formDevice.arrayIndex) {
            if ((paymentDevice.pd_name == obj.pd_name) && (paymentDevice.pd_serial_number == obj.pd_serial_number)) {
              formDevice.errors = {
                validations: {
                  pd_name: [
                    'Device name already exists for this account. Please enter a unique device name.',
                  ],
                  pd_serial_number: [
                    'Device id already exists for this account. Please enter a unique device id.',
                  ],
                },
              }
              isValidate = false
            } else if ((paymentDevice.pd_name == obj.pd_name) && (paymentDevice.pd_serial_number != obj.pd_serial_number)) {
              formDevice.errors = {
                validations: {
                  pd_name: [
                    'Device name already exists for this account. Please enter a unique device name.',
                  ],
                },
              }
              isValidate = false
            } else if ((paymentDevice.pd_name != obj.pd_name) && (paymentDevice.pd_serial_number == obj.pd_serial_number)) {
              formDevice.errors = {
                validations: {
                  pd_serial_number: [
                    'Device id already exists for this account. Please enter a unique device id.',
                  ],
                },
              }
              isValidate = false
            } else {
              isValidate = true
            }
          } else {
            isValidate = true
          }
        })
      } else {
        isValidate = true
      }
      if (isValidate) {
        axios.post('/v1/validate-new-merchant-account-payment-device-details/' + form.mch_acc_id, formDevice).then((response) => {
          if (response.data != null && !response.data.data.errors) {
            if (obj.mch_acc_pd_id == '') {
              if (formDevice.arrayIndex != null) {
                let prevObj = merchantAccountGatewayPaymentDevices.value[formDevice.arrayIndex]
                prevObj.mch_acc_pd_id = formDevice.mch_acc_pd_id
                prevObj.mch_acc_id = formDevice.mch_acc_id;
                prevObj.gw_id = formDevice.gw_id;
                prevObj.gw_pd_id = formDevice.gw_pd_id
                prevObj.pd_name = formDevice.pd_name
                prevObj.pd_serial_number = formDevice.pd_serial_number
                prevObj.status = formDevice.status
                prevObj.location = formDevice.location
                prevObj.pd_key1 = formDevice.pd_key1
                prevObj.pd_key2 = formDevice.pd_key2
                prevObj.pd_key3 = formDevice.pd_key3
                prevObj.pd_key4 = formDevice.pd_key4
                prevObj.pd_key5 = formDevice.pd_key5
              } else {
                merchantAccountGatewayPaymentDevices.value.push(obj)
              }

            } else {
              if (merchantAccountGatewayPaymentDevices.value.length > 0) {
                merchantAccountGatewayPaymentDevices.value.forEach((paymentDevice) => {
                  if (paymentDevice.mch_acc_pd_id == obj.mch_acc_pd_id) {
                    paymentDevice.mch_acc_pd_id = obj.mch_acc_pd_id
                    paymentDevice.pd_name = obj.pd_name
                    paymentDevice.location = obj.location
                    paymentDevice.pd_serial_number = obj.pd_serial_number
                    paymentDevice.status = obj.status
                    paymentDevice.gw_id = obj.gw_id
                    paymentDevice.pd_key1 = obj.pd_key1
                    paymentDevice.pd_key2 = obj.pd_key2
                    paymentDevice.pd_key3 = obj.pd_key3
                    paymentDevice.pd_key4 = obj.pd_key4
                    paymentDevice.pd_key5 = obj.pd_key5

                    paymentDevice.gw_pd_id = obj.gw_pd_id
                  }
                })
              }
            }
            slideOver.show = false;
            AddGwsSlideOver.show = true;
            formDevice.arrayIndex = null
            formDevice.gw_id = '';
            formDevice.gw_pd_id = '';
            formDevice.pd_name = '';
            formDevice.pd_serial_number = '';
            formDevice.location = '';
            formDevice.status = 'ACT';
            url.value = '';
            formDevice.mch_acc_pd_id = '';
            formDevice.errors = ''
          } else {
            formDevice.errors = response.data.data.errors
          }
        })
      }
      isLoading.value = false;
    }

    async function editPaymentGateway(gwId, gwName){
      gatewayIdInEditMode.value = gwId
      gatewayNameInEditMode.value = gwName
      form.gw_id = gatewayIdInEditMode.value
      form.errors = ''
      errorMchId.value = ''
      errorPrivateKey.value = ''
      errorPublicKey.value = ''
      errorPaymentMethod.value = ''
      errorApiKey1.value = ''
      gatewayOptions.value = []
      isView.value = false
      let obj = {}
      obj.value = form.gw_id
      obj.text = gatewayNameInEditMode.value
      gatewayOptions.value.push(obj)
      resetSlideOverDetails()
      await getAllPaymentDevicePerGateway(gatewayIdInEditMode.value)
      setDetailsToSlideOver(gatewayIdInEditMode.value, gatewayNameInEditMode.value)
      getGatewayApiKeysDetails(gatewayIdInEditMode.value)
      activeGatewayDetailsTabId.value = 'tab1'
    }

    function setDetailsToSlideOver(gatewayIdInEditMode, gatewayNameInEditMode){
      AddGwsSlideOver.title = "Payment Gateways - " + gatewayNameInEditMode
      merchantAccountGatewayPaymentDevices.value = []
      form.states = []
      form.isSelectAllGatewayPaymentMethod = 0
      let paymentMethodValue = false

      if(form.is_parent_cred_used == 1){
        merchantAccountPaymentGatewayCredentialsDetails.value.forEach((gatewayvalue) => {
          if(gatewayvalue.gw_id == gatewayIdInEditMode){
            form.gw_mch_id = gatewayvalue.gw_mch_id
            form.gw_key1 = gatewayvalue.gw_key1
            form.gw_key2 = gatewayvalue.gw_key2
            form.gw_key3 = gatewayvalue.gw_key3
            form.gw_key4 = gatewayvalue.gw_key4
            form.gw_key5 = gatewayvalue.gw_key5
            form.gw_key6 = gatewayvalue.gw_key6

            form.encrypt_gw_mch_id = gatewayvalue.gw_mch_id ? constantValue : ''
            form.encrypt_gw_key1 = gatewayvalue.gw_key1 ? constantValue : ''
            form.encrypt_gw_key2 = gatewayvalue.gw_key2 ? constantValue : ''
            form.encrypt_gw_key3 = gatewayvalue.gw_key3 ? constantValue : ''
            form.encrypt_gw_key4 = gatewayvalue.gw_key4 ? constantValue : ''
            form.encrypt_gw_key5 = gatewayvalue.gw_key5 ? constantValue : ''
            form.encrypt_gw_key6 = gatewayvalue.gw_key6 ? constantValue : ''
          }
        })

        if(paymentGatewayDetails.value.length == 0){
          paymentMethodsForAccount.value.forEach((paymentMethod) => {
            if(paymentMethod.gw_id == gatewayIdInEditMode){
              if(paymentMethod.payment_details.pm_cat !="CARD_NOT_PRESENT"){
                form.states.push(paymentMethod.tnt_pm_id)
              }
            }
          })
        }else{
          paymentGatewayDetails.value.forEach((gatewayDetails) => {
            if(gatewayDetails.gw_id == gatewayIdInEditMode){
              gatewayDetails.paymentMethods.forEach((paymentMethod) => {
                form.states.push(paymentMethod)
              })
              paymentMethodValue = true
            }
          })
        }

        if(paymentMethodValue == false){
          form.states = []
          paymentMethodsForAccount.value.forEach((paymentMethod) => {
            if(paymentMethod.gw_id == gatewayIdInEditMode){
              if(paymentMethod.payment_details.pm_cat !="CARD_NOT_PRESENT") {
                form.states.push(paymentMethod.tnt_pm_id)
              }
            }
          })
        }
      }

      gatewayDetails.value.forEach((gatewayDetail) => {
        if(gatewayDetail.gw_id == gatewayIdInEditMode) {
          form.is_js_support = gatewayDetail.is_js_support
        }
      })
      paymentGatewayDetails.value.forEach((gatewayDetails) => {
          if(gatewayDetails.gw_id == gatewayIdInEditMode){
            form.gw_mch_id = gatewayDetails.gw_mch_id
            form.gw_key1 = gatewayDetails.gw_key1
            form.gw_key2 = gatewayDetails.gw_key2
            form.gw_key3 = gatewayDetails.gw_key3
            form.gw_key4 = gatewayDetails.gw_key4
            form.gw_key5 = gatewayDetails.gw_key5
            form.gw_key6 = gatewayDetails.gw_key6

            if(form.is_parent_cred_used == 0){
              gatewayDetails.paymentMethods.forEach((paymentMethod) => {
                form.states.push(paymentMethod)
              })
            }

            gatewayDetails.device.forEach((deviceDetails) => {
              if(deviceDetails.gw_id == gatewayIdInEditMode){
                let obj = {}
                obj.mch_acc_pd_id = deviceDetails.mch_acc_pd_id
                obj.pd_name = deviceDetails.pd_name
                obj.location = deviceDetails.location
                obj.pd_serial_number = deviceDetails.pd_serial_number
                obj.status = deviceDetails.status
                obj.gw_id = deviceDetails.gw_id
                obj.pd_key1 = deviceDetails.pd_key1
                obj.pd_key2 = deviceDetails.pd_key2
                obj.pd_key3 = deviceDetails.pd_key3
                obj.pd_key4 = deviceDetails.pd_key4
                obj.pd_key5 = deviceDetails.pd_key5
                obj.gw_pd_id = deviceDetails.gw_pd_id

                merchantAccountGatewayPaymentDevices.value.push(obj)
              }
            })
          }
      })
    }

    async function getGatewayPaymentMethods(
      page = 1,
      column = sortColumn.value,
      search = null
    ) {
      if (search != null) {
        searchText.value = search
      }

        axios
          .get('v1/gateway/payment-methods/get-all/' + gatewayIdInEditMode.value, {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data.data != null) {
              merchantAccountGatewayPaymentMethods.value = response.data.data.data
              paymentMethodList.value = []
              response.data.data.data.forEach((paymentMethod) => {
                if(paymentMethod.pm_cat != 'CARD_NOT_PRESENT'){
                  paymentMethodList.value.push(paymentMethod.tnt_pm_id)
                }
              })
              checkAllPaymentMethodSelect()
              if(form.is_parent_cred_used == 1){
                paymentMethodCheckboxFunctionality()
              }
            }
          })
    }

    async function applyGatewayPaymentChanges(){
      let newValue = true
      errorMchId.value = ''
      errorPrivateKey.value = ''
      errorPublicKey.value = ''
      errorPaymentMethod.value = ''
      errorApiKey1.value = ''

      if((form.gw_mch_id == null || form.gw_mch_id == '') && requiredApiKeysValue.value == false){
        errorMchId.value = 'Please enter the merchant id.'
      }
      if((form.gw_key1 == null || form.gw_key1 == '') && requiredApiKeysValue.value == false){
        errorPrivateKey.value = 'Please enter the API key 1.'
      }
      if((form.encrypt_gw_key3 == null || form.encrypt_gw_key3 == '') && form.is_js_support == true && (form.gw_key3 == null || form.gw_key3 == '')){
        errorApiKey1.value = 'Please enter the API key 3.'
      }

      if(form.states.length == 0){
        errorPaymentMethod.value = 'Please select at least one payment method.'
        activeGatewayDetailsTabId.value = 'tab1'
      }
      if(errorMchId.value == '' && errorPrivateKey.value == '' && errorPublicKey.value == '' && errorPaymentMethod.value == '' && errorApiKey1.value == ''){
        paymentGatewayDetails.value.forEach((gatewayData) => {
          if(gatewayData.gw_id == gatewayIdInEditMode.value){
            newValue = false
            gatewayData.gw_mch_id = form.gw_mch_id
            gatewayData.gw_key1 = form.gw_key1
            gatewayData.gw_key2 = form.gw_key2
            gatewayData.gw_key3 = form.gw_key3
            gatewayData.gw_key4 = form.gw_key4
            gatewayData.gw_key5 = form.gw_key5
            gatewayData.gw_key6 = form.gw_key6
            gatewayData.paymentMethods = form.states
            gatewayData.applyChanges = true

            gatewayData.device = []
            merchantAccountGatewayPaymentDevices.value.forEach((device) => {
              if(device.gw_id == gatewayIdInEditMode.value){
                let deviceObj = {}
                deviceObj.mch_acc_pd_id = device.mch_acc_pd_id
                deviceObj.pd_name = device.pd_name
                deviceObj.location = device.location
                deviceObj.pd_serial_number = device.pd_serial_number
                deviceObj.status = device.status
                deviceObj.gw_id = device.gw_id
                deviceObj.pd_key1 = device.pd_key1
                deviceObj.pd_key2 = device.pd_key2
                deviceObj.pd_key3 = device.pd_key3
                deviceObj.pd_key4 = device.pd_key4
                deviceObj.pd_key5 = device.pd_key5
                deviceObj.gw_pd_id = device.gw_pd_id
                gatewayData.device.push(deviceObj)
              }
            })
          }
        })

        if(newValue == true){
          let obj = {}
          obj.gw_id = gatewayIdInEditMode.value
          obj.gw_mch_id = form.gw_mch_id
          obj.gw_key1 = form.gw_key1
          obj.gw_key2 = form.gw_key2
          obj.gw_key3 = form.gw_key3
          obj.gw_key4 = form.gw_key4
          obj.gw_key5 = form.gw_key5
          obj.gw_key6 = form.gw_key6
          obj.paymentMethods = form.states
          obj.applyChanges = true

          gatewayPaymentDevices.value = []
          merchantAccountGatewayPaymentDevices.value.forEach((device) => {
            if(device.gw_id == gatewayIdInEditMode.value){
              let obj1 = {}
              obj1.mch_acc_pd_id = device.mch_acc_pd_id
              obj1.pd_name = device.pd_name
              obj1.location = device.location
              obj1.pd_serial_number = device.pd_serial_number
              obj1.status = device.status
              obj1.gw_id = device.gw_id
              obj1.pd_key1 = device.pd_key1
              obj1.pd_key2 = device.pd_key2
              obj1.pd_key3 = device.pd_key3
              obj1.pd_key4 = device.pd_key4
              obj1.pd_key5 = device.pd_key5
              obj1.gw_pd_id = device.gw_pd_id
              gatewayPaymentDevices.value.push(obj1)
            }
          })
          obj.device = gatewayPaymentDevices.value
          paymentGatewayDetails.value.push(obj)
        }

        if(paymentGatewayDetails.value[0].paymentMethods.length > 0) {
          changeVal.value = true
        }

        AddGwsSlideOver.show = false
        resetSlideOverDetails()
      }
      gatewayDetails.value = await setBlueBtnOrRedBtn(gatewayDetails.value)
    }

    function checkGatewayDetailsCheckbox(gwId){
          if (form.gateways.includes(parseInt(gwId))) {
          return true
        }
      }

    async function clickGateway(event, gw_id, gw_name){
      if(event.target.checked){
        AddGwsSlideOver.show = true
        editPaymentGateway(gw_id, gw_name)
        form.gateways.push(gw_id)
        errorMsgForGateway.value = ''
        errorMchId.value = ''
        errorPrivateKey.value = ''
        errorPublicKey.value = ''
        errorPaymentMethod.value = ''
        errorApiKey1.value = ''
        await getGatewayPaymentMethods()
      }else{
        form.gateways.forEach((gw, index1) => {
          if(gw_id == gw){
            changeVal.value = true
            form.gateways.splice((parseInt(index1)), 1)
          }
        })
        paymentGatewayDetails.value.forEach((gatewayCredentials, index) => {
          if (gatewayCredentials.gw_id == gw_id) {
            paymentGatewayDetails.value.splice((parseInt(index)), 1)
          }
        })
      }
      gatewayDetails.value = await setBlueBtnOrRedBtn(gatewayDetails.value)
    }

    function disableEditButton(gwId){
      return !form.gateways.includes(parseInt(gwId));
    }

    function disableViewButton(gwId){
      return !form.gateways.includes(parseInt(gwId));
    }

    function disableCheckbox(gwId){
      if(form.is_parent_cred_used == 1){
        let arry = []
        form.parentGatewaysWithAcceptedCountry.forEach((gw, index) => {
          arry[index] = gw.gw_id
        })

        return !arry.includes(parseInt(gwId));
      }
    }

    function resetSlideOverDetails(){
      form.gw_mch_id = ''
      form.gw_key1 = ''
      form.gw_key2 = ''
      form.gw_key3 = ''
      form.gw_key4 = ''
      form.gw_key5 = ''
      form.gw_key6 = ''
      form.encrypt_gw_mch_id = ''
      form.encrypt_gw_key1 = ''
      form.encrypt_gw_key2 = ''
      form.encrypt_gw_key3 = ''
      form.encrypt_gw_key4 = ''
      form.encrypt_gw_key5 = ''
      form.encrypt_gw_key6 = ''
    }

    async function getPaymentMethodsToAccount() {
      isLoading.value = true
      form.gateways = []
      axios
        .get('/v1/get-merchant-account-payment-methods/' + form.mch_acc_id)
        .then((response) => {
          if (response.data != null) {
            paymentMethodsForAccount.value = response.data.data
          } else {
            console.error('Error!')
          }
        })
    }

    function closeGatewayDetailsSlideOver() {
      if(form.is_parent_cred_used == 0){
      }
    }

    async function checkPaymentMethodsEmptyOrNot(gwId){
      let value = false
        if(form.is_parent_cred_used == 1){
          for (const parentGateway of merchantAccountPaymentGatewayCredentialsDetails.value) {
            if(parentGateway.gw_id === gwId) {
              if(parentGateway.hasOwnProperty("credential_payment_methods")) {
                if(parentGateway.credential_payment_methods.length > 0) {
                  value = true
                }
              }
              if(parentGateway.hasOwnProperty("credential_gateway")) {
                if(parentGateway.credential_gateway.is_js_support ) {
                  if((parentGateway.gw_key3 != null && parentGateway.gw_key3 != '')) {
                    value = true
                  }else {
                    value = false
                  }
                }
              }
            }
          };
        }
        if (paymentGatewayDetails.value.length > 0){
          for (const paymentGateway of paymentGatewayDetails.value){
            if(paymentGateway.gw_id === gwId){
              if(paymentGateway.hasOwnProperty('paymentMethods')){
                if(paymentGateway.paymentMethods.length > 0){
                  value = true;
                }
              }
            }
          }
        }
      return value;
    }

    /*
    function redEditButtonIsVisible(gwId) {
      let value = false
      if(form.is_parent_cred_used == 0){
        paymentGatewayDetails.value.forEach((gwDetails) => {
          if(gwDetails.gw_id == gwId){
            value = true
          }
        })
        if(form.gateways.includes(parseInt(gwId)) && value == false){
          return true
        }
      }else {
        let isParentGateway = false
        if(Array.isArray(merchantAccountPaymentGatewayCredentialsDetails.value) ) {
        merchantAccountPaymentGatewayCredentialsDetails.value.forEach( parentGateway => {
          if(parentGateway.gw_id == gwId) {
            if(parentGateway.hasOwnProperty("credential_payment_methods")) {
              if(parentGateway.credential_payment_methods.length != 0) {
                value = false
              }else {
                value = true
                isParentGateway = true
                notComplete.value = true
              }
            } else {
              value = false
            }
          }
          else {
            if(isParentGateway == false) {
              value = false

            }
          }
        });
      }
          return value
      }
    }
    */


    /*function blueEditButtonIsVisible(gwId) {
      let value = false
      if(form.is_parent_cred_used == 1){
        let isParentGateway = false
        if(Array.isArray(merchantAccountPaymentGatewayCredentialsDetails.value) ) {
        merchantAccountPaymentGatewayCredentialsDetails.value.forEach( (parentGateway) => {
          if(parentGateway.gw_id == gwId) {
            if(parentGateway.hasOwnProperty("credential_payment_methods")) {
              if(parentGateway.credential_payment_methods.length != 0) {
                value = true
              }else {
                value = false
                isParentGateway = true
                notComplete.value = true
              }
            } else {
              value = true
            }
          }
          else {
            if(isParentGateway == false) {
              value = true

            }
          }
        });
      }
          return value
      }else{
        paymentGatewayDetails.value.forEach((gwDetails) => {
          if(gwDetails.gw_id == gwId){
            value = true
          }
        })
        if(!form.gateways.includes(parseInt(gwId)) || value == true){
          return true
        }
      }
    }
    */

    function closeWarning() {
      modalWarning.show = false
    }

    function decryptData(data, value){
      decryptDataForm.data = data
      axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
        if (response.data != null) {
          if(value == 'gw_mch_id'){
            form.encrypt_gw_mch_id = response.data
          }
          else if(value == 'gw_key2'){
            form.encrypt_gw_key2 = response.data
          }
          else if(value == 'gw_key1'){
            form.encrypt_gw_key1 = response.data
          }
          else if(value == 'gw_key3'){
            form.encrypt_gw_key3 = response.data
          }
          else if(value == 'gw_key4'){
            form.encrypt_gw_key4 = response.data
          }
          else if(value == 'gw_key5'){
            form.encrypt_gw_key5 = response.data
          }
          else if(value == 'gw_key6'){
            form.encrypt_gw_key6 = response.data
          }
        } else {
          console.error('Error!')
        }
      })
    }

    function getGatewayApiKeysDetails(gw_id) {
      requiredApiKeysValue.value = false
      if(gw_id != null && gw_id != ''){
        axios
            .get('v1/gateway/api-keys/view/' + gw_id)
            .then((response) => {
              if (response.data.data != null) {
                if(response.data.data.is_merchant_cred_used == 0){
                  requiredApiKeysValue.value = true
                }
              }
            })
      }
    }

    function getMerchantAccountDetailsById(mch_acc_id) {
      if(mch_acc_id != 0 ){
        axios.get('/v1/get-merchant-account/' + mch_acc_id).then(async(response) => {
          if (response.data != null) {
            form.country_code = response.data.data.country_code;
            form.act_as_agent = response.data.data.merchant.act_as_agent;
            if(form.act_as_agent == 0) {
              await getGateways(sortColumn.value);
            }
          } else {
            console.error('Error!')
          }
        })
      }
    }


    return {
      t,
      slideOver,
      showTabContent,
      sortColumnDirection,
      gatewayDetailsTabItems,
      activeGatewayDetailsTabId,
      form,
      saveMerchantAccountGatewayDetails,
      mchAccountId,
      getGateways,
      gatewayOptions,
      getGatewayPaymentMethods,
      onchange,
      merchantAccountGatewayPaymentMethods,
      saveMerchantAccountGatewayPaymentMethodDetails,
      modal,
      closeAlert,
      setPaymentMethods,
      stateOptions,
      getAllPaymentDevicePerGateway,
      formDevice,
      paymentDeviceOptions,
      statusOptions,
      modalDevice,
      closeAlertDevice,
      getGatewayPaymentDevices,
      merchantAccountGatewayPaymentDevices,
      getGatewayPaymentDeviceByMchAccPaymentDeviceId,
      paymentDevicesByPaymentDeviceId,
      editMerchantAccountGatewayPaymentDeviceDetails,
      sortColumn,
      sort,
      searchText,
      perPage,
      sortPaymentDevice,
      setToParentGatewayAccount,
      parentMchId,
      getMerchantAccountPaymentGaetwayCredentialsDetailsByMchAccId,
      merchantAccountPaymentGatewayCredentialsDetails,
      merchantAccountDetails,
      responseData,
      errorForParentAccountLink,
      setToExistingGatewayAccount,
      fullPage,
      isLoading,
      // checkboxDisableValue,
      openSlideOver,
      disableDeviceValue,
      addDeviceOpen,
      deletePaymentDeviceType,
      confirmAlert,
      url,
      msg,
      setPaymentMethodCheck,
      selectAllPaymentMethod,
      lastPage,
      totatlRecord,
      currentPage,
      removeDetails,
      removePaymentDevices,
      applyMerchantAccountGatewayPaymentDeviceDetails,
      AddGwsSlideOver,
      gatewayDetails,
      editPaymentGateway,
      applyGatewayPaymentChanges,
      paymentGatewayDetails,
      checkGatewayDetailsCheckbox,
      resetSlideOverDetails,
      setDetailsToSlideOver,
      gatewayPaymentDevices,
      fieldsDisableValue,
      clickGateway,
      disableEditButton,
      paymentMethodsForAccount,
      getPaymentMethodsToAccount,
      closeGatewayDetailsSlideOver,
      // redEditButtonIsVisible,
      // blueEditButtonIsVisible,
      closeWarning,
      modalWarning,
      errorMsgForGateway,
      decryptDataForm,
      decryptData,
      sortGateway,
      constantValue,
      warningValue,
      errorMchId,
      errorPrivateKey,
      errorPublicKey,
      errorPaymentMethod,
      requiredApiKeysValue,
      isView,
      isDisabled,
      getAllGatewaysWithoutCountrySelection,
      getMerchantAccountDetailsById,
      disableViewButton,
      disableCheckbox,
      notComplete,
      changeVal,
      errorApiKey1

    }
  },
}
</script>
