<template>
  <slide-over
    :close-button="slideOver.close_button"
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    @closeSlideOver="slideOver.show = false, $emit('hideAccountsSlideOver'), tabDetailsReset()"
  >
    <template #slideover-body>
      <div class="mb-6 font-semibold text-black text-opacity-80 text-lg">
        {{ t("Merchant Account Details") }}
      </div>

      <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4 break-words">
        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Merchant Account Name") }}
          </div>
          <div class="mt-1 text-black font-medium break-all">
            {{ form.organisationName }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Website URL") }}
          </div>
          <div
            v-if="
              form.websiteUrl != null && form.websiteUrl.length > urlCharCount
            "
            class="mt-1 text-black font-medium"
          >
            <div> {{ form.websiteUrl }}</div>
            <button
              v-if="showFullUrl == true"
              class="btn_th-sort btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
              type="button"
              @click="threeDot(form.orgWebsiteUrl, urlCharCount, false)"
            >
              {{ "Hide URL" }}
            </button>
            <button
              v-else
              class="btn_th-sort btn_th-sort weburlr text-sm router-link-active mr-0.5 text-primary underline"
              type="button"
              @click="threeDot(form.orgWebsiteUrl, urlCharCount, true)"
            >
              {{ "Show URL" }}
            </button>
          </div>
          <div
            v-else
            class="mt-1 text-black font-medium"
          >
            {{ form.websiteUrl }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Status") }}
          </div>
          <div class="mt-1 text-black font-medium">
            <badge
              v-if="form.status == 'ACT'"
              text="Active"
              type="success"
            />
            <badge
              v-if="form.status == 'INA'"
              text="Inactive"
              type="danger"
            />
          </div>
        </div>

        <div>
          <div
            v-if="form.is_parent == 1 "
            class="text-xs font-normal text-black opacity-70"
          >
            {{ t("Main Agency Account") }}
          </div>
          <div
            v-if="form.is_parent == 0 "
            class="text-xs font-normal text-black opacity-70"
          >
            {{ t("Agency Account") }}
          </div>
          <div class="mt-1 text-black font-medium">
            <badge
              v-if="form.act_as_agent == 1"
              type="success"
              text="Yes"
            />
            <badge
              v-if="form.act_as_agent == 0"
              type="danger"
              text="No"
            />
          </div>
        </div>
      </div>

      <div class="mt-8 tab-group">
        <div class="tabs-wrapper">
          <nav
            aria-label="Tabs"
            class="tabs"
          >
            <a
              v-for="(tabItem, index) in accountEditTabItems"
              :key="index"
              :class="{ active: activeMainTabId == tabItem.id }"
              class="tab-item"
              href="#"
              @click="showTabContent('activeMainTabId', tabItem.id)"
            >{{ t(tabItem.name) }}</a>
          </nav>
        </div>

        <div class="tab-container">
          <div v-if="activeMainTabId == 'tab0'">
            <div class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Country") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.country }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("State") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.state }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Suburb/Town") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.suburb }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Post Code") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.postalCode }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Address Line 1") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.address1 }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Address Line 2") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.address2 }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeMainTabId == 'tab1'">
            <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 break-words">
              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Last Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.lastName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Given Name") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.givenName }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Phone Number") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.phoneNo }}
                </div>
              </div>

              <div>
                <div class="text-xs font-normal text-black opacity-70">
                  {{ t("Business Email") }}
                </div>
                <div class="mt-1 text-black font-medium">
                  {{ form.businessMail }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeMainTabId == 'tab2'">
            <div
              class="mb-5"
            >
              <!-- start  mulitple GWs ui  ui   ---->



              <TableDefault>
                <template #table>
                  <table
                    id="accounts_edit_payment_methods_tbl"
                    aria-describedby=""
                    class="table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          <div class="flex items-center">
                            <span>{{ t("Payment Gateways") }}</span>
                            <button
                              class="inline-flex focus:outline-none opacity-40"
                              type="button"
                              @click="sortGateway('name')"
                            >
                              <svg-icon
                                v-if="
                                  sortColumnDirection == 'asc' &&
                                    sortColumn == 'name'
                                "
                                class="text-black w-7 h-7"
                                icon="caret-up"
                              />
                              <svg-icon
                                v-else
                                class="text-black w-7 h-7"
                                icon="caret-down"
                              />
                            </button>
                          </div>
                        </th>
                        <th scope="col" />
                        <th
                          scope="col"
                          class="th-action"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr
                        v-for="(gateway_details, index) in gatewayDetails"
                        :key="index"
                      >
                        <td>
                          {{ gateway_details.name }}
                        </td>
                        <td>
                          <div
                            class="flex items-center justify-center space-x-5"
                          >
                            <form-checkbox
                              id="index"
                              name="gateway"
                              :checked="checkGatewayDetailsCheckbox(gateway_details.gw_id)"
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <div class="flex items-center justify-center space-x-5">
                            <button-circle
                              type="info"
                              icon="eye"
                              @click="(AddGwsSlideOver.show = true,
                                       slideOver.show = false),
                                      editPaymentGateway(gateway_details.gw_id, gateway_details.name),
                                      getGatewayPaymentMethods()"
                            />
                          </div>
                        </td>
                      </tr>

                      <no-table-data v-if="gatewayDetails.length == 0" />
                    </tbody>
                  </table>
                </template>
              </TableDefault>

              <hr class="mt-5 mb-5">

              <!-- end mulitple GWs ui ---->
            </div>
          </div>
        </div>

        <div v-if="activeMainTabId == 'tab3'">
          <div class="space-y-3 w-full">
            <div>
              <form-label for="merchant_id">
                {{ t("Merchant ID") }}
              </form-label>
              <div class="mt-1 sm:flex items-center sm:space-x-3">
                <div class="w-full sm:flex-1 mb-2 sm:mb-0">
                  <form-input
                    id="merchant_id"
                    v-model="form.op_mch_id"
                    disabled
                    name="merchant_id"
                    type="text"
                  />
                </div>
                <ButtonSecondary
                  class="w-full sm:w-auto whitespace-nowrap"
                  type="button"
                  @click="copyOpuspayMerchnatId()"
                >
                  {{ t("Copy to Clipboard") }}
                </ButtonSecondary>
              </div>
            </div>

            <div>
              <form-label for="api_key">
                {{ t("API Key") }}
              </form-label>
              <div class="relative">
                <div class="mt-1 sm:flex items-center sm:space-x-3">
                  <div class="w-full sm:flex-1 mb-2 sm:mb-0 relative">
                    <button
                      v-if="form.encrypt_op_api_key == constantValue"
                      class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                      type="button"
                      @click="decryptOpuspayApiData(form.op_api_key,'op_api_key')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                        />
                      </svg>
                    </button>
                    <form-input
                      id="api_key"
                      v-model="form.encrypt_op_api_key"
                      disabled
                      name="api_key"
                      type="text"
                    />
                  </div>
                  <ButtonSecondary
                    class="w-full sm:w-auto whitespace-nowrap"
                    type="button"
                    @click="decryptOpuspayApiData(form.op_api_key,'copy_op_api_key_copy')"
                  >
                    {{ t("Copy to Clipboard") }}
                  </ButtonSecondary>
                </div>
              </div>
            </div>

            <div>
              <form-label for="api_secret_key">
                {{ t("API Secret Key") }}
              </form-label>
              <div class="relative">
                <div class="mt-1 sm:flex items-center sm:space-x-3">
                  <div class="w-full sm:flex-1 mb-2 sm:mb-0 relative">
                    <button
                      v-if="form.encrypt_op_api_secret == constantValue"
                      class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                      type="button"
                      @click="decryptOpuspayApiData(form.op_api_secret,'op_api_secret')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                        />
                      </svg>
                    </button>
                    <form-input
                      id="api_secret_key"
                      v-model="form.encrypt_op_api_secret"
                      disabled
                      name="api_secret_key"
                      type="text"
                    />
                  </div>
                  <ButtonSecondary
                    class="w-full sm:w-auto whitespace-nowrap"
                    type="button"
                    @click="decryptOpuspayApiData(form.op_api_secret, 'copy_op_api_secret_copy')"
                  >
                    {{ t("Copy to Clipboard") }}
                  </ButtonSecondary>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <Button
              v-if="merchantUser == null "
              disabled
              type="button"
            >
              {{ t("Generate New API Credentials") }}
            </Button>
          </div>

          <div class="mt-4" />
          <div
            v-if="false"
            class="text-sm"
          >
            {{ t("View") }}
            <a
              class="text-primary underline"
              href="#"
              @click="viewTermsAndCondition()"
            >{{ t("Terms & Conditions") }}</a>
          </div>
        </div>

        <modal-form
          :close-button="modalTerms.close_button"
          :confirm-button="modalTerms.confirm_button"
          :modal-size="modalTerms.size"
          :show="modalTerms.show"
          :title="modalTerms.title"
          @closeModal="modalTerms.show = false"
        >
          <template #modal-body>
            <div
              class="tinymce-wrapper space-y-4"
              style="min-height: calc(100vh - 230px);"
            >
              <editor
                v-model="tcDescription"
                :api-key="tinymce_api_key"
                :disabled="true"
                :init="editorOptions"
                menu="false"
                menubar="false"
                toolbar="false"
              />
            </div>
          </template>
        </modal-form>

        <div v-if="activeMainTabId == 'tab4'">
          <account-plan />
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <div class="w-full flex items-center justify-between space-x-2">
        <div class="flex-1">
          <div class="flex items-center">
            <div class="flex items-center flex-wrap text-sm sm:space-x-3">
              <div class="flex items-center mr-3 sm:mr-10">
                <form-checkbox
                  id="invoice_account_directly"
                  v-model="form.is_invoice_directly"
                  :checked="
                    ($emit('sendStatus', form.is_invoice_directly),
                     form.is_invoice_directly == 1 ? true : false)
                  "
                  :model-value="form.is_invoice_directly == 1 ? 0 : 1"
                  class="mr-2"
                  disabled
                  name="invoice_account_directly"
                />
                <label
                  class="text-sm"
                  for="invoice_account_directly"
                >{{ t("Invoice Account Directly") }}</label>
              </div>
              <div class="flex items-center">
                <span class="mr-1">{{ t("View") }}</span>
                <a
                  class="text-primary underline"
                  href="#"
                  @click="viewTermsAndCondition()"
                >{{ t("Terms & Conditions") }}</a>
              </div>
            </div>
          </div>
        </div>

        <Button
          v-if="merchantUser == null "
          class="btn-wide"
          type="button"
          @click="editMerchantAccount(form.mch_acc_id, null, null)"
        >
          {{ t("Edit") }}
        </Button>
      </div>
    </template>
  </slide-over>

  <slide-over
    :show="deviceDetailSlideOver.show"
    :slideover-size="deviceDetailSlideOver.size"
    :slideover-title="deviceDetailSlideOver.title"
    :close-button="deviceDetailSlideOver.close_button"
    @closeSlideOver="; (deviceDetailSlideOver.show = false), (slideOver.show = true)"
  >
    <template #slideover-body>
      <div class="space-y-4">
        <div>
          <form-label for="gateway-name">
            {{ t("Gateway") }}
          </form-label>
          <form-input
            id="gateway-name"
            v-model="form.gw_name"
            class="mt-1"
            disabled
            name="gateway"
          />
        </div>

        <div>
          <form-label for="device-type">
            {{ t("Device Type") }}
          </form-label>
          <form-input
            id="device-type"
            v-model="formDevice.tnt_pd_type_id"
            class="mt-1"
            name="device_type"
            disabled
          />
        </div>

        <div>
          <form-label for="device-name">
            {{ t("Name") }}
          </form-label>
          <form-input
            id="device-name"
            v-model="formDevice.pd_name"
            class="mt-1"
            name="device_name"
            required
            type="text"
            disabled
          />
        </div>

        <div>
          <form-label for="device-id">
            {{ t("Device ID") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_serial_number && formDevice.encrypt_pd_serial_number == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_serial_number, 'pd_serial_number')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="device-id"
              v-model="formDevice.encrypt_pd_serial_number"
              class="mt-1"
              name="device_name"
              required
              type="text"
              disabled
            />
          </div>
        </div>

        <div>
          <form-label for="key-1">
            {{ t("Device Key 1") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_key1 && formDevice.encrypt_pd_key1 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_key1, 'pd_key1')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="pd_key1"
              v-model="formDevice.encrypt_pd_key1"
              class="mt-1"
              name="pd_key1"
              type="text"
              disabled
            />
          </div>
        </div>

        <div>
          <form-label for="key-2">
            {{ t("Device Key 2") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_key2 && formDevice.encrypt_pd_key2 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_key2, 'pd_key2')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>

            <form-input
              id="pd_key2"
              v-model="formDevice.encrypt_pd_key2"
              class="mt-1"
              name="pd_key2"
              type="text"
              disabled
            />
          </div>
        </div>

        <div>
          <form-label for="key-3">
            {{ t("Device Key 3") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_key3 && formDevice.encrypt_pd_key3 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_key3, 'pd_key3')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="pd_key3"
              v-model="formDevice.encrypt_pd_key3"
              class="mt-1"
              name="pd_key3"
              type="text"
              disabled
            />
          </div>
        </div>

        <div>
          <form-label for="key-4">
            {{ t("Device Key 4") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_key4 && formDevice.encrypt_pd_key4 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_key4, 'pd_key4')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="pd_key4"
              v-model="formDevice.encrypt_pd_key4"
              class="mt-1"
              name="pd_key4"
              type="text"
              disabled
            />
          </div>
        </div>

        <!-- Start Device Key 5-->
        <div>
          <form-label for="key-5">
            {{ t("Device Key 5") }}
          </form-label>
          <div class="relative">
            <button
              v-if="formDevice.pd_key5 && formDevice.encrypt_pd_key5 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptDeviceData(formDevice.pd_key5, 'pd_key5')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <form-input
              id="pd_key5"
              v-model="formDevice.encrypt_pd_key5"
              class="mt-1"
              name="pd_key5"
              type="text"
              disabled
            />
          </div>
        </div>
        <!-- End Device Key 5-->

        <div>
          <form-label for="device-status">
            {{ t("Status") }}
          </form-label>
          <form-input
            id="device-status"
            v-model="formDevice.status"
            class="mt-1"
            name="device_status"
            required
            disabled
          />
        </div>

        <div>
          <form-label for="device-location">
            {{ t("Location") }}
          </form-label>
          <form-textarea
            id="device-location"
            v-model="formDevice.location"
            class="mt-1"
            name="device_location"
            disabled
          />
        </div>
      </div>
    </template>

    <template #slideover-actions />
  </slide-over>



  <slide-over
    :show="AddGwsSlideOver.show"
    :slideover-size="AddGwsSlideOver.size"
    :slideover-title="AddGwsSlideOver.title"
    :close-button="AddGwsSlideOver.close_button"
    @closeSlideOver="; (AddGwsSlideOver.show = false), (slideOver.show = true)"
  >
    <template #slideover-body>
      <div class="mb-5">
        <div class="pr-4">
          <div>
            <div class="mt-1 flex items-center">
              <form-radio
                id="view-view-multiiple-link-parent-gateway"
                name="view-view-multiiple-gateway_link"
                :checked="form.is_parent_cred_used == 1"
                disabled
              />
              <label
                class="ml-2 text-sm"
                for="view-view-multiiple-link-parent-gateway"
              >{{ t("Link to the Parent Entity's gateway account") }}</label>
            </div>
            <div class="mt-1 flex items-center">
              <form-radio
                id="view-view-multiiple-link-existing-gateway"
                name="view-view-multiiple-gateway_link"
                :checked="form.is_parent_cred_used == 0"
                disabled
              />
              <label
                class="ml-2 text-sm"
                for="view-view-multiiple-link-existing-gateway"
              >{{ t("Link to an existing gateway account") }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 break-words">
        <!--    <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Gateway") }}
          </div>
          <div class="mt-1 text-black font-medium">
            {{ form.gw_name }}
          </div>
        </div> -->

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Merchant ID") }}
          </div>
          <div class="mt-1 text-black font-medium">
            <div class="relative">
              <button
                v-if="form.gw_mch_id && form.encrypt_gw_mch_id == constantValue"
                class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
                type="button"
                @click="decryptData(form.gw_mch_id, 'gw_mch_id')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                  />
                </svg>
              </button>
              {{ form.gw_mch_id ? form.encrypt_gw_mch_id : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 1") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key1 && form.encrypt_gw_key1 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key1, 'gw_key1')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key1 ? form.encrypt_gw_key1 : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 2") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key2 && form.encrypt_gw_key2 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key2, 'gw_key2')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key2 ? form.encrypt_gw_key2 : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 3") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key3 && form.encrypt_gw_key3 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key3, 'gw_key3')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key3 ? form.encrypt_gw_key3 : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 4") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key4 && form.encrypt_gw_key4 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key4, 'gw_key4')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key4 ? form.encrypt_gw_key4 : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 5") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key5 && form.encrypt_gw_key5 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key5, 'gw_key5')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key5 ? form.encrypt_gw_key5 : '' }}
            </div>
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("API Key 6") }}
          </div>
          <div class="relative">
            <button
              v-if="form.gw_key6 && form.encrypt_gw_key6 == constantValue"
              class="absolute inset-y-0 left-0 m-px border-r rounded-r-sm bg-gray-100 text-gray-500 px-2 py-2 w-auto h-9 cursor-pointer"
              type="button"
              @click="decryptData(form.gw_key6, 'gw_key6')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
                />
              </svg>
            </button>
            <div class="mt-1 text-black font-medium">
              {{ form.gw_key6 ? form.encrypt_gw_key6 : '' }}
            </div>
          </div>
        </div>
      </div>

      <div class="my-5 border-b border-gray-200">
        <nav
          aria-label="Tabs"
          class="-mb-px flex space-x-8"
        >
          <a
            :class="{ active: activeSubTab == 'payment_methods' }"
            class="secondary-tab-item"
            href="#"
            @click="activeSubTab = 'payment_methods'"
          >
            {{ t("Payment Methods") }}
          </a>

          <a
            :class="{ active: activeSubTab == 'devices' }"
            class="secondary-tab-item"
            href="#"
            @click="activeSubTab = 'devices'"
          >
            {{ t("Devices") }}
          </a>
        </nav>
      </div>

      <div v-if="activeSubTab == 'payment_methods'">
        <div class="mb-4 grid grid-cols-2 gap-y-3">
          <div class="w-full flex justify-end" />
        </div>

        <TableDefault>
          <template #table>
            <table
              aria-describedby=""
              class="table"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <div class="flex items-center">
                      <span>{{ t("Payment Methods") }}</span>
                      <button
                        class="btn_th-sort"
                        type="button"
                        @click="sort('paymentMethod')"
                      >
                        <svg-icon
                          v-if="
                            sortColumnDirection == 'asc' &&
                              sortColumn == 'paymentMethod'
                          "
                          class="icon_th-sort"
                          icon="th-caret-up"
                        />
                        <svg-icon
                          v-else
                          class="icon_th-sort"
                          icon="th-caret-down"
                        />
                      </button>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex items-center justify-center">
                      <form-checkbox
                        v-model="form.isSelectAllGatewayPaymentMethod"
                        :check-value="true"
                        :checked="
                          form.isSelectAllGatewayPaymentMethod == 1
                            ? true
                            : false
                        "
                        :model-value="
                          form.isSelectAllGatewayPaymentMethod == 1
                            ? 0
                            : 1
                        "
                        disabled
                        name="all-payment_method"
                        @click="selectAllPaymentMethod()"
                      />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(
                    payment_method_data, index
                  ) in merchantAccountGatewayPaymentMethods"
                  :key="index"
                >
                  <td
                    v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                    :for="index"
                  >
                    {{ payment_method_data.description }}
                  </td>

                  <td v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'">
                    <div
                      class="flex items-center justify-center space-x-5"
                    >
                      <form-checkbox
                        v-if="payment_method_data.pm_cat != 'CARD_NOT_PRESENT'"
                        :id="index"
                        :checked="
                          setPaymentMethodCheck(
                            payment_method_data.tnt_pm_id
                          )
                        "
                        :model-value="
                          payment_method_data.tnt_pm_id ? 1 : 0
                        "
                        disabled
                        name="payment_method"
                        @click="
                          setPaymentMethods(payment_method_data.tnt_pm_id)
                        "
                      />
                    </div>
                  </td>
                </tr>

                <no-table-data
                  v-if="!merchantAccountGatewayPaymentMethods"
                />
              </tbody>
            </table>
          </template>
        </TableDefault>
      </div>

      <div v-if="activeSubTab == 'devices'">
        <div class="mb-4 grid grid-cols-2 gap-y-3">
          <div class="flex items-center">
            <span class="text-sm">{{ t("Show") }}</span>
            <div class="mx-2">
              <form-select
                id="results_per_page1"
                v-model="perPage"
                :items="[10, 25, 50, 100]"
                class="py-2"
                name="results_per_page"
                @onChangeFunction="getGatewayPaymentDevices()"
              />
            </div>
            <span class="text-sm">{{ t("entries") }}</span>
          </div>
        </div>

        <TableDefault>
          <template #table>
            <div class="overflow-y-auto-custom">
              <table
                aria-describedby=""
                class="table"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Name") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sortPaymentDevice('pd_name')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'pd_name'
                            "
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center">
                        <span>{{ t("Location") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sortPaymentDevice('location')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'location'
                            "
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th scope="col">
                      <div class="flex items-center justify-center">
                        <span>{{ t("Status") }}</span>
                        <button
                          class="btn_th-sort"
                          type="button"
                          @click="sortPaymentDevice('status')"
                        >
                          <svg-icon
                            v-if="
                              sortColumnDirection == 'asc' &&
                                sortColumn == 'status'
                            "
                            class="icon_th-sort"
                            icon="th-caret-down"
                          />
                          <svg-icon
                            v-else
                            class="icon_th-sort"
                            icon="th-caret-up"
                          />
                        </button>
                      </div>
                    </th>
                    <th
                      v-if="mode == 'edit-mode'"
                      class="th-action"
                      scope="col"
                    >
                      {{ t("Actions") }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(
                      payment_device_data, index
                    ) in merchantAccountGatewayPaymentDevices"
                    :key="index"
                  >
                    <td>
                      <router-link
                        :to="{ path: '/accounts/index' }"
                        class="text-primary underline"
                        @click="getGatewayPaymentDeviceByMchAccPaymentDeviceId(
                                  payment_device_data.mch_acc_pd_id
                                ),
                                (slideOver.show = false), (deviceDetailSlideOver.show = true), (AddGwsSlideOver.show = false)
                        "
                      >
                        {{ payment_device_data.pd_name }}
                      </router-link>
                    </td>
                    <td>{{ payment_device_data.location }}</td>
                    <td class="text-center">
                      <badge
                        v-if="payment_device_data.status == 'ACT'"
                        text="Active"
                        type="success"
                      />
                      <badge
                        v-if="payment_device_data.status == 'INA'"
                        text="Inactive"
                        type="danger"
                      />
                    </td>
                    <td v-if="mode == 'edit-mode'">
                      <div
                        class="flex items-center justify-center space-x-5"
                      >
                        <button-circle
                          icon="trash"
                          type="danger"
                        />
                      </div>
                    </td>
                  </tr>

                  <no-table-data
                    v-if="!merchantAccountGatewayPaymentDevices.length"
                  />
                </tbody>
              </table>
            </div>
          </template>
        </TableDefault>
        <vue-pagination
          v-if="false"
          :last-page="lastPage"
          :page-size="perPage"
          :sort-column="sortColumn"
          :sort-direction="sortColumnDirection"
          :total-records="totatlRecord"
          @page-changed="getGatewayPaymentDevices($event)"
        />
      </div>
    </template>

    <template #slideover-actions>
      <div class="w-full flex items-center justify-between space-x-2">
        <div class="flex-1">
          <div class="flex items-center">
            <div class="flex items-center flex-wrap text-sm sm:space-x-3" />
          </div>
        </div>

        <Button
          v-if="merchantUser == null "
          class="btn-wide"
          type="button"
          @click="editMerchantAccount(form.mch_acc_id, gatewayIdInEditMode, gatewayNameInEditMode)"
        >
          {{ t("Edit") }}
        </Button>
      </div>
    </template>
  </slide-over>
</template>

<script>
  import {ref, reactive, onMounted, watchEffect} from 'vue'
  import { useI18n } from 'vue-i18n'
  import Button from '@/components/Button.vue'
  import SlideOver from '@/components/SlideOver.vue'
  import AccountPlan from '@/pages/merchant-portal/accounts/AccountPlan.vue'
  import SvgIcon from '@/components/SvgIcon.vue'
  import FormLabel from '@/components/FormLabel.vue'
  import FormRadio from '@/components/FormRadio.vue'
  import FormInput from '@/components/FormInput.vue'
  import FormSelect from '@/components/FormSelect.vue'
  import NoTableData from '@/components/NoTableData.vue'
  import FormCheckbox from '@/components/FormCheckbox.vue'
  import TableDefault from '@/components/TableDefault.vue'
  import ButtonCircle from '@/components/ButtonCircle.vue'
  import ButtonSecondary from '@/components/ButtonSecondary'
  import axios from 'axios'
  import {useRoute, useRouter} from 'vue-router'
  import Badge from '@/components/Badge.vue'
  import ModalForm from '@/components/ModalForm.vue'
  import Editor from '@tinymce/tinymce-vue'
  import tinymceConfig from '@/plugins/tinymce-config.js'
  import {copyText} from 'vue3-clipboard'
  import VuePagination from '@/components/VuePagination'
  import FormTextarea from '@/components/FormTextarea.vue'
  import constant from "@/constant";

  export default {
    name: 'ViewAccount',

    components: {
      Button,
      SlideOver,
      AccountPlan,
      SvgIcon,
      FormLabel,
      FormInput,
      FormRadio,
      FormSelect,
      NoTableData,
      FormCheckbox,
      FormTextarea,
      TableDefault,
      ButtonCircle,
      ButtonSecondary,
      Badge,
      ModalForm,
      VuePagination,
      editor: Editor,
    },

    props: {
      showAccountsSlideOver: {
        type: Boolean,
        default: false,
      },
      mch: {
        type: Number,
        default: 0,
      },
      tab: {
        type: String,
        default: 'tab0',
      },
      subtab: {
        type: String,
        default: 'payment_methods',
      },
    },

    emits: ['sendStatus', 'hideAccountsSlideOver'],

    setup(props, {emit}) {
      const { t } = useI18n()
      const mchId = ref(0);
      const sortColumnDirection = ref('desc');
      const perPage = ref(10);
      const sortColumn = ref('');
      const searchText = ref('');
      const totatlRecord = ref(0);
      const currentPage = ref(0);
      const lastPage = ref(0);

      const decryptDeviceDataForm = reactive({
        data: '',
      })

      const slideOver = reactive({
        show: false,
        size: 'max-w-5xl',
        close_button: false,
        title: 'View Account',
      });

      const deviceDetailSlideOver = reactive({
        show: false,
        size: 'max-w-md',
        close_button: false,
        title: 'View Device',
      });

      const accountEditTabItems = reactive([
        {id: 'tab0', name: 'Address Details'},
        {id: 'tab1', name: 'Primary Contact'},
        {id: 'tab2', name: 'Gateway'},
        {id: 'tab3', name: 'opuspay API Credentials'},
        {id: 'tab4', name: 'Plan'},
      ]);

      const activeMainTabId = ref(accountEditTabItems[0].id);

      const activeSubTab = ref('payment_methods');

      const countryOptions = ref([]);
      const stateOptions = ref([]);
      const townOptions = ref([]);
      const postalCodeOptions = ref([]);

      const merchantAccountDetails = ref(0);
      const merchantAccountCredentialsDetails = ref(0);
      const merchantAccountPaymentMethods = ref(0);
      const route = useRoute();

      const merchantAccountGatewayPaymentDevices = ref(0);
      const merchantAccountGatewayPaymentMethods = ref([]);
      const paymentMethodList = ref([]);
      const merchantUser = ref('');
      const paymentDevicesByPaymentDeviceId = ref(0)
      const gatewayDetails = ref(0)
      const parentGatewayDetails = ref(0)
      const paymentGatewayDetails = ref([])
      const gatewayIdInEditMode = ref(0)
      const gatewayNameInEditMode = ref('')
      const constantValue = constant.encryptionKey

      const form = reactive({
        id: '',
        mch_acc_id: '',
        mch_acc_pgw_id: '',
        organisationName: '',
        lastName: '',
        givenName: '',
        businessMail: '',
        phoneNo: '',
        country: '',
        countryId: '',
        state: '',
        states: [],
        stateId: '',
        address1: '',
        address2: '',
        suburb: '',
        suburbId: '',
        postalCode: '',
        postalCodeId: '',
        websiteUrl: '',
        orgWebsiteUrl: '',
        username: '',
        errors: '',
        status: '',
        gw_id: '',
        gw_name: '',
        gw_key2: '',
        gw_key1: '',
        gw_mch_id: '',
        op_mch_id: '',
        op_api_key: '',
        op_api_secret: '',
        encrypt_op_api_key: constant.encryptionKey,
        encrypt_op_api_secret: constant.encryptionKey,
        gw_key3: '',
        gw_key4: '',
        gw_key5: '',
        gw_key6: '',
        is_invoice_directly: 0,
        isSelectAllGatewayPaymentMethod: 0,
        is_parent: 0,
        gateways: [],
        encrypt_gw_mch_id: constant.encryptionKey,
        encrypt_gw_key2: constant.encryptionKey,
        encrypt_gw_key1: constant.encryptionKey,
        encrypt_gw_key3: constant.encryptionKey,
        encrypt_gw_key4: constant.encryptionKey,
        encrypt_gw_key5: constant.encryptionKey,
        encrypt_gw_key6: constant.encryptionKey,
        act_as_agent: 0,
        parentMchAccId: '',
        parentGateways: [],
        parentAceeptedCountrygateways: [],
      });

      const decryptDataForm = reactive({
        data: '',
      })

      const decryptOpuspayApiDataForm = reactive({
        data: '',
      })

      const formCopy = reactive({
        op_mch_id_copy: '',
        op_api_key_copy: '',
        op_api_secret_copy: '',
      });

      const formDevice = reactive({
        mch_acc_pd_id: '',
        mch_acc_id: '',
        tnt_pd_type_id: '',
        pd_name: '',
        pd_serial_number: '',
        location: '',
        status: '',
        pd_key1: '',
        pd_key2: '',
        pd_key3: '',
        pd_key4: '',
        pd_key5: '',
        encrypt_pd_serial_number: constant.encryptionKey,
        encrypt_pd_key1: constant.encryptionKey,
        encrypt_pd_key2: constant.encryptionKey,
        encrypt_pd_key3: constant.encryptionKey,
        encrypt_pd_key4: constant.encryptionKey,
        encrypt_pd_key5: constant.encryptionKey,
      })

      const router = useRouter();

      const modalTerms = reactive({
        show: false,
        size: 'max-w-6xl',
        title: 'Terms and Conditions',
        close_button: false,
        confirm_button: false,
      });

      const AddGwsSlideOver = reactive({
      show: false,
      size: 'max-w-5xl',
      close_button: false,
      title: 'Payment Gateways',
    });

      const tcDescription = ref('');
      const editorOptions = reactive(tinymceConfig.viewOnModalSettings);
      const showFullUrl = ref(false)
      const urlCharCount = ref(constant.urlCharCount)

      function showTabContent(tab, tabId) {
        eval(tab).value = tabId
      }

      watchEffect(() => {
        mchId.value = props.mch;
        activeMainTabId.value = props.tab;
        activeSubTab.value = props.subtab;

        if (props.showAccountsSlideOver) {
          slideOver.show = true
        }

        if (mchId.value != 0) {
          getMerchantAccountDetailsById(mchId.value);
          findPublishedTermAndCondition()
          getParentMerchantAccountDetails();
        }
      });

      function getMerchantAccountDetailsById(mch_acc_id) {
        countryOptions.value = [];
        stateOptions.value = [];
        townOptions.value = [];
        postalCodeOptions.value = [];
        form.encrypt_op_api_key = constant.encryptionKey
        form.encrypt_op_api_secret = constant.encryptionKey
        if (localStorage.getItem('merchant') != '') {
          merchantUser.value = localStorage.getItem('merchant')
        }

        axios.get('/v1/get-merchant-account/' + mch_acc_id).then((response) => {
          if (response.data != null) {
            merchantAccountDetails.value = response.data.data;
            form.organisationName = merchantAccountDetails.value.mch_acc_name;
            form.mch_acc_id = merchantAccountDetails.value.mch_acc_id;
            form.websiteUrl = merchantAccountDetails.value.website;
            form.orgWebsiteUrl = merchantAccountDetails.value.website;
            form.address1 = merchantAccountDetails.value.address1;
            form.address2 = merchantAccountDetails.value.address2;
            form.countryId = merchantAccountDetails.value.country_code;
            form.country = merchantAccountDetails.value.country_name;
            form.stateId = merchantAccountDetails.value.state_id;
            form.state = merchantAccountDetails.value.state_name;
            form.suburbId = merchantAccountDetails.value.suburb_id;
            form.suburb = merchantAccountDetails.value.suburb_name;
            form.postalCodeId = merchantAccountDetails.value.postcode_id;
            form.postalCode = merchantAccountDetails.value.postcode;
            form.gw_id = merchantAccountDetails.value.gw_id;
            form.status = merchantAccountDetails.value.status;
            form.is_invoice_directly =
              merchantAccountDetails.value.is_invoice_directly;
            form.is_parent = merchantAccountDetails.value.is_parent
            form.act_as_agent = merchantAccountDetails.value.merchant.act_as_agent;
            getMerchantAccountOpuspayApiCredentialsDetailsByMchAccId(mchId.value);
            getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId(form.mch_acc_id)
            if (form.gw_id != null) {
              form.gw_name =
                merchantAccountDetails.value.merchant_account_gateway.name;
              getMerchantAccountCredentialsDetailsByMchAccId(mchId.value);
              getMerchantAccountPaymentMethodsByMchAccId(mchId.value);
              getGatewayPaymentDevices();
              getGatewayPaymentMethods()
            }

            form.lastName = merchantAccountDetails.value.merchant_user.last_name;
            form.givenName = merchantAccountDetails.value.merchant_user.first_name;
            form.businessMail =
              merchantAccountDetails.value.merchant_user.business_email;
            form.phoneNo = merchantAccountDetails.value.merchant_user.phone_number;

            let objCountry = {};
            objCountry.text = form.country;
            objCountry.value = form.countryId;
            countryOptions.value.push(objCountry);

            let objState = {};
            objState.text = form.state;
            objState.value = form.stateId;
            stateOptions.value.push(objState);

            let objSuburb = {};
            objSuburb.text = form.suburb;
            objSuburb.value = form.suburbId;
            townOptions.value.push(objSuburb);

            let objPostcode = {};
            objPostcode.text = form.postalCode;
            objPostcode.value = form.postalCodeId;
            postalCodeOptions.value.push(objPostcode)
            threeDot(form.orgWebsiteUrl)
          } else {
            console.error('Error!')
          }
        })
      }

      function getMerchantAccountCredentialsDetailsByMchAccId(mch_acc_id) {
        axios
          .get('/v1/get-merchant-account-credentials/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountCredentialsDetails.value = response.data.data;
              form.gw_key2 = merchantAccountCredentialsDetails.value.gw_key2;
              form.gw_key1 =
                merchantAccountCredentialsDetails.value.gw_key1;
              form.gw_mch_id = merchantAccountCredentialsDetails.value.gw_mch_id;
              form.op_api_key = merchantAccountCredentialsDetails.value.op_api_key;
              form.op_api_secret =
                merchantAccountCredentialsDetails.value.op_api_secret;
              form.op_mch_id =
                merchantAccountCredentialsDetails.value.mch_acc_uuid
              form.gw_key3 = merchantAccountCredentialsDetails.value.gw_key3
              form.gw_key4 = merchantAccountCredentialsDetails.value.gw_key4
              form.gw_key5 = merchantAccountCredentialsDetails.value.gw_key5
              form.gw_key6 = merchantAccountCredentialsDetails.value.gw_key6
              form.is_parent_cred_used = merchantAccountCredentialsDetails.value.is_parent_cred_used
            } else {
              console.error('Error!')
            }
          })
      }

      function getMerchantAccountOpuspayApiCredentialsDetailsByMchAccId(
        mch_acc_id
      ) {
        axios
          .get('/v1/get-merchant-account-credentials/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountCredentialsDetails.value = response.data.data;
              form.op_api_key = merchantAccountCredentialsDetails.value.op_api_key;
              form.op_api_secret =
                merchantAccountCredentialsDetails.value.op_api_secret;
              form.op_mch_id =
                merchantAccountCredentialsDetails.value.mch_acc_uuid
            } else {
              console.error('Error!')
            }
          })
      }

      function copyOpuspayMerchnatId() {
        formCopy.op_mch_id_copy = form.op_mch_id;
        copyText(form.op_mch_id, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function copyOpuspayApiKey(copyValue) {
        copyText(copyValue, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function copyOpuspayApiSecretKey(copyValue) {
        formCopy.op_api_secret_copy = form.op_api_secret;
        copyText(copyValue, undefined, (error, event) => {
          if (error) {
            console.error('Error!')
          }
        })
      }

      function getMerchantAccountPaymentMethodsByMchAccId(mch_acc_id) {
        form.states = []
        axios
          .get('/v1/get-merchant-account-payment-methods/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              merchantAccountPaymentMethods.value = response.data.data;
              response.data.data.find((paymentMethod) => {
                form.states.push(paymentMethod.tnt_pm_id)
              })
            } else {
              console.error('Error!')
            }
          })
      }

      async function getGatewayPaymentDevices(
        page = 1,
        column = sortColumn.value,
        search = null
      ) {
        if (search != null) {
          searchText.value = search
        }

        await axios
          .get('/v1/get-merchant-account-payment-device-details/' + mchId.value, {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
              gwId: gatewayIdInEditMode.value,
              mch_acc_pgw_id: form.mch_acc_pgw_id,
            },
          })
          .then((response) => {
            if (response.data.data != null) {
              merchantAccountGatewayPaymentDevices.value = response.data.data.data;
              totatlRecord.value = response.data.data.total;
              currentPage.value = response.data.data.current_page;
              lastPage.value = response.data.data.last_page
            }
          })
      }

      function editMerchantAccount(mch_acc_id, gatewayIdInEditMode, gatewayNameInEditMode) {
        router.push({
          name: 'account-edit',
          params: {mch_acc_id: mch_acc_id, tab: activeMainTabId.value, subTab: activeSubTab.value, gwId: gatewayIdInEditMode, gwName: gatewayNameInEditMode},
        })
      }

      function sortPaymentDevice(column) {
        sortColumn.value = column;
        sortColumnDirection.value =
          sortColumnDirection.value === 'asc' ? 'desc' : 'asc';

        getGatewayPaymentDevices(1, sortColumn.value, searchText.value)
      }

      function findPublishedTermAndCondition() {
        axios.get('/v1/get-published-terms-and-condition/').then((response) => {
          if (response.data != null) {
            tcDescription.value = response.data.data.description
          } else {
            console.error('Error!')
          }
        })
      }

      function viewTermsAndCondition() {
        modalTerms.show = true
      }

      async function getGatewayPaymentMethods(
        page = 1,
        column = sortColumn.value,
        search = null
      ) {
        merchantAccountPaymentMethods.value = '';

        if (search != null) {
          searchText.value = search
        }

        await axios
          .get('v1/gateway/payment-methods/get-all/' + form.gw_id, {
            params: {
              search: searchText.value,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data.data != null) {
              merchantAccountGatewayPaymentMethods.value = response.data.data.data;
              paymentMethodList.value = []
              response.data.data.data.forEach((paymentMethod) => {
                paymentMethodList.value.push(paymentMethod.tnt_pm_id)
              });
              checkAllPaymentMethodSelect()
            }
          })
      }

      function setPaymentMethods(stateId) {
        if (form.states.includes(parseInt(stateId))) {
          form.states.splice(form.states.indexOf(parseInt(stateId)), 1)
        } else {
          form.states.push(stateId)
        }
        checkAllPaymentMethodSelect()
      }

      function checkAllPaymentMethodSelect() {
        let _arr1 = form.states.sort();
        let _arr2 = paymentMethodList.value.sort();

        if(_arr1.length != 0 && _arr2.length != 0) {
          if (
                  Array.isArray(_arr1) &&
                  Array.isArray(_arr2) &&
                  _arr1.length === _arr2.length
          ) {
            form.isSelectAllGatewayPaymentMethod = 1
          }

          if (
                  !Array.isArray(_arr1) ||
                  !Array.isArray(_arr2) ||
                  _arr1.length !== _arr2.length
          ) {
            form.isSelectAllGatewayPaymentMethod = 0
          }
        }
      }

      function setPaymentMethodCheck(paymentMethodId) {
        if (form.states.includes(parseInt(paymentMethodId))) {
          return true
        }
      }

      function selectAllPaymentMethod() {
        if (form.isSelectAllGatewayPaymentMethod == 0) {
          form.states = [];
          paymentMethodList.value.forEach((value) => {
            form.states.push(value)
          })
        } else if (form.isSelectAllGatewayPaymentMethod == 1) {
          isLoading.value = true;
          form.states = [];
          isLoading.value = false
        }
      }

      function tabDetailsReset() {
        activeMainTabId.value = 'tab0';
        activeSubTab.value = 'payment_methods';
        perPage.value = 10
      }

      async function getGatewayPaymentDeviceByMchAccPaymentDeviceId(
        mch_acc_pd_id
      ) {
        formDevice.encrypt_pd_serial_number = constant.encryptionKey
        formDevice.encrypt_pd_key1 = ''
        formDevice.encrypt_pd_key2 = ''
        formDevice.encrypt_pd_key3 = ''
        formDevice.encrypt_pd_key4 = ''
        formDevice.encrypt_pd_key5 = ''
        await axios
          .get(
            '/v1/get-merchant-account-payment-device-details-by-pd-id/' +
            mch_acc_pd_id
          )
          .then((response) => {
            if (response.data.data != null) {
              paymentDevicesByPaymentDeviceId.value = response.data.data
              formDevice.mch_acc_pd_id =
                paymentDevicesByPaymentDeviceId.value[0].mch_acc_pd_id
              formDevice.tnt_pd_type_id =
                paymentDevicesByPaymentDeviceId.value[0].gateway_payment_device.gw_pd_type
              formDevice.pd_name =
                paymentDevicesByPaymentDeviceId.value[0].pd_name
              formDevice.pd_serial_number =
                paymentDevicesByPaymentDeviceId.value[0].pd_serial_number
              formDevice.location =
                paymentDevicesByPaymentDeviceId.value[0].location
              formDevice.status = paymentDevicesByPaymentDeviceId.value[0].status
              if(formDevice.status == 'ACT'){
                formDevice.status = 'Active'
              }else {
                formDevice.status = 'Inactive'
              }
              formDevice.pd_key1 = paymentDevicesByPaymentDeviceId.value[0].pd_key1
              formDevice.pd_key2 = paymentDevicesByPaymentDeviceId.value[0].pd_key2
              formDevice.pd_key3 = paymentDevicesByPaymentDeviceId.value[0].pd_key3
              formDevice.pd_key4 = paymentDevicesByPaymentDeviceId.value[0].pd_key4
              formDevice.pd_key5 = paymentDevicesByPaymentDeviceId.value[0].pd_key5

              if(formDevice.pd_key1){
                formDevice.encrypt_pd_key1 = constant.encryptionKey
              }if(formDevice.pd_key2){
                formDevice.encrypt_pd_key2 = constant.encryptionKey
              }if(formDevice.pd_key3){
                formDevice.encrypt_pd_key3 = constant.encryptionKey
              }if(formDevice.pd_key4){
                formDevice.encrypt_pd_key4 = constant.encryptionKey
              }if(formDevice.pd_key5){
                formDevice.encrypt_pd_key5 = constant.encryptionKey
              }
            }
          })
      }

      async function getGateways(column = sortColumn.value, countryId = null) {
        if (countryId === '' || countryId === null){
          countryId = await getMerchantAccountDetailsById2(mchId.value);
        }
        await axios
            .get('v1/gateway/accepted-countries/get-all-gateways-per-country/' + countryId, {
              params: {
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
              },
            })
            .then((response) => {
              if (response.data.data != null) {
                gatewayDetails.value = response.data.data
              }
            })
      }

      async function getAllGatewaysWithoutCountrySelection(column = sortColumn.value) {
        await axios
            .get('v1/gateway/list/', {
              params: {
                sortColumn: column,
                sortDirection: sortColumnDirection.value,
                search: searchText.value,
                paginate: false,
              },
            })
            .then((response) => {
              if (response.data.data != null) {
                gatewayDetails.value = response.data.data
              }
            })
      }

      function sortGateway(column) {
        sortColumn.value = column
        sortColumnDirection.value =
            sortColumnDirection.value === 'asc' ? 'desc' : 'asc'

        if(form.act_as_agent == 1){
          getParentGateways(form.parentMchAccId);
        }else{
          getGateways(sortColumn.value, form.countryId);
        }
      }

      function sort(column) {
        sortColumn.value = column;
        sortColumnDirection.value =
                sortColumnDirection.value === 'asc' ? 'desc' : 'asc';

        getGatewayPaymentMethods(1, sortColumn.value, searchText.value)
      }

      async function getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId(mch_acc_id){
        form.gateways = []
        await axios
          .get('/v1/get-merchant-account-payment-gateway-credentials/' + mch_acc_id)
          .then((response) => {
            if (response.data != null) {
              paymentGatewayDetails.value = response.data.data
              paymentGatewayDetails.value.forEach((gatewayCredentials) => {
                form.gateways.push(gatewayCredentials.gw_id)
              })
            } else {
              console.error('Error!')
            }
          })
      }

      function checkGatewayDetailsCheckbox(gwId){
        if (form.gateways.includes(parseInt(gwId))) {
          return true
        }
      }
      function editPaymentGateway(gwId, gwName) {
        activeSubTab.value = 'payment_methods'
        gatewayIdInEditMode.value = gwId
        gatewayNameInEditMode.value = gwName
        form.gw_name = gwName
        form.gw_id = gatewayIdInEditMode.value
        form.encrypt_gw_mch_id = constant.encryptionKey
        form.encrypt_gw_key2 = constant.encryptionKey
        form.encrypt_gw_key1 = constant.encryptionKey
        form.encrypt_gw_key3 = constant.encryptionKey
        form.encrypt_gw_key4 = constant.encryptionKey
        form.encrypt_gw_key5 = constant.encryptionKey
        form.encrypt_gw_key6 = constant.encryptionKey
        resetSlideOverDetails()
        setDetailsToSlideOver(gatewayIdInEditMode.value)
      }

      function resetSlideOverDetails(){
        form.gw_mch_id = ''
        form.gw_key1 = ''
        form.gw_key2 = ''
        form.gw_key3 = ''
        form.gw_key4 = ''
        form.gw_key5 = ''
        form.gw_key6 = ''
      }

      function setDetailsToSlideOver(gatewayIdInEditMode){
        merchantAccountGatewayPaymentDevices.value = []
        form.states = []
        form.isSelectAllGatewayPaymentMethod = 0

        paymentGatewayDetails.value.forEach((gatewayDetails) => {
          if(gatewayDetails.gw_id == gatewayIdInEditMode){
            form.gw_mch_id = gatewayDetails.gw_mch_id
            form.gw_key1 = gatewayDetails.gw_key1
            form.gw_key2 = gatewayDetails.gw_key2
            form.gw_key3 = gatewayDetails.gw_key3
            form.gw_key4 = gatewayDetails.gw_key4
            form.gw_key5 = gatewayDetails.gw_key5
            form.gw_key6 = gatewayDetails.gw_key6
            form.mch_acc_pgw_id = gatewayDetails.mch_acc_pgw_id
            form.is_parent_cred_used = gatewayDetails.is_parent_cred_used


            gatewayDetails.credential_payment_methods.forEach((paymentMethod) => {
                form.states.push(paymentMethod.tnt_pm_id)
            })

            gatewayDetails.credential_payment_device.forEach((deviceDetails) => {
                let obj = {}
                obj.mch_acc_pd_id = deviceDetails.mch_acc_pd_id
                obj.pd_name = deviceDetails.pd_name
                obj.location = deviceDetails.location
                obj.pd_serial_number = deviceDetails.pd_serial_number
                obj.status = deviceDetails.status
                obj.gw_id = deviceDetails.gw_id
                obj.pd_key1 = deviceDetails.pd_key1
                obj.pd_key2 = deviceDetails.pd_key2
                obj.pd_key3 = deviceDetails.pd_key3
                obj.pd_key4 = deviceDetails.pd_key4
                obj.gw_pd_id = deviceDetails.gw_pd_id

                merchantAccountGatewayPaymentDevices.value.push(obj)
            })
          }
        })
        merchantAccountGatewayPaymentDevices.value.reverse()
      }

      function decryptData(data, value){
        decryptDataForm.data = data
        axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
          if (response.data != null) {
            if(decryptDataForm.data == form.gw_mch_id && value == 'gw_mch_id'){
              form.encrypt_gw_mch_id = response.data
            }
            else if(decryptDataForm.data == form.gw_key2 && value == 'gw_key2'){
              form.encrypt_gw_key2 = response.data
            }
            else if(decryptDataForm.data == form.gw_key1 && value == 'gw_key1'){
              form.encrypt_gw_key1 = response.data
            }
            else if(decryptDataForm.data == form.gw_key3 && value == 'gw_key3'){
              form.encrypt_gw_key3 = response.data
            }
            else if(decryptDataForm.data == form.gw_key4 && value == 'gw_key4'){
              form.encrypt_gw_key4 = response.data
            }
            else if(decryptDataForm.data == form.gw_key5 && value == 'gw_key5'){
              form.encrypt_gw_key5 = response.data
            }
            else if(decryptDataForm.data == form.gw_key6 && value == 'gw_key6'){
              form.encrypt_gw_key6 = response.data
            }
          } else {
            console.error('Error!')
          }
        })
      }

      function decryptDeviceData(data, value){
        decryptDeviceDataForm.data = data
        axios.post('/v1/decrypt-data', decryptDeviceDataForm).then((response) => {
          if (response.data != null) {
            if(value == 'pd_serial_number'){
              formDevice.encrypt_pd_serial_number = response.data
            }
            else if(value == 'pd_key1'){
              formDevice.encrypt_pd_key1 = response.data
            }
            else if(value == 'pd_key2'){
              formDevice.encrypt_pd_key2 = response.data
            }
            else if(value == 'pd_key3'){
              formDevice.encrypt_pd_key3 = response.data
            }
            else if(value == 'pd_key4'){
              formDevice.encrypt_pd_key4 = response.data
            }
            else if(value == 'pd_key5'){
              formDevice.encrypt_pd_key5 = response.data
            }
          } else {
            console.error('Error!')
          }
        })
      }

      function decryptOpuspayApiData(data, value){
        decryptDataForm.data = data
        axios.post('/v1/decrypt-data', decryptDataForm).then((response) => {
          if (response.data != null) {
            if(value == 'op_api_key'){
              form.encrypt_op_api_key = response.data
            }
            else if(value == 'op_api_secret'){
              form.encrypt_op_api_secret = response.data
            }
            else if(value == 'copy_op_api_key_copy'){
              formCopy.op_api_key_copy = response.data
              setTimeout(() => (copyOpuspayApiKey(formCopy.op_api_key_copy)), 500);
            }
            else if(value == 'copy_op_api_secret_copy'){
              formCopy.op_api_secret_copy = response.data
              setTimeout(() => (copyOpuspayApiKey(formCopy.op_api_secret_copy)), 500);
            }
          } else {
            console.error('Error!')
          }
        })
      }

      function threeDot(string, count=20, fullText=false) {
        if (fullText) {
          showFullUrl.value = true
          form.websiteUrl = string
        } else {
          showFullUrl.value = false
          count = urlCharCount.value
          const threeDots = constant.add3Dots(string, count);
          form.websiteUrl = threeDots
        }
      }

      async function getParentMerchantAccountDetails() {

        await axios
            .get('v1/get-merchant-accounts-by-merchant-id-is-parent')
            .then((response) => {
              if (response.data.data != null) {
                form.parentMchAccId = response.data.data.mch_acc_id
                if(form.act_as_agent == 1 && form.is_parent == 1){
                  getAllGatewaysWithoutCountrySelection(sortColumn.value);
                }else if (form.act_as_agent == 1 && form.is_parent == 0){
                  getParentGateways(form.parentMchAccId)
                }else{
                  getGateways(sortColumn.value, form.countryId);
                }
              }
            })
      }

      function getParentGateways(parentMchAccId){
        gatewayDetails.value = []
        form.parentAceeptedCountrygateways = []
        axios
            .get('/v1/get-parent-gateways-with-accepted-country/' + parentMchAccId + '/' + form.countryId)
            .then((response) => {
              if (response.data != null) {
                response.data.data.forEach((gateways) => {
                  let obj = {}
                  obj.gw_id = gateways.gw_id
                  obj.name = gateways.name
                  form.parentAceeptedCountrygateways.push(obj)
                })
                gatewayDetails.value = form.parentAceeptedCountrygateways
              } else {
                console.error('Error!')
              }
            })
      }

      async function getMerchantAccountDetailsById2(mch_acc_id) {
        const response = await axios.get('/v1/get-merchant-account/' + mch_acc_id)
        if (response.data != null){
          return response.data.data.country_code;
        }else{
          return null;
        }
      }


      return {
        t,
        slideOver,
        activeSubTab,
        showTabContent,
        activeMainTabId,
        accountEditTabItems,
        sortColumnDirection,
        deviceDetailSlideOver,
        onMounted,
        getMerchantAccountDetailsById,
        form,
        countryOptions,
        stateOptions,
        townOptions,
        postalCodeOptions,
        merchantAccountDetails,
        merchantAccountCredentialsDetails,
        mchId,
        getMerchantAccountCredentialsDetailsByMchAccId,
        getMerchantAccountPaymentMethodsByMchAccId,
        merchantAccountPaymentMethods,
        merchantAccountGatewayPaymentDevices,
        getGatewayPaymentDevices,
        editMerchantAccount,
        formCopy,
        copyOpuspayMerchnatId,
        copyOpuspayApiKey,
        copyOpuspayApiSecretKey,
        sortPaymentDevice,
        searchText,
        perPage,
        sortColumn,
        tcDescription,
        viewTermsAndCondition,
        modalTerms,
        editorOptions,
        merchantAccountGatewayPaymentMethods,
        setPaymentMethodCheck,
        setPaymentMethods,
        selectAllPaymentMethod,
        getMerchantAccountOpuspayApiCredentialsDetailsByMchAccId,
        lastPage,
        totatlRecord,
        currentPage,
        tabDetailsReset,
        merchantUser,
        getGatewayPaymentDeviceByMchAccPaymentDeviceId,
        paymentDevicesByPaymentDeviceId,
        formDevice,
        AddGwsSlideOver,
        gatewayDetails,
        getGateways,
        checkGatewayDetailsCheckbox,
        getMerchantAccountPaymentGatewayCredentialDetailsByMerchantAccountId,
        paymentGatewayDetails,
        editPaymentGateway,
        gatewayIdInEditMode,
        gatewayNameInEditMode,
        resetSlideOverDetails,
        setDetailsToSlideOver,
        getGatewayPaymentMethods,
        decryptDataForm,
        decryptDeviceDataForm,
        decryptData,
        decryptDeviceData,
        decryptOpuspayApiDataForm,
        decryptOpuspayApiData,
        constantValue,
        sortGateway,
        sort,
        threeDot,
        showFullUrl,
        urlCharCount,
        getAllGatewaysWithoutCountrySelection,
      }
    },
  }
</script>
