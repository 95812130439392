<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center">
        <button
          id="header-back_button"
          type="button"
          class="
      mr-3
      w-8
      h-8
      bg-white bg-opacity-50
      rounded-full
      hover:bg-opacity-60
      focus:outline-none
      focus:ring-2 focus:ring-offset-2 focus:ring-primary
    "
          @click="backNavigation()"
        >
          <svg-icon
            class="w-auto h-8 text-secondary bg-opacity-100"
            icon="caret-left"
          />
        </button>
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Add Account") }}
        </h1>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="tab-wizard-group">
        <div class="tab-wizard-wrapper">
          <nav
            class="tab-wizard"
            aria-label="Tabs"
          >
            <a
              v-for="(tabItem, index) in accountTabItems"
              :id="'tab'+index"
              :key="index"
              href="#"
              class="tab-wizard-item"
              :class="{ active: activeAccountTabId == tabItem.id }"
              @click="navigateTab(tabItem.id)"
            >
              {{ t(tabItem.name) }}
            </a>
          </nav>
        </div>

        <div class="tab-wizard-container">
          <div v-show="activeAccountTabId == 'tab1'">
            <h4 class="my-3 font-medium text-black text-opacity-75 text-lg">
              {{ t("Sub Account Details") }}
            </h4>

            <div class="grid md:grid-cols-3 gap-4">
              <div>
                <form-label
                  for="merchant-sub-account-name"
                >
                  {{ t("Merchant Sub Account Name") }} <required />
                </form-label>
                <form-input
                  id="merchant-sub-account-name"
                  v-model="form.organisationName"
                  class="mt-1"
                  name="merchant_sub_account_name"
                  type="text"
                  autofocus
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.organisationName
                      ? form.errors.validations.organisationName[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="website-url">
                  {{ t("Website URL") }}
                </form-label>
                <form-input
                  id="website-url"
                  v-model="form.websiteUrl"
                  class="mt-1"
                  name="website_url"
                  type="url"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.websiteUrl
                      ? form.errors.validations.websiteUrl[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="country">
                  {{ t("Status") }} <required />
                </form-label>
                <form-select
                  id="status"
                  v-model="form.status"
                  class="mt-1"
                  name="country"
                  :items="statusOptions"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.status
                      ? form.errors.validations.status[0]
                      : ''
                  "
                />
              </div>

              <div>
                <div class="mt-3 flex items-center">
                  <form-checkbox
                    id="invoice_account_directly"
                    v-model="form.is_invoice_directly"
                    name="invoice_account_directly"
                    :model-value="form.is_invoice_directly == 1 ? 0 : 1"
                    :checked="form.is_invoice_directly == 1"
                    :disabled="disableInvoiceDirectoryValue == true"
                  />
                  <label
                    class="ml-2 text-sm"
                    for="invoice_account_directly"
                  >{{ t("Invoice Account Directly") }}</label>
                </div>
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.is_invoice_directly
                      ? form.errors.validations.is_invoice_directly[0]
                      : ''
                  "
                />
              </div>
            </div>

            <divider />

            <h4 class="my-3 font-medium text-black text-opacity-75 text-lg">
              {{ t("Address Details") }}
            </h4>

            <div class="grid md:grid-cols-3 gap-4">
              <div>
                <form-label for="country">
                  {{ t("Country") }} <required />
                </form-label>
                <form-select
                  id="country"
                  v-model="form.countryId"
                  :change="onchange"
                  class="mt-1"
                  name="country"
                  :items="countryOptions"
                  required
                  @onChangeFunction="getState(), getParentMerchantAccountDetails()"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.countryId
                      ? form.errors.validations.countryId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="state">
                  {{ t("State") }} <required />
                </form-label>
                <form-select
                  id="state"
                  v-model="form.stateId"
                  :items="stateOptions"
                  :disable="stateDisable"
                  class="mt-1"
                  name="state"
                  required
                  @onChangeFunction="getTown()"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.stateId
                      ? form.errors.validations.stateId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="suburb">
                  {{ t("Suburb/Town") }} <required />
                </form-label>
                <form-select
                  id="suburb"
                  v-model="form.suburbId"
                  :disable="suburbDisable"
                  :items="townOptions"
                  class="mt-1"
                  name="suburb"
                  required
                  @onChangeFunction="getPostCode()"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.suburbId
                      ? form.errors.validations.suburbId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="postcode">
                  {{ t("Post Code") }} <required />
                </form-label>
                <form-select
                  id="postcode"
                  v-model="form.postalCodeId"
                  class="mt-1"
                  name="postcode"
                  required
                  :disable="postalCodeDisale"
                  :items="postalCodeOptions"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.postalCodeId
                      ? form.errors.validations.postalCodeId[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="address-line-1"
                >
                  {{ t("Address Line 1") }} <required />
                </form-label>
                <form-input
                  id="address-line-1"
                  v-model="form.address1"
                  class="mt-1"
                  type="text"
                  name="address_line_1"
                  required
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.address1
                      ? form.errors.validations.address1[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label for="address-line-2">
                  {{ t("Address Line 2") }}
                </form-label>
                <form-input
                  id="address-line-2"
                  v-model="form.address2"
                  class="mt-1"
                  type="text"
                  name="address_line_2"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.address2
                      ? form.errors.validations.address2[0]
                      : ''
                  "
                />
              </div>
            </div>

            <divider />

            <h4 class="my-3 font-medium text-black text-opacity-75 text-lg">
              {{ t("Primary Contact") }}
            </h4>

            <div class="mb-4 grid grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <form-label for="select-user">
                  {{ t("Select User") }}
                </form-label>
                <form-select
                  id="select-user"
                  v-model="form.user_uuid"
                  :change="onchange"
                  class="mt-1"
                  name="select_user"
                  :items="usersOptions"
                  @onChangeFunction="getMerchantUserDetailsByUserId()"
                />
              </div>
              <div>
                <form-label class="invisible">
&nbsp;
                </form-label>
                <ButtonSecondary
                  class="mt-1"
                  type="button"
                  @click="addNewUser()"
                >
                  {{ t("New") }}
                </ButtonSecondary>
              </div>
            </div>

            <div class="mb-4 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              <div>
                <form-label for="last-name">
                  {{ t("Last Name") }} <required />
                </form-label>
                <form-input
                  id="last-name"
                  v-model="form.last_name"
                  class="mt-1"
                  name="last_name"
                  type="text"
                  required
                  :disabled="disable"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.last_name
                      ? form.errors.validations.last_name[0]
                      : ''
                  "
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.fname_lname_email
                      ? form.errors.validations.fname_lname_email[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="given-name"
                >
                  {{ t("Given Name") }} <required />
                </form-label>
                <form-input
                  id="given-name"
                  v-model="form.first_name"
                  class="mt-1"
                  name="last_name"
                  type="text"
                  required
                  :disabled="disable"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.first_name
                      ? form.errors.validations.first_name[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="phone-number"
                >
                  {{ t("Phone Number") }} <required />
                </form-label>
                <form-input
                  id="phone-number"
                  v-model="form.phone_number"
                  class="mt-1"
                  name="phone_number"
                  type="text"
                  required
                  :disabled="disable"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.phone_number
                      ? form.errors.validations.phone_number[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="business-email"
                >
                  {{ t("Business Email") }} <required />
                </form-label>
                <form-input
                  id="business-email"
                  v-model="form.business_email"
                  class="mt-1"
                  name="business_email"
                  type="email"
                  required
                  :disabled="disable"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.business_email
                      ? form.errors.validations.business_email[0]
                      : ''
                  "
                />
              </div>

              <div>
                <form-label
                  for="security-profile"
                >
                  {{ t("Security Profile") }} <required />
                </form-label>
                <form-select
                  id="security-profile"
                  v-model="form.sec_profile_id"
                  class="mt-1"
                  name="security_profile"
                  required
                  :items="securityProfiles"
                  :disabled="disable"
                />
                <form-error
                  v-if="form.errors"
                  :message="
                    form.errors.validations.sec_profile_id
                      ? form.errors.validations.sec_profile_id[0]
                      : ''
                  "
                />
              </div>
            </div>
            <div class="mt-4 flex justify-end space-x-2">
              <Button
                class="btn-wide"
                type="button"
                @click="saveMerchantAccountDetails"
              >
                {{ t("Save & Continue") }}
              </Button>
            </div>
          </div>

          <div v-show="activeAccountTabId == 'tab2'">
            <form-gateway-details
              :mch="mchAccountId"
              :tab="activeAccountTabId"
              :country-id="form.countryId"
              :state-react="stateReact"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import Loading from 'vue3-loading-overlay'
import PageHeader from '@/components/PageHeader.vue'
import FormGatewayDetails from '@/pages/merchant-portal/accounts/add/FormGatewayDetails.vue'
import Divider from '@/components/Divider.vue'
import Required from '@/components/Required.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import Button from '@/components/Button.vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'AccountAdd',

  components: {
    Loading,
    PageHeader,
    SvgIcon,
    FormGatewayDetails,
    Button,
    Divider,
    Required,
    FormError,
    FormLabel,
    FormInput,
    FormSelect,
    FormCheckbox,
    ButtonSecondary,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const mchAccountId = ref(0)
    const stateReact = reactive({ count: 0 });
    const accountTabItems = reactive([
      { id: 'tab1', name: 'Enter Account Details' },
      { id: 'tab2', name: 'Enter Gateway Details' },
    ])

    const activeAccountTabId = ref(accountTabItems[0].id)

    function showTabContent(tab, tabId) {
      eval(tab).value = tabId
    }

    let onchange = ref(true)
    let stateDisable = ref(true)
    let suburbDisable = ref(true)
    let postalCodeDisale = ref(true)

    const router = useRouter()
    const disable = ref(true)

    const form = reactive({
      id: '',
      user_uuid: '',
      organisationName: '',
      lastName: '',
      givenName: '',
      businessMail: '',
      phoneNo: '',
      country: '',
      countryId: '',
      state: '',
      stateId: '',
      address1: '',
      address2: '',
      suburb: '',
      suburbId: '',
      postalCode: '',
      postalCodeId: '',
      websiteUrl: '',
      username: '',
      errors: '',
      status: '',
      is_invoice_directly: 0,
      last_name: '',
      first_name: '',
      phone_number: '',
      business_email: '',
      sec_profile_id: '',
      fname_lname_email: true,
      row_version: '',
      editMode: false,
      editModeInSave: false,
    })

    const countryOptions = ref([{ value: '', text: 'Please select a country' }])
    const stateOptions = ref([{ value: '', text: 'Please select a state' }])
    const townOptions = ref([
      { value: '', text: 'Please select a suburb/town' },
    ])
    const postalCodeOptions = ref([
      { value: '', text: 'Please select a post code' },
    ])
    const usersOptions = ref([{ value: '', text: 'Please select a user' }])
    const securityProfiles = ref([
      { value: '', text: 'Please select a security profile' },
    ])

    const statusOptions = ref([
      { value: 'ACT', text: 'Active' },
      { value: 'INA', text: 'Inactive' },
    ])

    const user_id = ref('')
    const parentMerchantCountryCode = ref('')
    const disableInvoiceDirectoryValue = ref(false)

    form.status = 'ACT'

    onMounted(() => {
      getCountry()
      getMerchantUsers()
    })

    getSecurityProfiles()

    async function getParentMerchantAccountDetails() {
      disableInvoiceDirectoryValue.value = false
      await axios
        .get('v1/get-merchant-accounts-by-merchant-id-is-parent')
        .then((response) => {
          if (response.data.data != null) {
            parentMerchantCountryCode.value = response.data.data.country_code
            if(form.countryId != parentMerchantCountryCode.value){
              disableInvoiceDirectoryValue.value = true
              form.is_invoice_directly = 0
            }
          }
        })
    }

    async function getCountry() {
      await axios
        .get('v1/country_and_currency/get_all_tnt_countries_by_tnt_id')
        .then((response) => {
          if (response.data.data != null) {
            response.data.data.find((country) => {
              let obj = {}
              obj.text = country.country_name
              obj.value = country.country_code
              countryOptions.value.push(obj)
            })
          }
        })
    }

    async function getState() {
      form.stateId = null
      form.suburbId = null
      form.postalCodeId = null
      stateOptions.value = [{ value: '', text: 'Please select a state' }]
      townOptions.value = [{ value: '', text: 'Please select a suburb/town' }]
      postalCodeOptions.value = [
        { value: '', text: 'Please select a post code' },
      ]
      if (form.countryId) {
        await axios
          .get('v1/address/state-names-for-country/' + form.countryId)
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((state) => {
                let obj = {}
                obj.text = state.state_name
                obj.value = state.state_id
                stateOptions.value.push(obj)
              })
            }
            stateDisable.value = false
          })
      } else {
        stateDisable.value = true
      }
    }

    async function getTown() {
      if (Number.isInteger(parseInt(form.stateId))) {
        form.suburbId = null
        form.postalCodeId = null
        townOptions.value = [{ value: '', text: 'Please select a suburb/town' }]
        postalCodeOptions.value = [
          { value: '', text: 'Please select a post code' },
        ]
        await axios
          .get(
            'v1/address/suburb-names-for-state/' +
              form.countryId +
              '/' +
              form.stateId
          )
          .then((response) => {
            if (response.data.data != null) {
              response.data.data.find((state) => {
                let obj = {}
                obj.text = state.suburb_name
                obj.value = state.suburb_id
                townOptions.value.push(obj)
              })
            }
            suburbDisable.value = false
          })
      } else {
        suburbDisable.value = true
        postalCodeDisale.value = true
        form.suburbId = null
        form.postalCodeId = null
      }
    }

    async function getPostCode() {
      if (Number.isInteger(parseInt(form.suburbId))) {
        form.postalCodeId = null
        postalCodeDisale.value = true
        postalCodeOptions.value = [
          { value: '', text: 'Please select a post code' },
        ]
        await axios
          .get(
            'v1/address/postal-codes-for-suburb/' +
              form.countryId +
              '/' +
              form.stateId +
              '/' +
              form.suburbId
          )
          .then((response) => {
            if (response.data.data != null) {
              if (response.data.data.length == 1) {
                form.postalCodeId = 0
                postalCodeOptions.value = [
                  {
                    value: response.data.data[0].postcode_id,
                    text: response.data.data[0].postcode,
                  },
                ]
                setTimeout(
                  () => (form.postalCodeId = response.data.data[0].postcode_id),
                  500
                )
              } else {
                response.data.data.find((state) => {
                  let obj = {}
                  obj.text = state.postcode
                  obj.value = state.postcode_id
                  postalCodeOptions.value.push(obj)
                })
              }
            } else {
            }
          })
        postalCodeDisale.value = false
      } else {
        postalCodeDisale.value = true
        form.postalCodeId = null
      }
    }

    async function getMerchantUsers(user_uuid) {
      await axios.get('/v1/merchant-active-users-by-mchId').then((response) => {
        if (response.data.data != null) {
          response.data.data.find((users) => {
            if (user_uuid != users.user_uuid) {
            let obj = {}
            obj.text =
              users.username +
              ' ' +
              '-' +
              ' ' +
              users.first_name +
              ' ' +
              '-' +
              ' ' +
              users.last_name
            obj.value = users.user_uuid
            usersOptions.value.push(obj)
            }
          })
        }
      })
    }

    async function getMerchantUserDetailsByUserId() {
      disable.value = true
      if(form.user_uuid != ''){
        await axios
          .get('/v1/merchant-user-details/' + form.user_uuid)
          .then((response) => {
            if (response.data.data != null) {
              form.last_name = response.data.data.last_name
              form.first_name = response.data.data.first_name
              form.phone_number = response.data.data.phone_number
              form.business_email = response.data.data.business_email
              form.sec_profile_id = response.data.data.sec_profile_id
            }
          })
      }else{
        form.last_name = ''
        form.first_name = ''
        form.phone_number = ''
        form.business_email = ''
        form.sec_profile_id = ''
        form.username = ''
      }
    }

    async function getSecurityProfiles() {
      isLoading.value = true
      await axios
        .get('v1/get-merchant-security-profiles')
        .then((response) => {
          if (response.data != null) {
            response.data.data.find((securityProfile) => {
              let obj = {}
              obj.text = securityProfile.sec_profile_name
              obj.value = securityProfile.sec_profile_id
              securityProfiles.value.push(obj)
            })
            isLoading.value = false
          } else {
            console.error('Error!')
          }
        })
    }

    function saveMerchantAccountDetails() {
      stateReact.count++;
      isLoading.value = true
      let url = ''
      form.editModeInSave = false
      if (form.id == 0 || form.id == '' || form.id == null) {
        url = 'v1/new-merchant-account'
      } else {
        url = '/v1/edit-merchant-account/' + form.id
        form.editModeInSave = true
      }
      axios.post(url, form).then((response) => {
        isLoading.value = false
        if (response.data != null && !response.data.data.errors) {
          disable.value = true
          activeAccountTabId.value = 'tab2'
          mchAccountId.value = response.data.data.mch_acc_id
          form.id = response.data.data.mch_acc_id
          form.row_version = response.data.data.row_version
          user_id.value = response.data.data.user_id
          getMerchantUserByUserId(user_id.value)
          return true
        } else {
          form.errors = response.data.data.errors
          return false
        }
      })
    }

    function getMerchantUserByUserId(user_id) {
      axios
        .get('v1/get-merchant-user-by-user-id/' + user_id)
        .then((response) => {
          if (response.data != null) {
            usersOptions.value = []

            form.user_uuid = response.data.data.user_uuid
            form.last_name = response.data.data.last_name
            form.first_name = response.data.data.first_name
            form.username = response.data.data.username

            if (form.username == null) {
              let obj = {}
              obj.text = form.first_name + ' ' + '-' + ' ' + form.last_name
              obj.value = form.user_uuid
              usersOptions.value.push(obj)
            } else {
              let obj = {}
              obj.text =
                form.username +
                ' ' +
                '-' +
                ' ' +
                form.first_name +
                ' ' +
                '-' +
                ' ' +
                form.last_name
              obj.value = form.user_uuid
              usersOptions.value.push(obj)
            }
            getMerchantUsers(form.user_uuid)
          } else {
            console.error('Error!')
          }
        })
    }

    function navigateTab(tabId) {
      form.errors = ''
      if (activeAccountTabId.value == 'tab1' && tabId == 'tab2') {
        if (saveMerchantAccountDetails()) {
          showTabContent('activeAccountTabId', tabId)
        }
      } else {
        showTabContent('activeAccountTabId', tabId)
      }
    }

    function addNewUser() {
      disable.value = false
      form.user_uuid = ''
      form.last_name = ''
      form.first_name = ''
      form.phone_number = ''
      form.business_email = ''
      form.sec_profile_id = ''
    }

    function backNavigation(){
      if(activeAccountTabId.value == 'tab1'){
        router.push('/accounts/')
      }else{
        activeAccountTabId.value =  'tab1'
      }
    }

    return {
      t,
      fullPage,
      isLoading,
      showTabContent,
      accountTabItems,
      activeAccountTabId,
      form,
      saveMerchantAccountDetails,
      onMounted,
      getCountry,
      countryOptions,
      stateOptions,
      postalCodeOptions,
      townOptions,
      stateDisable,
      suburbDisable,
      postalCodeDisale,
      onchange,
      getState,
      getTown,
      getPostCode,
      mchAccountId,
      getMerchantUsers,
      usersOptions,
      getMerchantUserDetailsByUserId,
      securityProfiles,
      disable,
      addNewUser,
      statusOptions,
      navigateTab,
      getMerchantUserByUserId,
      getParentMerchantAccountDetails,
      parentMerchantCountryCode,
      disableInvoiceDirectoryValue,
      backNavigation,
      stateReact
    }
  },
}
</script>
