<template>
  <loading
    :active="isLoading"
    :can-cancel="true"
    :full-page="fullPage"
  />

  <page-header>
    <template #header-content>
      <div class="flex items-center justify-between flex-wrap">
        <h1
          id="page-title"
          class="page-title"
        >
          {{ t("Users") }}
        </h1>

        <div class="flex items-center justify-between space-x-3">
          <Button
            id="button_search-user"
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            @click=";(slideOver.show = true), setEnvironment('search'), searchUser()"
          >
            {{ t("Search User") }}
          </Button>
          <Button
            id="button_add-user"
            class="btn-page_header btn-primary btn-lg btn-wide shadow-lg"
            type="button"
            @click=";(slideOver.show = true), setEnvironment('add')"
          >
            {{ t("Add User") }}
          </Button>
        </div>
      </div>
    </template>
  </page-header>

  <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="py-8">
      <div class="md:flex gap-x-3">
        <div class="w-full md:w-auto flex items-center justify-center md:justify-start mb-4">
          <span class="text-sm">{{ t("Show") }}</span>
          <div class="mx-2">
            <form-select
              id="results_per_page"
              v-model="perPage"
              :items="[10, 25, 50, 100]"
              class="py-2"
              name="results_per_page"
              @onChangeFunction="getUsers()"
            />
          </div>
          <span class="text-sm">{{ t("entries") }}</span>
        </div>

        <div class="w-full md:w-2/3 flex flex flex-1 flex-wrap md:flex-nowrap items-center justify-center md:justify-end mt-2 md:mt-0">
          <div class="flex flex-wrap items-center justify-center mx-1">
            <ButtonSecondary
              v-if="false"
              class="btn-default mb-4 mx-1"
              type="button"
              @click="resetSearchFilter(), getUsers()"
            >
              <svg-icon
                class="btn-icon mr-1 w-auto h-4"
                icon="refresh"
                stroke-width="2.5"
              />
              <span>{{ t("Reset Filters") }}</span>
            </ButtonSecondary>
          </div>

          <div class="w-full sm:w-3/6 xl:w-2/6 mb-4">
            <search
              v-model="quickSearchText"
              @dataFetchfunction="getUsers(1, sortColumn, $event)"
            />
          </div>
        </div>
      </div>

      <TableDefault>
        <template #table>
          <table
            id="users_admin_portal_tbl"
            class="table table-clickable"
            aria-describedby=""
          >
            <thead>
              <tr>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Last Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('last_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'last_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("First Name") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('first_name')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'first_name'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Username") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('username')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'username'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Email Address") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('email_address')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'email_address'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center">
                    <span>{{ t("Security Profile") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('security_profile')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'security_profile'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex items-center justify-center">
                    <span>{{ t("Status") }}</span>
                    <button
                      class="btn_th-sort"
                      type="button"
                      @click="sort('status')"
                    >
                      <svg-icon
                        v-if="
                          sortColumnDirection == 'desc' &&
                            sortColumn == 'status'
                        "
                        class="icon_th-sort"
                        icon="th-caret-down"
                      />
                      <svg-icon
                        v-else
                        class="icon_th-sort"
                        icon="th-caret-up"
                      />
                    </button>
                  </div>
                </th>
                <th
                  scope="col"
                  class="th-action"
                >
                  {{ t("Actions") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(userData,index) in users"
                :id="'users_admin_portal_' + index"
                :key="userData.uuid"
              >
                <td style="max-width: 230px">
                  {{ userData.last_name }}
                </td>
                <td style="max-width: 230px">
                  {{ userData.first_name }}
                </td>
                <td style="max-width: 230px">
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/security/users' }"
                    @click="slideOver.show = true, (slideOver.show = true),
                            setEnvironment('view'),
                            setEditUser(userData.uuid)"
                  >
                    {{ userData.username }}
                  </router-link>
                </td>
                <td style="max-width: 230px">
                  {{ userData.business_email }}
                </td>
                <td style="max-width: 200px">
                  <router-link
                    class="text-primary underline"
                    :to="{ path: '/security/security-profiles/' + userData.security_profile.sec_profile_id}"
                  >
                    {{ userData.security_profile.sec_profile_name }}
                  </router-link>
                </td>
                <td class="text-center">
                  <badge
                    v-if="userData.status == 'ACT'"
                    type="success"
                    text="Active"
                  />
                  <badge
                    v-if="userData.status == 'INA'"
                    type="danger"
                    text="Inactive"
                  />
                  <badge
                    v-if="userData.status == 'INIT'"
                    type="warning"
                    text="Pending"
                  />
                </td>
                <td>
                  <div class="flex items-center justify-center">
                    <div class="w-8 flex items-center justify-center ml-0">
                      <button-circle
                        :id="'users_admin_portal_view_' + index"
                        type="info"
                        icon="eye"
                        @click.stop="
                        ;(slideOver.show = true),
                          setEnvironment('view'),
                          setEditUser(userData.uuid)
                        "
                      />
                    </div>
                    <div class="w-8 flex items-center justify-center ml-2">
                      <button-circle
                        :id="'users_admin_portal_email_' + index"
                        :disabled="userData.status != 'INIT'"
                        type="info"
                        icon="Envelope"
                        @click.stop="resendRegisterEmail(userData)"
                      />
                    </div>
                    <div class="w-8 flex items-center justify-center ml-2">
                      <button-circle
                        :id="'users_admin_portal_edit_' + index"
                        type="info"
                        icon="edit"
                        :disabled="userData.status == 'INIT'"
                        @click.stop="
                        ;(slideOver.show = true),
                          setEnvironment('edit'),
                          setEditUser(userData.uuid)
                        "
                      />
                    </div>
                  </div>
                </td>
              </tr>

              <no-table-data v-if="!users.length" />
            </tbody>
          </table>
        </template>
      </TableDefault>

      <vue-pagination
        :page-size="perPage"
        :total-records="totalRecord"
        :sort-column="sortColumn"
        :sort-direction="sortColumnDirection"
        :last-page="lastPage"
        @page-changed="getUsers($event)"
      />
    </div>
  </div>

  <slide-over
    :show="slideOver.show"
    :slideover-size="slideOver.size"
    :slideover-title="slideOver.title"
    :close-button="slideOver.close_button"
    @closeSlideOver="slideOver.show = false"
  >
    <template #slideover-body>
      <div
        v-if="environment == 'search'"
        class="space-y-4"
      >
        <div>
          <form-label for="search_username">
            {{ t("Username") }}
          </form-label>
          <form-input
            id="search_username"
            v-model="search.username"
            class="mt-1"
            name="username"
            type="text"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.user_name"
            :message="searchErrors.user_name ? searchErrors.user_name : ''"
          />
        </div>

        <div>
          <form-label for="search_email">
            {{ t("Email") }}
          </form-label>
          <form-input
            id="search_email"
            v-model="search.businessEmail"
            class="mt-1"
            name="email"
            type="email"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.email"
            :message="searchErrors.email ? searchErrors.email : ''"
          />
        </div>

        <div>
          <form-label for="search_last_name">
            {{ t("Last Name") }}
          </form-label>
          <form-input
            id="search_last_name"
            v-model="search.lastName"
            class="mt-1"
            name="last_name"
            type="text"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.last_name"
            :message="searchErrors.last_name ? searchErrors.last_name : ''"
          />
        </div>

        <div>
          <form-label for="search_first_name">
            {{ t("First Name") }}
          </form-label>
          <form-input
            id="search_first_name"
            v-model="search.firstName"
            class="mt-1"
            name="first_name"
            type="text"
            @keyup.enter="getUsers()"
          />
          <form-error
            v-if="searchErrors.first_name"
            :message="searchErrors.first_name ? searchErrors.first_name : ''"
          />
        </div>

        <div>
          <form-label for="search_status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="search_status"
            v-model="search.status"
            class="mt-1"
            name="status"
            :items="SearchStatusOption"
            @keyup.enter="getUsers()"
          />
        </div>
      </div>

      <div
        v-if="environment == 'add'"
        class="space-y-4"
      >
        <div>
          <form-label for="add_last_name">
            {{ t("Last Name") }} <required />
          </form-label>
          <form-input
            id="add_last_name"
            v-model="form.lastName"
            class="mt-1"
            name="last_name"
            type="text"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.last_name
                ? form.errors.validations.last_name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="first_name">
            {{ t("First Name") }} <required />
          </form-label>
          <form-input
            id="add_first_name"
            v-model="form.firstName"
            class="mt-1"
            name="add_first_name"
            type="text"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.first_name
                ? form.errors.validations.first_name[0]
                : ''
            "
          />
        </div>
        <div>
          <form-label
            for="add_email"
          >
            {{ t("Business Email Address") }} <required />
          </form-label>
          <form-input
            id="add_email"
            v-model="form.businessEmail"
            class="mt-1"
            name="email"
            type="email"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.business_email
                ? form.errors.validations.business_email[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="add_phone">
            {{ t("Phone Number") }}
          </form-label>
          <form-input
            id="add_phone"
            v-model="form.phoneNumber"
            class="mt-1"
            name="phone"
            type="text"
          />
        </div>
        <form-error
          v-if="form.errors"
          :message="
            form.errors.validations.phone_number
              ? form.errors.validations.phone_number[0]
              : ''
          "
        />
        <div>
          <form-label
            for="add_security_profile"
          >
            {{ t("Security Profile") }} <required />
          </form-label>
          <form-select
            id="add_security_profile"
            v-model="form.securityProfileId"
            class="mt-1"
            name="security_profile"
            :items="securityProfiles"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.sec_profile_id
                ? form.errors.validations.sec_profile_id[0]
                : ''
            "
          />
        </div>
        <form-error
          v-if="form.errors"
          :message="
            form.errors.validations.fname_lname_email
              ? form.errors.validations.fname_lname_email[0]
              : ''
          "
        />
      </div>

      <div
        v-if="environment == 'edit'"
        class="space-y-4"
      >
        <div>
          <form-label for="edit_last_name">
            {{ t("Last Name") }} <required />
          </form-label>
          <form-input
            id="edit_last_name"
            v-model="form.lastName"
            class="mt-1"
            name="last_name"
            type="text"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.last_name
                ? form.errors.validations.last_name[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="edit_first_name">
            {{ t("First Name") }} <required />
          </form-label>
          <form-input
            id="edit_first_name"
            v-model="form.firstName"
            class="mt-1"
            name="first_name"
            type="text"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.first_name
                ? form.errors.validations.first_name[0]
                : ''
            "
          />
        </div>
        <div>
          <form-label for="first_name">
            {{ t("Username") }} <required />
          </form-label>
          <form-input
            id="add_username"
            v-model="form.username"
            class="mt-1"
            name="add_username"
            type="text"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.username
                ? form.errors.validations.username[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label
            for="edit_email"
          >
            {{ t("Business Email Address") }} <required />
          </form-label>
          <form-input
            id="edit_email"
            v-model="form.businessEmail"
            class="mt-1"
            name="email"
            type="email"
            required
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.business_email
                ? form.errors.validations.business_email[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="edit_phone">
            {{ t("Phone Number") }}
          </form-label>
          <form-input
            id="edit_phone"
            v-model="form.phoneNumber"
            class="mt-1"
            name="phone"
            type="text"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.phone_number
                ? form.errors.validations.phone_number[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label
            for="edit_security_profile"
          >
            {{ t("Security Profile") }} <required />
          </form-label>
          <form-select
            id="edit_security_profile"
            v-model="form.securityProfileId"
            class="mt-1"
            name="security_profile"
            :length="2"
            :items="securityProfiles"
            :disable="loginUserUuid == form.id && !form.isAdminUser"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.sec_profile_id
                ? form.errors.validations.sec_profile_id[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label for="edit_status">
            {{ t("Status") }}
          </form-label>
          <form-select
            id="edit_status"
            v-model="form.status"
            class="mt-1"
            name="status"
            :items="statusOption"
            :disable="loginUserUuid == form.id"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.status
                ? form.errors.validations.status[0]
                : ''
            "
          />
        </div>

        <div>
          <form-label
            for="edit_language"
          >
            {{ t("Default Language") }} <required />
          </form-label>
          <form-select
            id="edit_language"
            v-model="form.languageId"
            class="mt-1"
            name="language"
            :items="tntLanguages"
          />
          <form-error
            v-if="form.errors"
            :message="
              form.errors.validations.language
                ? form.errors.validations.language[0]
                : ''
            "
          />
        </div>
        <form-error
          v-if="form.errors"
          :message="
            form.errors.validations.fname_lname_email
              ? form.errors.validations.fname_lname_email[0]
              : ''
          "
        />
      </div>

      <div
        v-if="environment == 'view'"
        class="space-y-6 break-words"
      >
        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Status") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            <badge
              v-if="form.status == 'ACT'"
              type="success"
              text="Active"
            />
            <badge
              v-if="form.status == 'INA'"
              type="danger"
              text="Inactive"
            />
            <badge
              v-if="form.status == 'INIT'"
              type="warning"
              text="Pending"
            />
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Last Name") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.lastName }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("First Name") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.firstName }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Username") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.username }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Business Email Address") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.businessEmail }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Phone Number") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.phoneNumber }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Security Profile") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.securityProfile }}
          </div>
        </div>

        <div>
          <div class="text-xs font-normal text-black opacity-70">
            {{ t("Default Language") }}
          </div>
          <div class="mt-1 text-black text-opacity-80 font-medium">
            {{ form.language }}
          </div>
        </div>
      </div>
    </template>

    <template #slideover-actions>
      <ButtonSecondary
        v-if="environment == 'search' && isSearch()"
        class="btn-wide"
        type="reset"
        @click="resetSearch"
      >
        {{ t("Reset") }}
      </ButtonSecondary>
      <Button
        v-if="environment == 'add' || environment == 'edit'"
        class="btn-wide"
        type="button"
        @click="onSubmit"
      >
        {{ t("Save") }}
      </Button>
      <Button
        v-if="environment == 'view' && form.status == 'INIT'"
        class="btn-wide"
        type="button"
        :disabled="form.status != 'INIT'"
        @click="setEnvironment('view'), trapFocus(), registerEmail(allUserData)"
      >
        {{ t("Registration Email") }}
      </Button>
      <Button
        v-if="environment == 'view'"
        class="btn-wide"
        type="button"
        :disabled="form.status == 'INIT'"
        @click="setEnvironment('edit'), trapFocus()"
      >
        {{ t("Edit") }}
      </Button>
      <Button
        v-if="environment == 'search'"
        class="btn-wide"
        type="button"
        @click="searchFilter()"
      >
        {{ t("Search") }}
      </Button>
    </template>
  </slide-over>

  <modal
    :type="modal.type"
    :show="modal.show"
    :title="modal.title"
    :message="modal.message"
    :close-button="modal.close_button"
    :confirm-button="modal.confirm_button"
    :button-text="modal.button_text"
    @closeModal="closeAlert"
  />
</template>

<script>
import { ref, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import focusTrap from '@/helpers/focus-trap.js'
import Badge from '@/components/Badge.vue'
import Loading from 'vue3-loading-overlay'
import Modal from '@/components/Modal.vue'
import Search from '@/components/Search.vue'
import Button from '@/components/Button.vue'
import SvgIcon from '@/components/SvgIcon.vue'
import Required from '@/components/Required.vue'
import SlideOver from '@/components/SlideOver.vue'
import FormError from '@/components/FormError.vue'
import FormInput from '@/components/FormInput.vue'
import FormLabel from '@/components/FormLabel.vue'
import FormSelect from '@/components/FormSelect.vue'
import PageHeader from '@/components/PageHeader.vue'
import NoTableData from '@/components/NoTableData.vue'
import VuePagination from '@/components/VuePagination'
import ButtonCircle from '@/components/ButtonCircle.vue'
import TableDefault from '@/components/TableDefault.vue'
import ButtonSecondary from '@/components/ButtonSecondary.vue'
import router from "../../routes";
import CryptoJs from 'crypto-js'
import constant from "@/constant";

export default {
  name: 'Users',

  components: {
    Modal,
    Badge,
    Search,
    Button,
    Loading,
    SvgIcon,
    Required,
    SlideOver,
    FormError,
    FormInput,
    FormLabel,
    FormSelect,
    PageHeader,
    NoTableData,
    ButtonCircle,
    TableDefault,
    VuePagination,
    ButtonSecondary,
  },

  setup() {
    const { t } = useI18n()
    const fullPage = ref(true)
    const isLoading = ref(false)
    const loginUserUuid = ref('')
    const lastPage = ref(0)
    const currentPage = ref(0)
    const perPage = ref('10')
    const environment = ref('')
    const sortColumnDirection = ref('desc')
    const CryptoJsAesJson = constant.CryptoJsAesJson
    const securityProfiles = ref([
      { value: '', text: 'Please select a security profile' },
    ])
    const tntLanguages = ref([])
    const allUserData = ref([])
    const users = ref([])
    const sortColumn = ref('')
    const searchParam = ref('')
    const quickSearchText = ref('')
    const totalRecord = ref(0)
    const isRestFilterEnable = ref(false)
    const statusOption = ref([
      {
        text: 'Active',
        value: 'ACT',
      },
      {
        text: 'Inactive',
        value: 'INA',
      },
    ])
    const SearchStatusOption = ref([
      {
        text: 'Active',
        value: 'ACT',
      },
      {
        text: 'Inactive',
        value: 'INA',
      },
      {
        text: 'Pending',
        value: 'INIT',
      },
    ])
    const slideOver = reactive({
      show: false,
      size: 'max-w-md',
      close_button: false,
      title: 'Title',
    })
    const form = reactive({
      id: null,
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      securityProfileId: null,
      username: null,
      status: null,
      language: null,
      languageId: null,
      rowVersion: null,
      isAdminUser: false,
      errors: '',
    })

    const search = reactive({
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      username: null,
      status: null,
    })
    const searchFreeze = reactive({
      lastName: null,
      firstName: null,
      businessEmail: null,
      phoneNumber: null,
      securityProfile: null,
      username: null,
      status: null,
    })
    const modal = reactive({
      show: false,
      type: '',
      title: '',
      message: '',
      close_button: true,
    })
    const searchErrors = reactive({
      user_name: null,
      email: null,
      last_name: null,
      first_name: null,
    })
    getSecurityProfiles()
    getTntLanguages()
    checkLoginUser()

    function getSecurityProfiles() {
      securityProfiles.value = [
        { value: '', text: 'Please select a security profile' },
      ]
      axios.get('/v1/get-security-profiles').then((response) => {
        if (response.data != null) {
          response.data.data.find((secProfile) => {
            let obj = {}
            obj.text = secProfile.sec_profile_name
            obj.value = secProfile.sec_profile_id
            securityProfiles.value.push(obj)
          })
        } else {
          console.error('Error!')
        }
      })
    }
    function getTntLanguages() {
      axios.get('/v1/get-tnt-languages').then((response) => {
        if (response.data != null) {
          response.data.data.find((tntLanguage) => {
            let obj = {}
            obj.text = tntLanguage.language_code
            obj.value = tntLanguage.tnt_lang_id
            tntLanguages.value.push(obj)
          })
        } else {
          console.error('Error!')
        }
      })
    }

    async function getUsers(
      page = 1,
      column = sortColumn.value,
      quickSearch = null
    ) {
        isRestFilterEnable.value = false
        isLoading.value = true
        let searchText = ''
        if (isSearchFreeze()) {
          isRestFilterEnable.value = true
          searchParam.value = '&search='
          searchText = JSON.stringify(searchFreeze)
        }
        if (quickSearch != null) {
          quickSearchText.value = quickSearch
        }
        await axios
          .get('v1/users', {
            params: {
              quickSearch: quickSearchText.value,
              search: searchText,
              count_per_page: perPage.value,
              page: page,
              sortColumn: column,
              sortDirection: sortColumnDirection.value,
            },
          })
          .then((response) => {
            if (response.data != null) {
              isLoading.value = false
              users.value = response.data.data.data
              lastPage.value = response.data.data.last_page
              currentPage.value = response.data.data.current_page
              totalRecord.value = response.data.data.total
            } else {
              console.error('Error!')
            }
          })
          .catch(err => {
            router.push({ name: 'unauthorized-access'})
          })
        slideOver.show = false
    }

    async function sort(column) {
      sortColumn.value = column
      sortColumnDirection.value =
        sortColumnDirection.value === 'asc' ? 'desc' : 'asc'
      await getUsers(1, sortColumn.value, quickSearchText.value)
    }

    async function setEditUser(registerId) {
      await axios.get('v1/user/' + registerId).then((response) => {
        if (response.data.data != null) {
          let user = response.data.data
          allUserData.value = response.data.data
          form.id = user.user_uuid
          form.lastName = user.last_name
          form.firstName = user.first_name
          form.businessEmail = user.business_email
          form.phoneNumber = user.phone_number
          form.securityProfile = user.security_profile.sec_profile_name
          form.securityProfileId = user.security_profile.sec_profile_id
          form.username = user.username
          form.status = user.status
          form.language = user.language.language_code
          form.languageId = user.language.tnt_lang_id
          form.isAdminUser =
            user.security_profile.is_admin_profile == 1 ? true : false
        }
      })
    }

    function onSubmit() {
      isLoading.value = true
      let SubmitArr = {}
      let url = ''
      SubmitArr.uuid = form.id
      SubmitArr.last_name = form.lastName
      SubmitArr.first_name = form.firstName
      SubmitArr.username = form.username
      SubmitArr.business_email = form.businessEmail
      SubmitArr.phone_number = form.phoneNumber
      SubmitArr.sec_profile_id = form.securityProfileId
      SubmitArr.status = form.status
      SubmitArr.language = form.languageId
      SubmitArr.row_version = form.rowVersion
      SubmitArr.fname_lname_email = true

      if (form.id == null) {
        url = '/v1/new-user-register'
      } else {
        url = '/v1/edit-user/' + form.id
      }
      axios.post(url, SubmitArr).then((response) => {
        if (response.data != null && !response.data.data.errors) {
          if (form.id == null) {
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'User created successfully.'
            modal.show = true
          } else {
            if (form.id != null && response.data.status){
              let loggedUserUUID = JSON.parse(CryptoJs.AES.decrypt(localStorage.getItem('user'), process.env.MIX_ENCRYPT_KEY, {format: CryptoJsAesJson}).toString(CryptoJs.enc.Utf8)).authentication_data.user.user.user_uuid
              let encryptUserName = CryptoJs.AES.encrypt(
                JSON.stringify( SubmitArr.username),
                process.env.MIX_ENCRYPT_KEY,
                { format: constant.CryptoJsAesJson }
              ).toString()
              if (loggedUserUUID == form.id){
                localStorage.setItem("loggedUser", encryptUserName)
              }
            }
            slideOver.show = false
            modal.confirm_button = false
            modal.button_text = 'Ok'
            modal.type = 'success'
            modal.title = 'Success'
            modal.message = 'User updated successfully.'
            modal.show = true
          }
          getUsers(currentPage.value)
        } else {
          form.errors = response.data.data.errors
        }
        if(form.errors != null)
        {
          if(form.errors.validations.uuid && form.errors.validations.uuid[0]){
            router.push({ name: 'unauthorized-access' })
          }
        }
        isLoading.value = false
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function setEnvironment(env) {
      if (env == 'add') {
        resetFrom()
        environment.value = 'add'
        slideOver.title = 'Add User'
      }
      if (env == 'edit') {
        form.errors = null
        environment.value = 'edit'
        slideOver.title = 'Edit User'
      }
      if (env == 'view') {
        environment.value = 'view'
        slideOver.title = 'View User'
      }
      if (env == 'search') {
        environment.value = 'search'
        slideOver.title = 'Search User'
        search.lastName = null
        search.firstName = null
        search.businessEmail = null
        search.username = null
        search.status = null
        searchErrors.user_name = null
        searchErrors.email = null
        searchErrors.last_name = null
        searchErrors.first_name = null
      }
    }

    function checkLoginUser() {
      axios.get('/v1/get_login_user_uuid').then((response) => {
        if (response.data != null) {
          loginUserUuid.value = response.data.data
        }
      })
    }

    function searchFilter(){
      const max = /^.{0,255}$/
      let index = 0

      if (environment.value == 'search') {
        resetErrors()
        if (!max.test(search.username)) {
          searchErrors.user_name = 'Maximum length for user name is 255.'
          index++
        }
        if (!max.test(search.businessEmail)) {
          searchErrors.email = 'Maximum length for email is 255.'
          index++
        }
        if (!max.test(search.lastName)) {
          searchErrors.last_name = 'Maximum length for last name is 255.'
          index++
        }
        if (!max.test(search.firstName)) {
          searchErrors.first_name = 'Maximum length for first name is 255.'
          index++
        }
      }
      if (index == 0) {
        searchFreeze.username = search.username
        searchFreeze.lastName = search.lastName
        searchFreeze.firstName = search.firstName
        searchFreeze.businessEmail = search.businessEmail
        searchFreeze.status = search.status
        getUsers();
      }
    }

    function resetFrom() {
      form.id = null
      form.lastName = null
      form.firstName = null
      form.businessEmail = null
      form.phoneNumber = null
      form.securityProfile = null
      form.securityProfileId = null
      form.username = null
      form.status = null
      form.language = null
      form.languageId = null
      form.rowVersion = null
      form.errors = null
    }
    function resetSearch() {
      search.lastName = null
      search.firstName = null
      search.businessEmail = null
      search.username = null
      search.status = null
      searchErrors.user_name = null
      searchErrors.email = null
      searchErrors.last_name = null
      searchErrors.first_name = null
      searchFreeze.businessEmail = null 
      searchFreeze.username = null 
      searchFreeze.status = null 
      searchFreeze.firstName = null 
      searchFreeze.lastName = null
      getUsers()
    }
    function resetSearchFilter() {
      searchFreeze.username = null
      searchFreeze.lastName = null
      searchFreeze.firstName = null
      searchFreeze.businessEmail = null
      searchFreeze.status = null
    }
    function closeAlert() {
      modal.show = false
      if (environment.value == 'edit') {
        // slideOver.show = true
        // setEnvironment('view')
        setEditUser(form.id)
      }
    }
    function clickEditButton() {
      environment.value = 'edit'
    }
    function isSearch() {
      if (
        search.businessEmail != null ||
        search.username != null ||
        search.status != null ||
        search.firstName != null ||
        search.lastName != null
      ) {
        return true
      } else {
        return false
      }
    }

    function isSearchFreeze() {
      if (
        searchFreeze.businessEmail != null ||
        searchFreeze.username != null ||
        searchFreeze.status != null ||
        searchFreeze.firstName != null ||
        searchFreeze.lastName != null
      ) {
        return true
      } else {
        return false
      }
    }

    function focusElement(element) {
      nextTick(() => {
        const focusRef = document.getElementById(element)
        if (focusRef != null) {
          focusRef.focus()
        }
      })
    }

    function trapFocus() {
      var focusLoop = document.getElementById("slideover_focus-loop")
      focusTrap.trapFocus(focusLoop)
    }

    function resetErrors() {
      searchErrors.user_name = null
      searchErrors.email = null
      searchErrors.last_name = null
      searchErrors.first_name = null
    }

    function searchUser() {
      search.username = searchFreeze.username
      search.lastName = searchFreeze.lastName
      search.firstName = searchFreeze.firstName
      search.businessEmail = searchFreeze.businessEmail
      search.status = searchFreeze.status
    }

    function resendRegisterEmail(userData){
      isLoading.value = true
      axios.post('/v1/re-send-register-email', userData).then((response) => {
        if (response.data != null && (response.data.data.errors == null || response.data.data.errors == undefined)) {
          isLoading.value = false
          modal.confirm_button = false
          modal.button_text = 'Ok'
          modal.type = 'success'
          modal.title = 'Success'
          modal.message = 'Please check your email for registration details.'
          modal.show = true
        }else {
          router.push({ name: 'unauthorized-access'})
          isLoading.value = false
          console.error('Error!')
        }
      })
        .catch(err => {
          router.push({ name: 'unauthorized-access'})
        })
    }

    function registerEmail(userData){
      userData.uuid = userData.user_uuid
      userData.phone_no = userData.phone_number
      resendRegisterEmail(userData)
    }

    return {
      t,
      fullPage,
      isLoading,
      slideOver,
      trapFocus,
      sortColumnDirection,
      setEnvironment,
      environment,
      form,
      securityProfiles,
      onSubmit,
      users,
      setEditUser,
      search,
      resetFrom,
      statusOption,
      tntLanguages,
      modal,
      closeAlert,
      sort,
      searchParam,
      perPage,
      getUsers,
      clickEditButton,
      resetSearch,
      isSearch,
      totalRecord,
      lastPage,
      sortColumn,
      loginUserUuid,
      quickSearchText,
      searchErrors,
      isRestFilterEnable,
      SearchStatusOption,
      searchUser,
      resetSearchFilter,
      searchFilter,
      resendRegisterEmail,
      registerEmail,
      allUserData,
    }
  },
}
</script>
